import React, { useEffect } from "react";
import { Button } from "antd";
import { ButtonType } from "antd/es/button";

let buttonStepId = 0;

const ButtonStepper: React.FC<{
  steps: string[];
  selectedStep: number;
  finishedStepsNumber: number;
  onClickOnStep?: (step: number) => void;
}> = ({ steps, onClickOnStep, selectedStep, finishedStepsNumber }) => {
  const onClickOnStepHandler = (index: number) => {
    if (onClickOnStep) {
      onClickOnStep(index);
    }
  };

  useEffect(() => {
    buttonStepId += 1;
  }, []);

  return (
    <Button.Group>
      {steps.map((step, index) => (
        <Button
          type={getButtonType(index, selectedStep, finishedStepsNumber)}
          danger={index === selectedStep}
          onClick={() => onClickOnStepHandler(index)}
          key={`button_step_${buttonStepId}_${index}`}
        >
          {step}
        </Button>
      ))}
    </Button.Group>
  );
};

function getButtonType(
  index: number,
  selectedStep: number,
  finishedStepsNumber: number
): ButtonType {
  if (index === selectedStep) {
    return "primary";
  }
  if (index <= finishedStepsNumber) {
    return "primary";
  }
  return "ghost";
}

export default ButtonStepper;
