import React, { useCallback } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Typography,
} from "antd";
import s from "./styles.module.scss";
import { CreateOrEditMarathonFormComponent } from "pages/createOrEditMarathon/types";
import { Marathon } from "sport-app-types";
import { date } from "lib/dates";
import { useCreateOrEditMarathonActor } from "app/xstate/createOrEditMarathon/hooks/useCreateOrEditMarathonActor";

const CreateOrEditMarathonFirstStepForm: CreateOrEditMarathonFormComponent = ({
  marathon,
}) => {
  const [, send] = useCreateOrEditMarathonActor();
  const onSubmit = useCallback((marathonData: Partial<Marathon>) => {
    send({
      type: "UPDATE_MARATHON",
      payload: {
        ...marathonData,
        startDate: date(marathonData.startDate).toISOString(),
        finishDate: date(marathonData.finishDate).toISOString(),
      },
    });

    send({
      type: "SET_CURRENT_STEP",
      payload: "Second",
    });
  }, []);
  return (
    <div>
      <div className={s.firstStepContainer}>
        <div className={s.firstStepImage}>photo</div>

        <Form onFinish={onSubmit}>
          <Row>
            <Typography.Title>Создание марафона</Typography.Title>
          </Row>
          <Row>
            <Typography.Text>
              Это очень вкусная тренировка, которая точно поможет тебе стать
              стройнее и красивее. Мы проводим тренировки вместе с собакой,
              которая делает пресс лёжа лучше тебя. Но не отчаивайся, ты тоже
              сможешь делать пресс как она, потому что мы шутим.
            </Typography.Text>
          </Row>

          <Form.Item
            rules={[
              {
                required: true,
                message: "Обязательное поле",
              },
            ]}
            required
            label={"Название"}
            name={"name"}
            initialValue={marathon.name}
          >
            <Input />
          </Form.Item>

          <Form.Item
            required
            rules={[
              {
                required: true,
                min: 1,
                message: "Не меньше 1 тренировки",
                type: "number",
              },
            ]}
            label={"Количество тренировок"}
            name={"volume"}
            initialValue={marathon.volume}
          >
            <InputNumber />
          </Form.Item>

          <Row>
            <Form.Item
              label={"Дата начала"}
              name={"startDate"}
              initialValue={date(marathon.startDate)}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              label={"Дата конца"}
              name={"finishDate"}
              initialValue={date(marathon.finishDate)}
            >
              <DatePicker />
            </Form.Item>
          </Row>

          <Row justify={"center"}>
            <Button htmlType={"submit"}>Далее</Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default CreateOrEditMarathonFirstStepForm;
