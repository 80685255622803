import {
  uploadFilesToStorage,
  UploadFileToStorageFolders,
} from "app/helpers/uploadFileToStorage";

export function uploadMarathonPhotosToStorage(photos: string[]) {
  return uploadFilesToStorage(
    photos.map((urlData) => ({
      file: urlData,
      folder: UploadFileToStorageFolders.marathonCovers,
    }))
  );
}
