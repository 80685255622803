import { Api } from "app/api/api";
import { GetUrlFnNames, getUrlFns } from "app/api/getData/constants";
import { Instructor } from "sport-app-types/src/client";

export function updateInstructorById(
  id: string,
  newTrainerData: Partial<Omit<Instructor, "id">>
) {
  if (!id) {
    throw new Error("Invalid id");
  }

  return Api.put(
    getUrlFns[GetUrlFnNames.updateInstructorById](id),
    newTrainerData
  );
}
