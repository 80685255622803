import { Button, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React, { ChangeEvent } from "react";

interface GetColumnSearchPropsProps {
  dataIndex: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
}

export function getColumnSearchProps({
  dataIndex,
  onChange,
  onSubmit,
}: GetColumnSearchPropsProps) {
  return {
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Поиск по ${dataIndex}`}
          onChange={onChange}
          onPressEnter={onSubmit}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={onSubmit}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Найти
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  };
}
