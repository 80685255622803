type Key = symbol | string | number;

type RecordStringToAny = Record<string, any>;

// TODO Неправильно нормализирует если передавать функцию преобразования значения
export function normalizeArray<
  InputElem extends RecordStringToAny = RecordStringToAny,
  PreparedElem extends any = InputElem,
  InputElemsArray extends InputElem[] = InputElem[],
  NormalizingField extends keyof InputElem = keyof InputElem
>(
  inputArray: InputElemsArray,
  normalizingField: NormalizingField,
  prepareValueFn?: (
    acc: PreparedElem[],
    value: InputElem,
    array: InputElemsArray
  ) => PreparedElem,
  prepareKeyFn?: (params: {
    normalizingField: NormalizingField;
    value: InputElem;
    inputArray: InputElemsArray;
  }) => Key
): Record<Key, InputElem> {
  return inputArray.reduce((acc: any, value) => {
    const preparedKey: Key = prepareKeyFn
      ? prepareKeyFn({ normalizingField, value, inputArray })
      : value[normalizingField];

    const preparedValue = prepareValueFn
      ? prepareValueFn(acc[preparedKey] || [], value, inputArray)
      : value;

    return {
      ...acc,
      [preparedKey]: preparedValue,
    };
  }, {} as Record<string, PreparedElem>);
}
