import { v4 as uuid } from "uuid";
import { date, dateFromUtc } from "lib/dates";
import { LicenseKeyInDb } from "sport-app-types";

export const timeFormatDDMMYYYY = "DD.MM.YYYY";

export async function getB2BLicenceKeysList(
  searchParams: Record<string, string>
): Promise<LicenseKeyInDb[]> {
  const fakeData: LicenseKeyInDb[] = [
    {
      id: uuid(),
      licenseKey: uuid(),
      createdAt: date().add(5, "d").toISOString(),
      expiredAt: date().add(23, "d").toISOString(),
    },
  ];

  console.warn("fakeData used b2bLicenceKeys!!!", searchParams);

  return fakeData;
}

export function normalizeLicenceKeysListByCreatedDate(
  licenseKeysList: LicenseKeyInDb[]
): Record<string, LicenseKeyInDb[]> {
  return licenseKeysList.reduce((acc, licenceKey) => {
    const preparedStartDate = dateFromUtc(licenceKey.createdAt).format(
      timeFormatDDMMYYYY
    );
    const licenceKeysInCreatedInThisDate =
      acc && acc[preparedStartDate] ? acc[preparedStartDate] : [];

    return {
      ...acc,
      [preparedStartDate]: licenceKeysInCreatedInThisDate.concat(licenceKey),
    };
  }, {} as Record<string, LicenseKeyInDb[]>);
}
