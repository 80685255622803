import { PromoCodeInDb } from "sport-app-types/index";
import { assign, createMachine } from "xstate";
import { getB2BPromoCodesList } from "app/api/getData/getB2BPromoCodesList";
import { normalizeArray } from "lib/normalizeArray";
import { InstructorInDb } from "sport-app-types/src/server";
import { getInstructorsList } from "app/api/getData/getInstructorsList";
import { createPromoCodeInServer } from "app/api/createData/createPromoCode";
import { toast } from "react-hot-toast";

export interface B2BPromoCodesMachineContext {
  instructorsHashTable: Record<string, InstructorInDb>;
  promoCodesHashTable: Record<string, PromoCodeInDb>;
  dataLoading: boolean;
  createPromoCodeParams: PromoCodeInDb | null;
  createPromoCodeModalIsOpen: boolean;
  createPromoCodeLoading: boolean;
}

type B2BPromoCodesMachineEvents =
  | {
      type: "SYNC_DATA";
    }
  | {
      type: "OPEN_CREATE_PROMO_CODE_MODAL";
    }
  | {
      type: "CLOSE_CREATE_PROMO_CODE_MODAL";
    }
  | {
      type: "CREATE_PROMOCODE";
      payload: PromoCodeInDb;
    };

export const b2bPromoCodesMachine = createMachine<
  B2BPromoCodesMachineContext,
  B2BPromoCodesMachineEvents
>({
  key: "b2bPromoCodesMachine",
  id: "b2bPromoCodesMachine",
  initial: "idle",
  context: {
    promoCodesHashTable: {},
    instructorsHashTable: {},
    dataLoading: false,
    createPromoCodeParams: null,
    createPromoCodeModalIsOpen: false,
    createPromoCodeLoading: false,
  },
  states: {
    idle: {
      on: {
        SYNC_DATA: {
          target: "dataLoading",
          actions: assign({
            dataLoading: (_) => true,
          }),
        },
        OPEN_CREATE_PROMO_CODE_MODAL: {
          actions: assign({
            createPromoCodeModalIsOpen: (_) => true,
          }),
        },
        CLOSE_CREATE_PROMO_CODE_MODAL: {
          actions: assign({
            createPromoCodeModalIsOpen: (_) => false,
          }),
        },
        CREATE_PROMOCODE: {
          target: "creatingPromoCode",
          actions: assign({
            createPromoCodeParams: (_, { payload }) => payload,
            createPromoCodeLoading: (_) => true,
          }),
        },
      },
    },
    dataLoading: {
      invoke: {
        src: () => Promise.all([getB2BPromoCodesList(), getInstructorsList()]),
        onDone: {
          target: "idle",
          actions: assign({
            dataLoading: (_) => false,
            promoCodesHashTable: (_, { data }) =>
              normalizeArray<PromoCodeInDb>(data[0], "promocode"),
            instructorsHashTable: (_, { data }) =>
              normalizeArray<InstructorInDb>(data[1], "id"),
          }),
        },
        onError: {
          target: "idle",
        },
      },
    },
    creatingPromoCode: {
      invoke: {
        src: ({ createPromoCodeParams }) => {
          if (!createPromoCodeParams) {
            throw "Нет параметров";
          }

          return createPromoCodeInServer(createPromoCodeParams);
        },
        onDone: {
          target: "idle",
          actions: [
            assign({
              createPromoCodeLoading: (_) => false,
              createPromoCodeModalIsOpen: (_) => false,
              promoCodesHashTable: ({ promoCodesHashTable }, { data }) => {
                console.log(data);
                return {
                  ...promoCodesHashTable,
                  [data.promocode]: data,
                };
              },
              createPromoCodeParams: (_) => null,
            }),
            () => {
              toast.success("Успешно");
            },
          ],
        },
        onError: {
          target: "idle",
          actions: assign({
            createPromoCodeLoading: (_) => false,
            createPromoCodeParams: (_, { data }) => {
              if (typeof data === "string") {
                toast.error(data);
              }
              return null;
            },
          }),
        },
      },
    },
  },
});
