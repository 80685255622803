import { RouteProps } from "react-router-dom";
import UsersListPage from "pages/users/inedx";
import EditInstructorPage from "pages/editInstructor";
import InstructorMasterTrainingsPage from "pages/instructorMasterTrainings";
import EditMasterTrainingPage from "pages/editMasterTraining";
import TrainingStatisticsPage from "pages/trainingStatistics";
import B2bListOfOrganizationsPage from "pages/b2bListOfOrganizations";
import B2BLicenceKeysPage from "pages/b2bLicenceKeys";
import B2BPromoCodesPage from "pages/b2bPromoCodes";
import TrainingRecordsPage from "pages/trainingsRecords";
import MarathonRequestsPage from "pages/marathons/requests";
import { operatorRouteFns } from "app/routes/constants";
import MarathonDetailsPage from "pages/marathons/details";
import ScheduledMarathonsPage from "pages/marathons/confirmed";
import MarathonsListPage from "pages/marathonsList";
import CreateOrEditMarathonPage from "pages/createOrEditMarathon";

// const TrainingsRecordsPage = React.lazy(() => import("pages/trainingsRecords"));

const operatorRoutes: RouteProps[] = [
  { component: UsersListPage, path: "/users" },
  // { component: FormPage, path: "/edit/trainer/:trainerId/:tab" },
  // {
  //   component: TrainingsRecordsPage,
  //   path: "/trainingsRecords",
  // },
  {
    component: EditInstructorPage,
    path: "/instructors/edit/:trainerId",
  },
  {
    component: TrainingRecordsPage,
    path: "/trainingRecords",
  },
  {
    component: InstructorMasterTrainingsPage,
    path: "/masterTrainings/:trainerId",
    exact: true,
  },
  {
    component: B2bListOfOrganizationsPage,
    path: "/b2bListOfOrganizations",
  },
  {
    component: B2BLicenceKeysPage,
    path: "/b2bLicenceKeys",
  },
  {
    component: B2BPromoCodesPage,
    path: "/b2bPromoCodes",
  },
  {
    component: EditMasterTrainingPage,
    path: "/masterTrainings/edit/:masterTrainingId",
  },
  {
    component: TrainingStatisticsPage,
    path: "/statistics/trainings",
    exact: true,
  },
  {
    component: MarathonsListPage,
    path: operatorRouteFns.marathonsList(),
    exact: true,
  },
  {
    component: CreateOrEditMarathonPage,
    path: operatorRouteFns.createMarathon(),
    exact: true,
  },
  {
    component: CreateOrEditMarathonPage,
    path: operatorRouteFns.editMarathon(),
  },
  {
    component: MarathonRequestsPage,
    path: operatorRouteFns.marathonsRequests(),
    exact: true,
  },
  {
    component: ScheduledMarathonsPage,
    path: operatorRouteFns.marathonsSchedule(),
  },
  {
    component: MarathonDetailsPage,
    path: operatorRouteFns.marathonDetails(),
  },
];

export default operatorRoutes;
