import React from "react";
import { Layout as LayoutAntd, Menu } from "antd";
import {
  UserOutlined,
  VideoCameraOutlined,
  OrderedListOutlined,
  DollarCircleOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { logout } from "app/api/authManegeHelpers";
import { Icon } from "uikit/icon";
import s from "./styles.module.scss";
import { operatorRouteFns } from "app/routes/constants";

const Layout: React.FC = ({ children }) => (
  <LayoutAntd style={{ minHeight: "100vh" }}>
    <LayoutAntd.Header>
      <div className={s.logo}>
        <Icon name={"companyLogo"} size={32} />
        <h4>Восьмерка спорт</h4>
      </div>
    </LayoutAntd.Header>
    <LayoutAntd>
      <LayoutAntd.Sider theme={"light"} width={200} collapsible style={{}}>
        <Menu theme="light" defaultSelectedKeys={["1"]} mode="inline">
          <Menu.Item key="instructors3" icon={<UserOutlined />}>
            <Link to={"/users/instructors"}>Тренеры</Link>
          </Menu.Item>
          {/* <Menu.Item key="4"> */}
          {/*  <Link to={"/users/customers"}>Клиенты</Link> */}
          {/* </Menu.Item> */}

          <Menu.Item key="5trainingRecords" icon={<VideoCameraOutlined />}>
            <Link to={"/trainingRecords"}>Записи</Link>
          </Menu.Item>

          <Menu.Item
            key="b2bListOfOrganizations6"
            icon={<DollarCircleOutlined />}
          >
            <Link to={"/b2bListOfOrganizations"}>Организации</Link>
          </Menu.Item>
          {/* <Menu.Item key="b2bListOfBloggers7"> */}
          {/*  <Link to={"/b2bListOfBloggers"}>Блогеры</Link> */}
          {/* </Menu.Item> */}
          {/* <Menu.Item key="b2bListOfInstructors8"> */}
          {/*  <Link to={"/b2bListOfInstructors"}>Тренеры</Link> */}
          {/* </Menu.Item> */}
          <Menu.SubMenu
            key="sub4"
            icon={<DollarCircleOutlined />}
            title="Промокоды"
          >
            {/* <Menu.Item key="b2bOneTimePromoCodes"> */}
            {/*  <Link to={"/b2bLicenceKeys"}>Одноразовые</Link> */}
            {/* </Menu.Item> */}

            <Menu.Item key="b2bReusablePromoCodes">
              <Link to={"/b2bPromoCodes"}>Многоразовые</Link>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.SubMenu
            icon={<OrderedListOutlined />}
            title={"Статистика"}
            key={"statisticsMenu"}
          >
            <Menu.Item key={"statistics/trainings"}>
              <Link to={"/statistics/trainings"}>Проведенные тренировки</Link>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.Item
            key={operatorRouteFns.marathonsList()}
            icon={<OrderedListOutlined />}
          >
            <Link to={operatorRouteFns.marathonsList()}>Марафоны</Link>
          </Menu.Item>

          <Menu.Item
            key={"layout_exit"}
            style={{
              marginTop: 300,
              display: "flex",
              alignItems: "center",
            }}
            icon={<LogoutOutlined onClick={logout} />}
          >
            <p
              onClick={logout}
              style={{
                margin: 0,
              }}
            >
              Выйти
            </p>
          </Menu.Item>
        </Menu>
      </LayoutAntd.Sider>
      <LayoutAntd.Content>
        <div style={{ padding: 5, minHeight: 360 }}>{children}</div>
      </LayoutAntd.Content>
    </LayoutAntd>
  </LayoutAntd>
);

export default Layout;
