import React from "react";
import { Button, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { date } from "lib/dates";
import IssueOfPromoCodesModal from "pages/b2bLicenceKeys/issueOfPromoCodesModal";
import { useOrganizationsActions } from "app/xstate/b2bCompanies/hooks/useCompaniesActions";

const dateFormat = "DD.mm.YYYY";

const B2BLicenceKeysPage: React.FC = () => {
  const { openCreateLicenceKeysModal } = useOrganizationsActions();
  const columns: ColumnsType<any> = [
    {
      key: "number",
      title: "Номер",
    },
    {
      key: "userId",
      title: "id пользователя",
    },
    {
      key: "organization",
      title: "Организация",
    },
    {
      key: "createdAt",
      title: "Выпущен",
    },
    {
      key: "validFrom",
      title: "Действует c",
      render: (value) => date(value).format(dateFormat),
    },
    {
      key: "validUntil",
      title: "До",
      render: (value) => date(value).format(dateFormat),
    },
    {
      key: "workedFrom",
      title: "Активен с",
      render: (value) => date(value).format(dateFormat),
    },
    {
      key: "workedUntil",
      title: "До",
      render: (value) => date(value).format(dateFormat),
    },
    {
      key: "actions",
      title: "",
      render: () => (
        <span
          style={{
            color: "#1890FF",
            cursor: "pointer",
          }}
        >
          Удалить
        </span>
      ),
    },
  ];

  return (
    <>
      <Typography.Title
        style={{
          textAlign: "center",
        }}
      >
        Одноразовые промокоды
      </Typography.Title>
      <Button onClick={() => openCreateLicenceKeysModal()} type={"primary"}>
        Выпустить
      </Button>
      <IssueOfPromoCodesModal />
      <Table columns={columns} bordered />
    </>
  );
};

export default B2BLicenceKeysPage;
