import React, { useState } from "react";
import { Button, Col, Row, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { B2BOrganizationInDb } from "sport-app-types";
import { useOrganizationsActions } from "app/xstate/b2bCompanies/hooks/useCompaniesActions";
import ShowImgModal from "components/showImgModal";
import { normalizeLicenceKeysListByCreatedDate } from "app/api/getData/getB2BLicenceKeysList";
import { LicenseKeyInDb } from "sport-app-types/index";
import { convertJsonToCsv } from "lib/jsonToCsv";

const ListOfOrganizations: React.FC<{ companiesList: any[] }> = ({
  companiesList,
}) => {
  const { openCreateOrEditOrganizationModal, openCreateLicenceKeysModal } =
    useOrganizationsActions();
  const [previewCompanyImg, setPreviewCompanyImg] = useState<string | null>(
    null
  );
  const clearPreviewCompanyImg = () => {
    setPreviewCompanyImg(null);
  };
  const openPreviewCompanyImg = (url: string) => {
    setPreviewCompanyImg(url);
  };

  const columns: ColumnsType<B2BOrganizationInDb> = [
    {
      key: "nameRow",
      dataIndex: "name",
      title: "Название организации",
      sorter: (a, b) =>
        a.name && b.name && a.name?.toLowerCase() < b.name?.toLowerCase()
          ? -1
          : 1,
    },
    // {
    //   key: "createDateRow",
    //   dataIndex: "createdAt",
    //   title: "Дата создания",
    //   sorter: (a, b) => (a.name.toLowerCase() < b.name.toLowerCase ? -1 : 1),
    //   render: (value) => (value ? date(value).format("DD MMMM YYYY") : ""),
    // },
    {
      key: "contactNameRow",
      dataIndex: "contactPerson",
      title: "Контактное лицо",
    },
    {
      key: "phoneRow",
      dataIndex: "phone",
      title: "Телефон",
    },
    {
      key: "emailRow",
      dataIndex: "email",
      title: "e-mail",
    },
    {
      key: "logoRow",
      dataIndex: "logo",
      title: "Лого",
      render: (v) => (
        <div
          style={{
            maxWidth: 200,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            color: "#1890FF",
            cursor: "pointer",
          }}
          onClick={() => {
            openPreviewCompanyImg(v);
          }}
        >
          Лого
        </div>
      ),
    },
    {
      key: "backgroundRow",
      dataIndex: "background",
      title: "Фон",
      render: (v) => (
        <div
          style={{
            maxWidth: 200,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            color: "#1890FF",
            cursor: "pointer",
          }}
          onClick={() => openPreviewCompanyImg(v)}
        >
          Фон
        </div>
      ),
    },
    {
      key: "editCompany",
      title: "",
      dataIndex: "id",
      render: (
        id,
        { logo, createdAt, name, contactPerson, email, phone, background }
      ) => (
        <span
          onClick={() => {
            openCreateOrEditOrganizationModal({
              id,
              logo,
              createdAt,
              name,
              contactPerson,
              email,
              phone,
              background,
            });
          }}
          style={{
            cursor: "pointer",
            color: "#1890FF",
          }}
        >
          Правка
        </span>
      ),
    },
  ];

  const expandedRowRender: (props: B2BOrganizationInDb) => React.ReactNode = ({
    licenseKeys,
    id: orgId,
  }) => {
    const groupedPromoCodes = licenseKeys
      ? normalizeLicenceKeysListByCreatedDate(licenseKeys)
      : {};

    return (
      <Row>
        <Col flex={9}>
          {Object.keys(groupedPromoCodes).map((key) => (
            <div key={`${key} row1`}>
              Ключи {groupedPromoCodes[key]?.length} шт. (выпущены {key})
              <Button
                type={"link"}
                onClick={() => {
                  if (!licenseKeys) {
                    return;
                  }
                  downloadLicenceKeys(groupedPromoCodes[key]);
                }}
              >
                Скачать
              </Button>
            </div>
          ))}
        </Col>
        <Col flex={1}>
          <Button
            type={"link"}
            onClick={() => openCreateLicenceKeysModal({ orgId })}
          >
            Выпустить
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Table
        style={{
          marginTop: 10,
        }}
        columns={columns}
        bordered
        dataSource={companiesList.map((e) => ({ ...e, key: e.id }))}
        expandable={{
          expandedRowRender,
        }}
      />
      <ShowImgModal
        src={previewCompanyImg}
        closeModalFn={clearPreviewCompanyImg}
      />
    </>
  );
};

export default ListOfOrganizations;

function downloadLicenceKeys(licenseKeys: LicenseKeyInDb[]) {
  convertJsonToCsv(
    [{ label: "Licence key", value: "licenseKey" }],
    licenseKeys
  );
}
