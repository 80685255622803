import React from "react";
import MasterTrainingCard from "components/MasterTrainingCard";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import { MasterTraining } from "sport-app-types/src/client/index";

const InstructorMasterTrainingsList: React.FC<{
  masterTrainings: MasterTraining[];
}> = ({ masterTrainings }) => {
  console.log(masterTrainings);
  return (
    <Row
      gutter={[16, 16]}
      style={{
        marginTop: 40,
      }}
    >
      {masterTrainings.map((masterTraining) => (
        <Col span={6} key={masterTraining.id}>
          <Link to={`/masterTrainings/edit/${masterTraining.id}`}>
            <MasterTrainingCard {...masterTraining} />
          </Link>
        </Col>
      ))}
    </Row>
  );
};

export default InstructorMasterTrainingsList;
