import { assign, createMachine } from "xstate";
import { B2BOrganizationInDb, LicenseKeyInDb } from "sport-app-types";
import { getB2bOrganizationsList } from "app/api/getData/getB2BOrganizationsList";
import { createB2BOrganization } from "app/api/createData/createB2BCompany";
import { PartialBy } from "lib/partialBy";
import { updateB2BOrganizationInServer } from "app/api/updateData/updateB2BOrganization";
import {
  createLicenseKeysInServer,
  CreateLicenseKeysProps,
} from "app/api/createData/createLicenseKeys";
import { getB2BLicenceKeysList } from "app/api/getData/getB2BLicenceKeysList";

export interface B2BOrganizationsMachineContext {
  organizationsListLoading: boolean;
  searchOrganizationParams: Record<string, string>;
  searchLicenceKeysParams: Record<string, string>;
  licenceKeysLoading: boolean;
  createLicenceKeyModalIsOpen: boolean;
  createOrEditOrganizationModalIsOpen: boolean;
  organizationsHashTable: Record<string, B2BOrganizationInDb>;
  licenseKeysHashTable: Record<string, LicenseKeyInDb> | null;
  selectedOrganization: PartialBy<B2BOrganizationInDb, "id"> | null;
  createLicenceKeyParams: CreateLicenseKeysProps | null;
  createLicenceKeyLoading: boolean;
  createOrEditOrganizationLoading: boolean;
}

export type B2BOrganizationsMachineEvents =
  | {
      type: "SYNC_ORGANIZATIONS_LIST";
    }
  | {
      type: "UPDATE_ORGANIZATION";
      payload: B2BOrganizationInDb;
    }
  | {
      type: "OPEN_CREATE_OR_EDIT_ORGANIZATION_MODAL";
      payload?: Partial<Omit<B2BOrganizationInDb, "id">>;
    }
  | {
      type: "CLOSE_CREATE_OR_EDIT_ORGANIZATION_MODAL";
    }
  | {
      type: "CREATE_ORGANIZATION";
      payload: PartialBy<B2BOrganizationInDb, "id">;
    }
  | {
      type: "SELECT_ORGANIZATION";
      payload: PartialBy<B2BOrganizationInDb, "id">;
    }
  | {
      type: "CLEAR_SELECTED_ORGANIZATION";
    }
  | {
      type: "SYNC_LICENSE_KEYS_LIST";
    }
  | {
      type: "DELETE_LICENCE_KEY";
      payload: Omit<B2BOrganizationInDb, "id">;
    }
  | {
      type: "CREATE_LICENCE_KEY";
      payload: CreateLicenseKeysProps;
    }
  | {
      type: "CHANGE_SEARCH_PARAMS";
      payload: Record<string, string>;
    }
  | {
      type: "OPEN_CREATE_LICENCE_KEY_MODAL";
      payload?: CreateLicenseKeysProps | null;
    }
  | {
      type: "CLOSE_CREATE_LICENCE_KEY_MODAL";
    };

export const b2bOrganizationsMachine = createMachine<
  B2BOrganizationsMachineContext,
  B2BOrganizationsMachineEvents
>({
  id: "b2bListOfOrganizationsMachine",
  initial: "idle",
  context: {
    organizationsHashTable: {},
    organizationsListLoading: false,
    createLicenceKeyModalIsOpen: false,
    createOrEditOrganizationModalIsOpen: false,
    createLicenceKeyLoading: false,
    licenceKeysLoading: false,
    selectedOrganization: null,
    licenseKeysHashTable: {},
    searchLicenceKeysParams: {},
    searchOrganizationParams: {},
    createOrEditOrganizationLoading: false,
    createLicenceKeyParams: null,
  },
  states: {
    idle: {
      on: {
        SYNC_ORGANIZATIONS_LIST: {
          target: "syncingOrganizationsList",
          actions: assign({
            organizationsListLoading: (_) => true,
          }),
        },
        UPDATE_ORGANIZATION: {
          target: "updatingOrganization",
          actions: assign({
            selectedOrganization: (_, { payload }) => payload,
            createOrEditOrganizationLoading: (_) => true,
          }),
        },
        OPEN_CREATE_OR_EDIT_ORGANIZATION_MODAL: {
          actions: assign({
            createOrEditOrganizationModalIsOpen: (_) => true,
            selectedOrganization: (_, { payload }) => payload || null,
          }),
        },
        CLOSE_CREATE_OR_EDIT_ORGANIZATION_MODAL: {
          actions: assign({
            createOrEditOrganizationModalIsOpen: (_) => false,
            selectedOrganization: (_) => null,
          }),
        },
        CREATE_ORGANIZATION: {
          target: "creatingOrganization",
          actions: assign({
            createOrEditOrganizationLoading: (_) => true,
            selectedOrganization: (_, { payload }) => payload,
          }),
        },
        SELECT_ORGANIZATION: {
          actions: assign({
            selectedOrganization: (_, { payload }) => payload,
          }),
        },
        CLEAR_SELECTED_ORGANIZATION: {
          actions: assign({
            selectedOrganization: (_) => null,
          }),
        },
        SYNC_LICENSE_KEYS_LIST: {
          target: "syncingLicenceKeys",
          actions: assign({
            licenceKeysLoading: (_) => true,
          }),
        },
        OPEN_CREATE_LICENCE_KEY_MODAL: {
          actions: assign({
            createLicenceKeyModalIsOpen: (_, { payload }) => !payload,
            createLicenceKeyParams: (_, { payload }) => payload || null,
          }),
        },
        CLOSE_CREATE_LICENCE_KEY_MODAL: {
          actions: assign({
            createLicenceKeyModalIsOpen: (_) => false,
            createLicenceKeyParams: (_) => null,
            selectedOrganization: (_) => null,
          }),
        },
        CREATE_LICENCE_KEY: {
          target: "creatingLicenceKeys",
          actions: assign({
            createLicenceKeyParams: (_, { payload }) => payload || null,
          }),
        },
      },
    },
    creatingOrganization: {
      invoke: {
        id: "b2bCreateOrganization",
        src: ({ selectedOrganization }) => {
          if (!selectedOrganization) {
            throw "Заполните корректно данные";
          }
          return createB2BOrganization(selectedOrganization);
        },
        onDone: {
          actions: assign({
            organizationsHashTable: ({ organizationsHashTable }, { data }) => ({
              ...organizationsHashTable,
              [data.id]: data,
            }),
            selectedOrganization: (_) => null,
            createOrEditOrganizationLoading: (_) => false,
            createOrEditOrganizationModalIsOpen: (_) => false,
          }),
          target: "idle",
        },
        onError: {
          target: "idle",
          actions: assign({
            createOrEditOrganizationLoading: (_) => false,
          }),
        },
      },
    },
    updatingOrganization: {
      invoke: {
        id: "b2bUpdateOrganization",
        src: ({ selectedOrganization }) => {
          if (!selectedOrganization?.id) {
            throw "Заполните корректно данные";
          }
          return updateB2BOrganizationInServer(
            selectedOrganization as B2BOrganizationInDb
          );
        },
        onDone: {
          actions: assign({
            selectedOrganization: (_) => null,
            organizationsHashTable: ({ organizationsHashTable }, { data }) => ({
              ...organizationsHashTable,
              [data.id]: data,
            }),
            createOrEditOrganizationLoading: (_) => false,
            createOrEditOrganizationModalIsOpen: (_) => false,
          }),
          target: "idle",
        },
        onError: {
          target: "idle",
          actions: assign({
            createOrEditOrganizationLoading: (_) => false,
          }),
        },
      },
    },
    syncingOrganizationsList: {
      invoke: {
        id: "b2bListOfOrganizationsSyncing",
        src: (_) => getB2bOrganizationsList(),
        onDone: {
          actions: assign({
            organizationsHashTable: ({ organizationsHashTable }, { data }) =>
              (data as B2BOrganizationInDb[]).reduce(
                (acc, val) => ({ ...acc, [val.id]: val }),
                {}
              ),
            organizationsListLoading: (_) => false,
          }),
          target: "idle",
        },
        onError: {
          target: "idle",
          actions: assign({
            organizationsListLoading: (_) => false,
          }),
        },
      },
    },
    syncingLicenceKeys: {
      invoke: {
        src: ({ searchLicenceKeysParams }) =>
          getB2BLicenceKeysList(searchLicenceKeysParams),
        onDone: {
          actions: assign({
            // TODO Нормализировать по id
            licenseKeysHashTable: (_, { data }) => data,
            licenceKeysLoading: (_) => false,
          }),
          target: "idle",
        },
        onError: {
          target: "idle",
          actions: assign({
            licenceKeysLoading: (_) => false,
          }),
        },
      },
    },
    creatingLicenceKeys: {
      invoke: {
        src: ({ createLicenceKeyParams }) => {
          if (!createLicenceKeyParams?.orgId) {
            throw "No organization id!";
          }
          return createLicenseKeysInServer(createLicenceKeyParams);
        },
        onDone: {
          target: "idle",
          actions: assign({
            createLicenceKeyLoading: (_) => false,
            createLicenceKeyModalIsOpen: (_) => false,
            createLicenceKeyParams: (_) => null,
            organizationsHashTable: (
              { organizationsHashTable, createLicenceKeyParams },
              { data }
            ) => {
              const updatingOrganization =
                organizationsHashTable[createLicenceKeyParams?.orgId || ""];

              const updatingOrganizationLicenceKeys =
                updatingOrganization?.licenseKeys || [];

              return {
                ...organizationsHashTable,
                [createLicenceKeyParams?.orgId || ""]: {
                  ...organizationsHashTable[
                    createLicenceKeyParams?.orgId || ""
                  ],
                  licenseKeys: updatingOrganizationLicenceKeys.concat(data),
                },
              };
            },
          }),
        },
        onError: {
          target: "idle",
          actions: assign({
            createLicenceKeyLoading: (_) => false,
          }),
        },
      },
    },
  },
});
