import React, { useCallback, useEffect, useState } from "react";
import { useMarathonsDataArr } from "app/xstate/marathons/hooks/useMarathonsDataArr";
import MarathonCard from "components/marathonCard";
import { useSyncMarathonsData } from "app/xstate/marathons/hooks/useSyncMarathonsData";
import s from "./styles.module.scss";
import { Row, Typography } from "antd";
import MarathonsListFilter, {
  marathonsFilters,
  MarathonsListFilterValue,
  OnMarathonsListFilterChange,
} from "pages/marathonsList/filter";
import { MarathonStatus } from "sport-app-types";
import { Link } from "react-router-dom";
import { operatorRouteFns } from "app/routes/constants";

const defaultMarathonsListFilters: MarathonsListFilterValue = {
  [MarathonStatus.active]: true,
  [MarathonStatus.draft]: true,
  [MarathonStatus.finished]: false,
  planned: true,
};

const MarathonsListPage: React.FC = () => {
  const marathons = useMarathonsDataArr();
  const syncMarathons = useSyncMarathonsData();
  const [filters, setFilters] = useState<MarathonsListFilterValue>(
    defaultMarathonsListFilters
  );
  const onFilterChange: OnMarathonsListFilterChange = useCallback((filter) => {
    setFilters((oldFilters) => ({
      ...oldFilters,
      [filter]: !oldFilters[filter],
    }));
  }, []);

  const filteredMarathons = marathons.filter((marathon) =>
    (Object.keys(filters) as Array<keyof typeof filters>).find(
      (filterKey) =>
        filters[filterKey] && marathonsFilters[filterKey].filterFn(marathon)
    )
  );

  useEffect(() => {
    syncMarathons();
  }, []);

  return (
    <div>
      <div className={s.marathonsControllers}>
        <Typography.Title>Марафоны</Typography.Title>
        <Typography.Text>Создание и редактирование</Typography.Text>

        <Row
          style={{
            marginTop: 30,
          }}
        >
          <MarathonsListFilter onChange={onFilterChange} value={filters} />
        </Row>
      </div>

      <div className={s.marathonsListContainer}>
        <Link
          className={s.goToCreateMarathon}
          to={operatorRouteFns.createMarathon()}
        >
          +
        </Link>
        {filteredMarathons.map((marathon) => (
          <Link
            to={operatorRouteFns.editMarathon(marathon.id)}
            key={marathon.id}
          >
            <MarathonCard marathon={marathon} />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default MarathonsListPage;
