import { Api } from "app/api/api";
import { getUrlFns } from "app/api/getData/constants";
import { Marathon, MarathonStatus } from "sport-app-types";
import { date } from "lib/dates";

export function filterMarathonsNeedSubmitRequests(
  marathons: Marathon[]
): Marathon[] {
  return marathons.filter(
    (marathon) => marathon.status === MarathonStatus.draft
  );
}

export function filterScheduledMarathons(marathons: Marathon[]): Marathon[] {
  return marathons.filter(
    (marathon) => marathon.status === MarathonStatus.active
  );
}

export async function getMarathons(
  filter?: (marathon: Marathon) => boolean
): Promise<Marathon[]> {
  const marathons = await Api.get<Marathon[]>(getUrlFns.marathons()).then(
    (d) => d.data
  );

  const todayDateString = date().toISOString();

  const preparedMarathons: Marathon[] = marathons.map((marathon) => ({
    ...marathon,
    startDate: marathon.startDate || todayDateString,
    finishDate: marathon.finishDate || todayDateString,
  }));

  if (filter) {
    return preparedMarathons.filter(filter);
  }

  return preparedMarathons;
}
