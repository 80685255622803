import React, { useMemo } from "react";
import { Marathon, MarathonStatus } from "sport-app-types";
import { Row, Switch } from "antd";
import { date } from "lib/dates";
import Progress from "components/progress";
import s from "./styles.module.scss";
import { FieldTimeOutlined, UserOutlined } from "@ant-design/icons";
import { SwitchChangeEventHandler } from "antd/lib/switch";
import { useUpdateMarathonStatus } from "app/xstate/marathons/hooks/useUpdateMarathon";

const MarathonCard: React.FC<{
  marathon: Partial<Marathon>;
}> = ({ marathon }) => {
  const covers = marathon.covers || [];
  const volume = marathon.volume || 1;
  const updateMarathonStatus = useUpdateMarathonStatus();
  const finishedTrainings = useMemo(
    () =>
      marathon?.trainings?.filter((training) =>
        date(training.startDate).isBefore(date())
      ),
    [marathon]
  );

  const marathonStatus =
    marathon.status === MarathonStatus.draft ? (
      "Черновик"
    ) : (
      <div className={s.marathonUsers}>
        {
          // @ts-ignore
          marathon.users?.length
        }
        <UserOutlined />
      </div>
    );

  const toggleMarathonStatus: SwitchChangeEventHandler = (value) => {
    console.log(value);
    if (!marathon.id) {
      return;
    }
    updateMarathonStatus(
      marathon.id,
      marathon.status !== MarathonStatus.draft
        ? MarathonStatus.draft
        : MarathonStatus.active
    );
  };

  return (
    <div className={s.cardWrapper}>
      <div
        style={{
          backgroundImage: `url(${covers[0]})`,
        }}
        className={s.cardContainer}
      >
        <div>
          <strong
            style={{
              fontSize: 20,
            }}
          >
            {marathon.name}
          </strong>
        </div>

        <div>
          {marathon.instructor?.map((instructor) => (
            <div
              style={{
                padding: 3,
                backgroundColor: "rgba(255,255,255,0.32)",
                color: "black",
                marginTop: 2,
              }}
            >
              {instructor.lastName} {instructor.firstName}
            </div>
          ))}
        </div>

        {marathon.startDate && (
          <Row className={s.marathonInterval}>
            {date(marathon.startDate).format("DD.MM")}
            {" - "}
            {date(marathon.finishDate).format("DD.MM")}
          </Row>
        )}
        <div
          style={{
            marginTop: 28,
          }}
        >
          <Progress
            successPercent={(finishedTrainings?.length || 0) / volume}
          />
        </div>
      </div>
      <div
        className={s.marathonStatus}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div>{marathon.id && marathonStatus}</div>
        <div>
          {marathon.volume} <FieldTimeOutlined />
        </div>
        {marathon.status !== MarathonStatus.finished && marathon.id && (
          <Switch
            onChange={toggleMarathonStatus}
            checked={marathon.status !== MarathonStatus.draft}
          />
        )}
      </div>
    </div>
  );
};

export default MarathonCard;
