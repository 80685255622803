import { assign, createMachine } from "xstate";
import {
  getCustomersList,
  GetCustomersListProps,
} from "app/api/getData/getCustomersList";
import { Customer } from "sport-app-types/src/client";

interface CustomersMachine {
  customers: Customer[];
  loading: boolean;
  search: GetCustomersListProps;
}

type CustomerMachineEvents =
  | {
      type: "IDLE";
    }
  | {
      type: "SEARCH";
    }
  | {
      type: "SET_SEARCH_PARAMS";
      search: GetCustomersListProps;
    };

export const customersMachine = createMachine<
  CustomersMachine,
  CustomerMachineEvents
>({
  id: "customersMachine",
  initial: "idle",
  context: {
    customers: [],
    loading: false,
    search: { field: "firstName", query: "" },
  },
  states: {
    idle: {
      on: {
        SEARCH: {
          target: "searching",
          actions: assign({
            loading: (_) => true,
          }),
        },
        SET_SEARCH_PARAMS: {
          actions: assign({
            search: (_, event) => event.search,
          }),
        },
      },
    },
    searching: {
      invoke: {
        id: "customersListSearch",
        src: (context) => {
          console.log("search: ", context.search);
          return getCustomersList(context.search);
        },
        onDone: {
          actions: assign({
            customers: (_, event) => event.data,
            loading: (_) => false,
          }),
          target: "idle",
        },
      },
    },
  },
});
