import React, { useEffect } from "react";
import { ColumnsType } from "antd/lib/table";
import { TrainingStatistics } from "app/api/getData/getTrainingStatistics";
import { Moment } from "moment";
import { convertJsonToCsv } from "lib/jsonToCsv";
import { toast } from "react-hot-toast";
import { date, dateFromUtc, timeFormatFull } from "lib/dates";
import { timeFormatDDMMYYYY } from "app/api/getData/getB2BLicenceKeysList";
import { useTrainingStatisticsData } from "app/xstate/trainingStatistics/hooks/useTrainingStatisticsData";
import { useTrainingStatisticsActions } from "app/xstate/trainingStatistics/hooks/useTrainingStatisticsActions";

export function useTrainingStatistics() {
  const { trainingsList } = useTrainingStatisticsData();
  const { setSearchParams, syncTrainingStatistics } =
    useTrainingStatisticsActions();

  const columns: ColumnsType<TrainingStatistics> = [
    {
      key: "dateTime",
      dataIndex: "startTrainingTime",
      title: "Дата",
      render: (startDate) => (
        <span>{date(startDate).format(timeFormatDDMMYYYY)}</span>
      ),
      sorter: (a, b) =>
        dateFromUtc(a?.startTrainingTime).isBefore(b?.startTrainingTime)
          ? 1
          : -1,
    },
    {
      key: "instructor",
      dataIndex: "training",
      title: "Тренер",
      render: (training) => (
        <span>
          {training.instructor?.lastName} {training.instructor?.firstName}
        </span>
      ),
    },
    {
      key: "masterTraining",
      dataIndex: "training",
      title: "Тренировка",
      render: (training) => <span>{training?.masterTraining?.name}</span>,
    },
    {
      key: "startTimePlan",
      dataIndex: "training",
      title: "Старт (план)",
      render: (training) => (
        <span>{date(training.startDate).format(timeFormatFull)}</span>
      ),
    },
    {
      key: "startTimeFact",
      dataIndex: "startTrainingTime",
      title: "Старт (факт)",
      render: (startDate) => (
        <span>{date(startDate).format(timeFormatFull)}</span>
      ),
    },
    {
      key: "trainingStatus",
      dataIndex: "trainingStatus",
      title: "Статус",
    },
    {
      key: "amountOfUsers",
      dataIndex: "amountOfUsers",
      title: "Гости (см)",
    },
    {
      key: "usersWVmore50",
      dataIndex: "usersWVmore50",
      title: "Гости (досм)",
    },
  ];

  const onRangeChange = (range: [Moment | null, Moment | null] | null) => {
    if (!range || !range[0] || !range[1]) {
      return;
    }
    setSearchParams({
      gte: range[0]?.startOf("d").toISOString(),
      lte: range[1]?.endOf("d").toISOString(),
    });
  };

  const downloadTrainingsStatistics = () => {
    if (!trainingsList) {
      return;
    }
    convertJsonToCsv<TrainingStatistics>(
      [
        {
          label: "Дата",
          value: (trainingStatistics: TrainingStatistics) =>
            date(trainingStatistics.startTrainingTime).format(
              timeFormatDDMMYYYY
            ),
        },
        {
          label: "Тренер",
          value: (trainingStatistics: TrainingStatistics) =>
            `${trainingStatistics.training.instructor?.lastName} ${trainingStatistics.training.instructor?.firstName}`,
        },
        {
          label: "Тренировка",
          value: (trainingStatistics: TrainingStatistics) =>
            `${trainingStatistics?.training.masterTraining?.name}`,
        },
        {
          label: "Старт (план)",
          value: (trainingStatistics: TrainingStatistics) =>
            `${date(trainingStatistics?.training?.startDate).format(
              timeFormatFull
            )}`,
        },
        {
          label: "Старт (факт)",
          value: (trainingStatistics: TrainingStatistics) =>
            `${date(trainingStatistics?.startTrainingTime).format(
              timeFormatFull
            )}`,
        },
        {
          label: "Статус",
          value: (trainingStatistics: TrainingStatistics) =>
            `${trainingStatistics?.trainingStatus}`,
        },
        {
          label: "Гости (см)",
          value: (trainingStatistics: TrainingStatistics) =>
            `${trainingStatistics?.amountOfUsers}`,
        },
        {
          label: "Гости (досм)",
          value: (trainingStatistics: TrainingStatistics) =>
            `${trainingStatistics?.usersWVmore50}`,
        },
      ],
      trainingsList
    )
      .then(() => {
        toast.success("Начало загрузки");
      })
      .catch(() => {
        toast.error("Что то пошло не так");
      });
  };

  useEffect(() => {
    syncTrainingStatistics();
  }, []);

  return {
    downloadTrainingsStatistics,
    onRangeChange,
    columns,
  };
}
