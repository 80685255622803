import { assign, createMachine } from "xstate";
import {
  getTrainingStatistics,
  GetTrainingStatisticsProps,
  TrainingStatistics,
} from "app/api/getData/getTrainingStatistics";
import { date } from "lib/dates";

type TrainingStatisticsEvents =
  | {
      type: "IDLE";
    }
  | {
      type: "SYNC_TRAINING_STATISTICS";
    }
  | {
      type: "SET_SEARCH_PARAMS";
      search: GetTrainingStatisticsProps;
    };

export interface TrainingStatisticsMachineContext {
  trainingsList: TrainingStatistics[] | null;
  loading: boolean;
  search: GetTrainingStatisticsProps;
}

export const trainingStatisticsMachine = createMachine<
  TrainingStatisticsMachineContext,
  TrainingStatisticsEvents
>({
  id: "trainingStatistics",
  initial: "idle",
  context: {
    trainingsList: null,
    loading: true,
    search: {
      lte: date().endOf("d").toISOString(),
      gte: date().subtract(1, "w").startOf("d").toISOString(),
    },
  },
  states: {
    idle: {
      on: {
        SYNC_TRAINING_STATISTICS: {
          target: "searching",
          actions: assign({
            loading: (_) => true,
          }),
        },
        SET_SEARCH_PARAMS: {
          actions: assign({
            search: (_, event) => event.search,
          }),
        },
      },
    },
    searching: {
      invoke: {
        id: "loadTrainingStatistics",
        src: ({ search }) => getTrainingStatistics(search),
        onDone: {
          actions: assign({
            loading: (_) => false,
            trainingsList: (_, event) => event.data,
          }),
          target: "idle",
        },
      },
    },
  },
});
