import { assign, createMachine } from "xstate";
import { getMarathons } from "app/api/getData/getMarathons";
import { confirmMarathonInServer } from "app/api/updateData/confirmMarathon";
import { toast } from "react-hot-toast";
import { Marathon, MarathonStatus } from "sport-app-types";
import {
  UpdateMarathonParams,
  updateMarathonStatusInServer,
} from "app/api/updateData/updateMarathon";

export interface MarathonsMachineContext {
  marathons: Record<string, Marathon>;
  loading: boolean;
  confirmingMarathonId: string | null;
}

type MarathonsMachineActions =
  | {
      type: "SYNC_DATA";
    }
  | {
      type: "CONFIRM_MARATHON";
      payload: {
        id: string;
      };
    }
  | {
      type: "UPDATE_MARATHON";
      payload: UpdateMarathonParams;
    }
  | {
      type: "UPDATE_MARATHON_STATUS";
      payload: {
        marathonId: string;
        status: MarathonStatus;
      };
    };

export const marathonsMachine = createMachine<
  MarathonsMachineContext,
  MarathonsMachineActions
>({
  key: "marathonsMachine",
  context: {
    marathons: {},
    confirmingMarathonId: null,
    loading: false,
  },
  initial: "idle",
  states: {
    idle: {
      on: {
        SYNC_DATA: {
          target: "dataLoading",
          actions: assign({
            loading: (_) => true,
          }),
        },
        CONFIRM_MARATHON: {
          target: "marathonConfirming",
          actions: assign({
            loading: (_) => true,
            confirmingMarathonId: (_, { payload }) => payload.id,
          }),
        },
        UPDATE_MARATHON_STATUS: {
          target: "updatingMarathonStatus",
          actions: assign({
            loading: (_) => true,
          }),
        },
      },
    },
    marathonConfirming: {
      invoke: {
        src: ({ confirmingMarathonId }) =>
          confirmMarathonInServer(confirmingMarathonId),
        onDone: {
          target: "idle",
          actions: assign({
            loading: (_) => false,
            confirmingMarathonId: (_) => null,
            marathons: ({ marathons }, { data }) => {
              if (!data?.id) {
                toast.error("В марафоне не хватает тренировок");
                return marathons;
              }
              return {
                ...marathons,
                [data.id]: data,
              };
            },
          }),
        },
        onError: {
          target: "idle",
          actions: assign({
            loading: (_) => false,
            confirmingMarathonId: (_) => null,
          }),
        },
      },
    },
    updatingMarathonStatus: {
      invoke: {
        src: (_, d) => {
          if (d.type === "UPDATE_MARATHON_STATUS") {
            return updateMarathonStatusInServer(
              d?.payload.marathonId,
              d.payload.status
            );
          }
          return Promise.resolve();
        },
        onDone: {
          target: "idle",
          actions: assign({
            loading: (_) => false,
            confirmingMarathonId: (_) => null,
            marathons: ({ marathons }, { data }) => {
              console.log(data);
              if (!data?.id) {
                return marathons;
              }
              return {
                ...marathons,
                [data.id]: data,
              };
            },
          }),
        },
        onError: {
          target: "idle",
          actions: assign({
            loading: (_) => false,
            confirmingMarathonId: (_) => null,
          }),
        },
      },
    },
    dataLoading: {
      invoke: {
        src: () => getMarathons(),
        onDone: {
          target: "idle",
          actions: assign({
            loading: (_) => false,
            marathons: (_, { data }) =>
              data?.reduce(
                (acc: Record<string, Marathon>, marathon: Marathon) => ({
                  ...acc,
                  [marathon.id]: marathon,
                }),
                {} as Record<string, Marathon>
              ),
          }),
        },
        onError: {
          target: "idle",
          actions: assign({
            loading: (_) => false,
          }),
        },
      },
    },
  },
});
