import { Api } from "app/api/api";
import { GetUrlFnNames, getUrlFns } from "app/api/getData/constants";

export enum UploadFileToStorageFolders {
  masterTrainingCovers = "masterTrainingCovers",
  avatars = "avatars",
  instructorCovers = "instructorCovers",
  marathonCovers = "marathonCovers",
}

export async function uploadFileToStorage(
  folder: UploadFileToStorageFolders,
  urlData: string,
  onUploadProgress?: (percent: number) => void
): Promise<string> {
  try {
    if (
      urlData.slice(0, 50)?.includes("cloudinary") ||
      urlData.slice(0, 50)?.includes("firebasestorage")
    ) {
      return Promise.resolve(urlData);
    }

    // const compressedImage = await compressImage(urlData);

    return Api.post<
      string,
      {
        image: string;
        folderName: UploadFileToStorageFolders;
      }
    >(
      getUrlFns[GetUrlFnNames.uploadFileToStorage](),
      {
        folderName: folder,
        image: urlData,
      },
      onUploadProgress
    ).then((d) => d.data);

    // const preparedUrl =
    //   folder[folder.length] === "/" ? folder + uuid() : `/${url}${uuid()}`;
    // return firebase
    //   .storage()
    //   .ref(preparedUrl)
    //   .put(blobFile)
    //   .then((snapshot) => snapshot.ref.getDownloadURL())
    //   .catch((e) => {
    //     console.log("Cant upload file, ", e);
    //     toast.error("Загрузка не удалась");
    //   });
  } catch (e) {
    console.log("Ошибка при загрузке файла в хранилище. ", e);
    return "";
  }
}

export type UploadFilesToStorageFile = {
  folder: UploadFileToStorageFolders;
  file: string;
};

export function uploadFilesToStorage(
  files: UploadFilesToStorageFile[]
): Promise<string[]> {
  const uploadProcess = files.map(({ file, folder }) =>
    uploadFileToStorage(folder, file)
  );

  return Promise.all(uploadProcess);
}
