import { useMarathonsActor } from "app/xstate/marathons/hooks/useMarathonsActor";
import { useCallback } from "react";
import { UpdatingMarathonInfo } from "app/api/updateData/updateMarathon";
import { MarathonStatus } from "sport-app-types";

export function useUpdateMarathon() {
  const [, send] = useMarathonsActor();

  return useCallback(
    (marathonId: string, updatingMarathonData: UpdatingMarathonInfo) => {
      send({
        type: "UPDATE_MARATHON",
        payload: {
          marathonId,
          ...updatingMarathonData,
        },
      });
    },
    []
  );
}

export function useUpdateMarathonStatus() {
  const [, send] = useMarathonsActor();

  return useCallback((marathonId: string, status: MarathonStatus) => {
    send({
      type: "UPDATE_MARATHON_STATUS",
      payload: {
        marathonId,
        status,
      },
    });
  }, []);
}
