import { useCallback } from "react";
import { GetInstructorsListProps } from "app/api/getData/getInstructorsList";
import { useInstructorsActor } from "app/xstate/instructors/hooks/useInstructorsActor";
import { CreateInstructorParams } from "app/types/operator/createTrainerRequest";

export function useInstructorsActions() {
  const [_, send] = useInstructorsActor();
  const updateSearchInstructorsParams = useCallback(
    (searchParams: GetInstructorsListProps) => {
      send({
        type: "SET_SEARCH_PARAMS",
        searchParams,
      });
    },
    []
  );

  const syncListOfCustomers = useCallback(() => {
    send({ type: "SYNC_INSTRUCTORS_LIST" });
  }, []);

  const openCreateInstructorModal = useCallback(() => {
    send({
      type: "OPEN_CREATE_NEW_INSTRUCTOR_MODAL",
    });
  }, []);

  const closeCreateInstructorModal = useCallback(() => {
    send({
      type: "CLOSE_CREATE_NEW_INSTRUCTOR_MODAL",
    });
  }, []);

  const createInstructor = useCallback((params: CreateInstructorParams) => {
    send({
      type: "CREATE_NEW_INSTRUCTOR",
      payload: params,
    });
  }, []);

  return {
    updateSearchInstructorsParams,
    syncListOfCustomers,
    openCreateInstructorModal,
    closeCreateInstructorModal,
    createInstructor,
  };
}
