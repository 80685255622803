import {
  GlobalStateSelectorFn,
  useGlobalStateSelector,
} from "app/xstate/globalState/globalStateProvider";
import { useMemo } from "react";
import { Marathon } from "sport-app-types";

const select: GlobalStateSelectorFn<"marathons", Marathon[]> = (state) =>
  Object.values(state.marathons);

export function useMarathonsDataArr() {
  const marathonsData = useGlobalStateSelector("marathons", select);

  return useMemo(() => marathonsData, [marathonsData]);
}
