import { B2BOrganizationInDb } from "sport-app-types";
import { Api } from "app/api/api";
import { GetUrlFnNames, getUrlFns } from "app/api/getData/constants";

export async function createB2BOrganization(
  company: Omit<B2BOrganizationInDb, "id">
): Promise<B2BOrganizationInDb> {
  return Api.post<B2BOrganizationInDb, Omit<B2BOrganizationInDb, "id">>(
    getUrlFns[GetUrlFnNames.b2bCreateOrganization](),
    company
  ).then((d) => d.data);
}
