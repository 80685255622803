import React from "react";
import { Spin } from "antd";
import s from "./styles.module.scss";

export enum LoaderVariants {
  big = "large",
  small = "small",
}

// eslint-disable-next-line
const Loader: React.FC<{ variant?: LoaderVariants; className?: string }> = ({
  variant = LoaderVariants.big,
  className,
}) => (
  <div className={`${s.bigLoaderContainer} ${className || ""}`}>
    <Spin size={variant} />
  </div>
);

export default Loader;
