import axios, { AxiosResponse } from "axios";
import { useCallback } from "react";
import { auth } from "index";
import { getUrlFns } from "app/api/getData/constants";

const RESET_TOKEN_INTERVAL = 3000000;
let token: string | null = null;
let updateTokenInterval: ReturnType<typeof setTimeout> | null = null;

export function setToken(newToken: string) {
  if (newToken) {
    token = newToken;
  }
}

// export function useSyncToken() {
//   const { getAccessTokenSilently } = useAuth0();
//
//   const syncToken = useCallback(async () => {
//     const newToken = await getAccessTokenSilently({
//       detailedResponse: true,
//       // audience: "https://auth0-jwt-authorizer",
//     });
//     setToken(newToken.access_token);
//   }, []);
//
//   const startUpdatingToken = useCallback(() => {
//     if (updateTokenInterval) {
//       clearInterval(updateTokenInterval);
//     }
//     updateTokenInterval = setInterval(syncToken, RESET_TOKEN_INTERVAL);
//   }, []);
//
//   return {
//     syncToken,
//     startUpdatingToken,
//   };
// }

export function useSyncToken() {
  const syncToken = useCallback(async () => {
    const newToken = await auth.currentUser?.getIdToken(true);
    if (!newToken) {
      return;
    }
    setToken(newToken);
  }, []);

  const startUpdatingToken = useCallback(() => {
    if (updateTokenInterval) {
      clearInterval(updateTokenInterval);
    }
    updateTokenInterval = setInterval(syncToken, RESET_TOKEN_INTERVAL);
  }, []);

  return {
    syncToken,
    startUpdatingToken,
  };
}

// Result extends Array<any> ? CollectionResult<Result> : Result

export class Api {
  static async get<
    Result,
    Params extends Record<string, string | boolean | number | null> = {}
  >(
    url: ReturnType<typeof getUrlFns[keyof typeof getUrlFns]>,
    params?: Params
  ) {
    return axios.get<Params, AxiosResponse<Result>>(url, {
      params,
      headers: {
        ...(token ? { Authorization: token } : {}),
      },
    });
  }

  static async post<Result, Params extends Record<string, any> = {}>(
    url: ReturnType<typeof getUrlFns[keyof typeof getUrlFns]>,
    params: Params = {} as Params,
    onUploadProgress?: (percent: number) => void
  ) {
    return axios.post<Params, AxiosResponse<Result>>(url, params, {
      headers: {
        ...(token ? { Authorization: token } : {}),
      },
      onUploadProgress: (progressEvent) => {
        if (onUploadProgress) {
          const percent = progressEvent.loaded / progressEvent.total;

          onUploadProgress(percent);
        }
      },
    });
  }

  static async put<Result, Params extends Record<string, any> = {}>(
    url: ReturnType<typeof getUrlFns[keyof typeof getUrlFns]>,
    params: Params
  ) {
    return axios.put<Params, AxiosResponse<Result>>(url, params, {
      headers: {
        ...(token ? { Authorization: token } : {}),
      },
    });
  }

  static async delete<Result, Params extends Record<string, any> = {}>(
    url: ReturnType<typeof getUrlFns[keyof typeof getUrlFns]>,
    params: Params = {} as Params
  ) {
    console.log(params);
    return axios.delete<Params, AxiosResponse<Result>>(url, {
      headers: {
        ...(token ? { Authorization: token } : {}),
      },
      params,
    });
  }
}
