import { Marathon } from "sport-app-types";
import {
  GlobalStateSelectorFn,
  useGlobalStateSelector,
} from "app/xstate/globalState/globalStateProvider";
import { useEffect } from "react";
import { useSyncMarathonsData } from "app/xstate/marathons/hooks/useSyncMarathonsData";

const marathonsHashTableSelect: GlobalStateSelectorFn<
  "marathons",
  Record<string, Marathon>
> = ({ marathons }) => marathons;

export function useMarathonsDataById(marathonId: string = ""):
  | {
      marathonDataLoading: false;
      marathon: Marathon;
    }
  | {
      marathonDataLoading: true;
      marathon: null;
    } {
  const syncMarathons = useSyncMarathonsData();
  const marathonsHashTable = useGlobalStateSelector(
    "marathons",
    marathonsHashTableSelect
  );
  const marathon: Marathon | null = marathonsHashTable[marathonId];

  useEffect(() => {
    if (!marathon) {
      syncMarathons();
    }
  }, []);

  return {
    marathon: marathon || null,
    marathonDataLoading: !marathon?.id as any,
  };
}
