import { Api } from "app/api/api";
import { GetUrlFnNames, getUrlFns } from "app/api/getData/constants";
import { Instructor, CollectionResult } from "sport-app-types";

interface GetInstructorsListPropsSimple {
  field: "email" | "firstName" | "lastName" | "phone";
  query: string;
}

interface GetInstructorsListPropsMulti {
  field: "createdAt";
  query: {
    createdAtAfter?: string;
    createdAtBefore?: string;
  };
}

export type GetInstructorsListProps =
  | GetInstructorsListPropsSimple
  | GetInstructorsListPropsMulti;

export async function getInstructorsList(
  queryParams?: GetInstructorsListProps
): Promise<Instructor[]> {
  const { query, field } = queryParams || {};

  let params: Record<string, any> = {};
  if (typeof query !== "string") {
    params = {
      ...query,
    };
  } else if (field) {
    params[field] = query;
  }
  console.log("instructors list params:", params);

  const instructorsResult = await Api.get<CollectionResult<Instructor[]>>(
    getUrlFns[GetUrlFnNames.getInstructors]()
  ).then((d) => d.data.result);

  return query && field && typeof query === "string"
    ? instructorsResult.filter(
        (instructor) =>
          instructor[field] &&
          instructor[field].toLowerCase().includes(query.toLowerCase())
      )
    : instructorsResult;
}
