import dayjs, { Dayjs } from "dayjs";
import ru from "dayjs/locale/ru";
import weekday from "dayjs/plugin/weekday";
import isBetween from "dayjs/plugin/isBetween";
import localeData from "dayjs/plugin/localeData";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

const { weekdays, weekdaysMin } = ru;

dayjs.locale(ru);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);

let timezoneVal: string = "EUROPE/MOSCOW";
function date(o?: dayjs.ConfigType, format?: string): Dayjs {
  if (format) {
    return dayjs.tz(o, format, timezoneVal);
  }
  return dayjs.tz(o, timezoneVal);
}
function dateFromUtc(o?: dayjs.ConfigType, format?: string) {
  return dayjs(o, format).tz(timezoneVal);
}

export { date, dateFromUtc };
export { dayjs as dateDef };

export function setTimezone(tz: string) {
  if (tz) {
    dayjs.tz.setDefault(tz);
    timezoneVal = tz;
  }
}

export const timeFormatShort = "YYYY-MM-DD";
export const timeFormatFull = "YYYY-MM-DD HH:mm";

export const weekdaysCorrectOrder = prepareWeekdaysArr(weekdays);
export const weekdaysMinCorrectOrder = prepareWeekdaysArr(weekdaysMin);

export const thisWeeksDates = (weekdays || [])?.map((_, idx) =>
  date().weekday(idx).format("DD")
);

export function getTodayMidnight() {
  return date().add(-date().hour(), "hour").add(-date().minute(), "minute");
}

// num - Номер недели относительно текущей
export function getWeekDates(num: number): string[] {
  return (weekdays || []).map((_, idx) =>
    date().add(num, "weeks").weekday(idx).format("DD")
  );
}

export const hoursArrayWithHourInterval = new Array(23)
  .fill("")
  .map((_, idx) => `${idx + 1 < 10 ? `0${idx + 1}` : idx + 1}:00`);

export const nextWeeksDates = new Array(7).fill("").map((_, idx) => {
  const number = +date().add(idx, "day").format("DD");
  return `${number < 10 ? `0${number}` : number}`;
});

export const nextWeekWeekdaysMin = new Array(7).fill("").map((_, idx) => {
  const dayNumber = date().add(idx, "day").weekday();
  return weekdaysMinCorrectOrder[dayNumber];
});

function prepareWeekdaysArr(weekdaysArr?: string[]) {
  return (weekdaysArr || []).map(
    (_, idx) => (weekdaysArr ?? [])[(idx + (ru?.weekStart || 0)) % 7]
  );
}

export const getThisYear = () => date().year();
export const getWeekdayNumber = () => (date().day() + 6) % 7;

export const getThisMonth = () => date().format("MMMM");

export function getLastDayOfWeek(num: number): Dayjs {
  const dateString = date()
    .weekday(7)
    .add(num, "weeks")
    .format(timeFormatShort);
  return dayjs(dateString);
}

export function getFirstDayOfWeek(num: number): Dayjs {
  const dateString = date()
    .weekday(1)
    .add(num, "weeks")
    .format(timeFormatShort);
  return dayjs(dateString);
}
