import { Api } from "app/api/api";
import { GetUrlFnNames, getUrlFns } from "app/api/getData/constants";
import { MasterTraining } from "sport-app-types/src/client";

export function getMasterTrainingInfoById({
  masterTrainingId,
}: {
  masterTrainingId: string;
}): Promise<MasterTraining | null> {
  return Api.get<MasterTraining>(
    getUrlFns[GetUrlFnNames.getMasterTrainingInfoById](masterTrainingId)
  ).then((d) => d.data);
}
