import React, { useRef } from "react";
import Portal from "components/portal";
import HlsPlayer from "react-hls-player";
import { useTrainingRecordsData } from "app/xstate/trainingRecords/hooks/useTrainingRecordsData";
import { useTrainingRecordsActions } from "app/xstate/trainingRecords/hooks/useTrainingRecordsActions";
import { Modal, Row, Typography } from "antd";

const ViewingTrainingRecord: React.FC = () => {
  const playerRef = useRef<HTMLVideoElement>(null);
  const { selectedRecord } = useTrainingRecordsData();
  const { clearSelectedRecord } = useTrainingRecordsActions();

  if (!selectedRecord?.recordURL) {
    return null;
  }

  return (
    <Portal>
      <Modal
        visible
        onOk={clearSelectedRecord}
        onCancel={clearSelectedRecord}
        cancelText={"Закрыть"}
        maskClosable={false}
      >
        <Row justify={"center"}>
          <Typography.Title level={3}>
            {selectedRecord?.instructor?.lastName}{" "}
            {selectedRecord?.instructor?.firstName}
          </Typography.Title>
        </Row>
        <Row
          justify={"center"}
          style={{
            marginTop: 10,
          }}
        >
          <HlsPlayer
            playerRef={playerRef}
            autoPlay
            controls
            src={selectedRecord.recordURL}
          />
        </Row>
      </Modal>
    </Portal>
  );
};

export default ViewingTrainingRecord;
