import { Api } from "app/api/api";
import { PromoCodeInDb } from "sport-app-types/index";
import { GetUrlFnNames, getUrlFns } from "app/api/getData/constants";

export async function createPromoCodeInServer(
  promo: PromoCodeInDb
): Promise<PromoCodeInDb> {
  try {
    const result = await Api.post<PromoCodeInDb, PromoCodeInDb>(
      getUrlFns[GetUrlFnNames.b2bCreatePromoCode](),
      promo
    );

    return result.data;
  } catch (e) {
    console.warn("Cant create promo code, ", e);
    throw "Не удалось создать промокод";
  }
}
