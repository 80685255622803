import React from "react";
import { Card, Form, Input, Modal, Row, Typography } from "antd";
import { useInstructorsActions } from "app/xstate/instructors/hooks/useInstructorsActions";
import { useInstructorsData } from "app/xstate/instructors/hooks/useInstructorsData";
import { toast } from "react-hot-toast";

const CreateInstructorModal = () => {
  const [form] = Form.useForm();
  const { closeCreateInstructorModal, createInstructor } =
    useInstructorsActions();
  const {
    createNewInstructorModalIsOpen,
    value,
    createNewInstructorParams,
    createNewInstructorLoading,
  } = useInstructorsData();

  const isShowInfoVariant = value === "showNewInstructorInfo";

  const onSubmit = async () => {
    try {
      const formData = await form.validateFields();
      console.log(formData);
      createInstructor(formData);
      form.resetFields();
    } catch (e) {
      console.warn(e);
    }
  };

  const onCancel = () => {
    form.resetFields();
    closeCreateInstructorModal();
  };

  return (
    <Modal
      title={"Новый тренер"}
      onCancel={onCancel}
      okText={isShowInfoVariant ? "Ок" : "Создать"}
      cancelText={isShowInfoVariant ? "Закрыть" : "Отменить"}
      onOk={onSubmit}
      visible={createNewInstructorModalIsOpen}
      confirmLoading={createNewInstructorLoading}
      maskClosable={false}
    >
      {isShowInfoVariant ? (
        <Card>
          <Row>
            Почта:
            <Typography.Link
              style={{
                marginLeft: 5,
              }}
              onClick={() => {
                copyText(createNewInstructorParams?.email);
              }}
            >
              {" "}
              {createNewInstructorParams?.email}
            </Typography.Link>
          </Row>
          <Row>
            Пароль:
            <Typography.Link
              style={{
                marginLeft: 5,
              }}
              onClick={() => copyText(createNewInstructorParams?.password)}
            >
              {createNewInstructorParams?.password}
            </Typography.Link>
          </Row>
        </Card>
      ) : (
        <Form
          form={form}
          name={"createNewInstructorModal"}
          labelCol={{
            span: 5,
          }}
          labelAlign={"left"}
        >
          <Form.Item
            rules={[{ required: true, message: "Обязательное поле" }]}
            label={"Имя"}
            required
            name={"firstName"}
          >
            <Input placeholder={"Введите имя тренера"} />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Обязательное поле" }]}
            label={"Фамилия"}
            required
            name={"lastName"}
          >
            <Input placeholder={"Введите фамилию тренера"} />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: "Обязательное поле" },
              {
                required: true,
                pattern: new RegExp(
                  "^(\\+7|7|8)?[\\s\\-]?\\(?[489][0-9]{2}\\)?[\\s\\-]?[0-9]{3}[\\s\\-]?[0-9]{2}[\\s\\-]?[0-9]{2}$"
                ),
                message: "Введите корректный номер терефона",
              },
            ]}
            label={"Телефон"}
            required
            name={"phone"}
          >
            <Input placeholder={"Введите телефон тренера"} />
          </Form.Item>
          <Form.Item
            name={"email"}
            label={"e-mail"}
            rules={[
              { required: true, message: "Обязательное поле" },
              { type: "email", message: "Введите корректную почту" },
            ]}
            required
          >
            <Input
              name={"email"}
              placeholder={"Введите электронную почту тренера"}
            />
          </Form.Item>
          <Form.Item label={"Пароль"} name={"password"}>
            <Input placeholder={"Опционально"} />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default CreateInstructorModal;

export function copyText(text?: string) {
  navigator.clipboard.writeText(text || "");
  toast.success("Текст успешно скопирован в буфер обмена.");
}
