import { Api } from "app/api/api";
import { getUrlFns } from "app/api/getData/constants";
import { Marathon } from "sport-app-types";
import { updateMarathonInServer } from "app/api/updateData/updateMarathon";

// export interface CreateMarathonDto {
//   name: string;
//   volume: number;
//   covers: string[];
//   trainings: string[];
//   instructors: string[];
//   startDate: string;
//   finishDate: string;
//   description: string;
//   goal: string;
// }

export type CreateOrUpdateMarathonDto = Partial<
  Pick<
    Marathon,
    | "name"
    | "volume"
    | "covers"
    | "startDate"
    | "finishDate"
    | "description"
    | "goal"
  > & {
    trainings: string[];
    instructors: string[];
  }
>;

export async function saveMarathonInServer(
  marathon: Partial<Marathon>
): Promise<Marathon> {
  const marathonDto: CreateOrUpdateMarathonDto = {
    covers: marathon.covers || [],
    description: marathon.description || "",
    instructors: marathon.instructor?.map((instructor) => instructor.id) || [],
    finishDate: marathon.finishDate || "",
    goal: marathon.goal || "",
    name: marathon.name || "",
    startDate: marathon.startDate || "",
    volume: marathon.volume || 8,
    trainings: marathon.trainings?.map((training) => training.id) || [],
  };

  if (marathon.id) {
    return updateMarathonInServer(marathon.id, marathonDto);
  }

  return Api.post<Marathon, CreateOrUpdateMarathonDto>(
    getUrlFns.createMarathon(),
    marathonDto
  ).then((d) => d.data);
}
