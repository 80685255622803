import React, { useCallback, useState } from "react";
import { CreateOrEditMarathonFormComponent } from "pages/createOrEditMarathon/types";
import { Button, Row, Typography } from "antd";
import CreateOrEditMarathonInstructorCard from "pages/createOrEditMarathon/fourthStepForm/instructorCard";
import { date } from "lib/dates";
import { Marathon, InstructorInDb, Training } from "sport-app-types";
import { generateEmptyArray } from "lib/generateEmptyArray";
import s from "./styles.module.scss";
import CreateOrEditMarathonAddTrainingRow from "pages/createOrEditMarathon/fifthStepForm/trainingRow";
import { useCreateOrEditMarathonActor } from "app/xstate/createOrEditMarathon/hooks/useCreateOrEditMarathonActor";
import { useGlobalStateSelector } from "app/xstate/globalState/globalStateProvider";

export interface AddingTrainingCords {
  instructor: Partial<InstructorInDb> | null;
  rowIndex: number | null;
}

const addingTrainingCordsDefault: AddingTrainingCords = {
  instructor: null,
  rowIndex: null,
};

const CreateOrEditMarathonFifthStepForm: CreateOrEditMarathonFormComponent = ({
  marathon,
}) => {
  const [, send] = useCreateOrEditMarathonActor();
  const marathonDuration = getMarathonDuration(marathon);
  const [addingTrainingCords, setAddingTrainingCords] =
    useState<AddingTrainingCords>(addingTrainingCordsDefault);

  const loading = useGlobalStateSelector(
    "createOrEditMarathon",
    (marathonContext) => marathonContext.marathonIsSaving
  );

  // const canSave = marathon.trainings?.length === marathon.volume;

  const canSave = !loading;

  const selectAddingTraining = useCallback((cords: AddingTrainingCords) => {
    setAddingTrainingCords(cords);
  }, []);

  const addTrainingToMarathon = useCallback(
    (training: Training) => {
      send({
        type: "UPDATE_MARATHON",
        payload: {
          trainings: [...(marathon.trainings || []), training],
        },
      });
      setAddingTrainingCords(addingTrainingCordsDefault);
    },
    [marathon]
  );

  const deleteTrainingFromMarathon = useCallback(
    (deletingTraining: Training) => {
      send({
        type: "UPDATE_MARATHON",
        payload: {
          trainings: marathon.trainings?.filter(
            (training) => training.id !== deletingTraining.id
          ),
        },
      });
      setAddingTrainingCords(addingTrainingCordsDefault);
    },
    [marathon]
  );

  const onDoneClick = useCallback(() => {
    send({
      type: "SAVE_MARATHON",
    });
  }, []);

  return (
    <div>
      <Row>
        <Typography.Title>
          Выберите тренировки {marathon?.trainings?.length || 0} /{" "}
          {marathon.volume}
        </Typography.Title>
      </Row>

      <div className={s.addTrainingRow}>
        <div className={s.dateCard}>ДАТА</div>
        {marathon.instructor?.map((instructor) => (
          <CreateOrEditMarathonInstructorCard instructor={instructor} />
        ))}
      </div>

      {generateEmptyArray(marathonDuration).map((_, index) => (
        <CreateOrEditMarathonAddTrainingRow
          marathon={marathon}
          index={index}
          addingTrainingCords={addingTrainingCords}
          selectTraining={selectAddingTraining}
          addTrainingToMarathon={addTrainingToMarathon}
          deleteTrainingFromMarathon={deleteTrainingFromMarathon}
        />
      ))}

      <Row
        style={{
          position: "fixed",
          right: 100,
          bottom: 100,
        }}
      >
        <Button onClick={onDoneClick} disabled={!canSave} loading={loading}>
          Далее
        </Button>
      </Row>
    </div>
  );
};

export default CreateOrEditMarathonFifthStepForm;

function getMarathonDuration(marathon: Partial<Marathon>): number {
  return date(marathon.finishDate).diff(marathon.startDate, "d", false) + 2;
}
