import React, { useEffect } from "react";
import { useOrganizationsData } from "app/xstate/b2bCompanies/hooks/useCompaniesData";
import Loader, { LoaderVariants } from "components/loader";
import ListOfOrganizations from "pages/b2bListOfOrganizations/listOfOrganizations";
import Title from "antd/es/typography/Title";
import { Button, Row } from "antd";
import AddOrEditCompanyModal from "pages/b2bListOfOrganizations/addOrganizationModal";
import { useOrganizationsActions } from "app/xstate/b2bCompanies/hooks/useCompaniesActions";
import IssueOfPromoCodesModal from "pages/b2bLicenceKeys/issueOfPromoCodesModal";

const B2bListOfOrganizationsPage: React.FC = () => {
  const {
    listOfOrganizationsArray,
    selectedOrganization,
    createLicenceKeyModalIsOpen,
    organizationsListLoading,
    createLicenceKeyParams,
    createOrEditOrganizationModalIsOpen,
  } = useOrganizationsData();

  const { syncOrganizationsList, openCreateOrEditOrganizationModal } =
    useOrganizationsActions();

  useEffect(() => {
    syncOrganizationsList();
  }, []);

  return (
    <>
      <Title
        style={{
          textAlign: "center",
        }}
      >
        Организации
      </Title>
      {/* TODO: Добавить ссылку */}
      <Row justify={"end"}>
        <Button
          type={"primary"}
          onClick={() => openCreateOrEditOrganizationModal()}
        >
          Создать
        </Button>
      </Row>
      {organizationsListLoading ? (
        <Loader variant={LoaderVariants.small} />
      ) : (
        <ListOfOrganizations companiesList={listOfOrganizationsArray} />
      )}
      {(createLicenceKeyParams || createLicenceKeyModalIsOpen) && (
        <IssueOfPromoCodesModal />
      )}
      {(createOrEditOrganizationModalIsOpen || !!selectedOrganization) && (
        <AddOrEditCompanyModal />
      )}
    </>
  );
};

export default B2bListOfOrganizationsPage;
