import React from "react";
import { Card } from "antd";
import { MasterTraining } from "sport-app-types/src/client";

const MasterTrainingCard: React.FC<MasterTraining> = ({ name, covers }) => {
  const mainPhoto = covers ? covers[0] : "";
  return (
    <Card
      hoverable
      style={{ width: 240 }}
      cover={
        <div
          style={{
            height: 240,
            width: 240,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundImage: `url(${mainPhoto})`,
          }}
        />
      }
    >
      <Card.Meta title={name} />
    </Card>
  );
};

export default MasterTrainingCard;
