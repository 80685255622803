import React, { useEffect } from "react";
import { getColumnSearchProps } from "components/table/getColumnSearchProps";
import { Link } from "react-router-dom";
import { Instructor } from "sport-app-types/src/client";
import { useInstructorsActions } from "app/xstate/instructors/hooks/useInstructorsActions";
import { useInstructorsData } from "app/xstate/instructors/hooks/useInstructorsData";
import {
  GlobalStateSelectorFn,
  useGlobalStateSelector,
} from "app/xstate/globalState/globalStateProvider";

const instructorsListSelector: GlobalStateSelectorFn<
  "instructors",
  Instructor[]
> = (state) => {
  const { instructorsHashTable, searchParams } = state;

  const instructorsListUnfiltered = Object.values(instructorsHashTable);

  const { query, field } = searchParams;

  if (!searchParams.query || typeof query !== "string") {
    return instructorsListUnfiltered;
  }

  return instructorsListUnfiltered.filter(
    (instructor) =>
      instructor[field] &&
      instructor[field].toLowerCase().includes(query.toLowerCase())
  );
};

export function useListOfInstructors() {
  const { syncListOfCustomers, updateSearchInstructorsParams } =
    useInstructorsActions();
  const { instructorsLoading } = useInstructorsData();

  const filteredInstructorsList: Instructor[] = useGlobalStateSelector(
    "instructors",
    instructorsListSelector
  );

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "idKey",
    },
    {
      title: "Имя",
      dataIndex: "firstName",
      key: "firstName",
      width: "20%",
      ...getColumnSearchProps({
        dataIndex: "имени",
        onChange: (e) => {
          updateSearchInstructorsParams({
            query: e.target.value,
            field: "firstName",
          });
        },
        onSubmit: syncListOfCustomers,
      }),
    },
    {
      title: "Фамилия",
      dataIndex: "lastName",
      key: "lastName",
      width: "30%",
      ...getColumnSearchProps({
        dataIndex: "Фамилии",
        onChange: (e) => {
          updateSearchInstructorsParams({
            query: e.target.value,
            field: "lastName",
          });
        },
        onSubmit: syncListOfCustomers,
      }),
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
      ...getColumnSearchProps({
        dataIndex: "телефону",
        onChange: (e) => {
          updateSearchInstructorsParams({
            query: e.target.value,
            field: "phone",
          });
        },
        onSubmit: syncListOfCustomers,
      }),
    },
    {
      title: "Почта",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps({
        dataIndex: "почте",
        onChange: (e) => {
          updateSearchInstructorsParams({
            query: e.target.value,
            field: "email",
          });
        },
        onSubmit: syncListOfCustomers,
      }),
    },
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
      render: (_: any, { id }: Instructor) => (
        <Link to={`/instructors/edit/${id}`}>Изменить</Link>
      ),
    },
  ];

  useEffect(syncListOfCustomers, []);

  return {
    instructorsListData: filteredInstructorsList,
    columns,
    instructorsListLoading: instructorsLoading,
  };
}
