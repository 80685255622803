import React, { useEffect, useMemo } from "react";
import { usePromoCodesActions } from "app/xstate/b2bPromoCodes/hooks/usePromoCodesActions";
import { ColumnsType } from "antd/es/table";
import { PromoCodeInDb } from "sport-app-types/index";
import { useB2BPromoCodesData } from "app/xstate/b2bPromoCodes/hooks/useB2BPromoCodesData";
import { dateFromUtc } from "lib/dates";
import { timeFormatDDMMYYYY } from "app/api/getData/getB2BLicenceKeysList";

export type PromoCodeWithInstructor = PromoCodeInDb & { instructor: string };

export function useB2BPromoCodes() {
  const { syncPromoCodesData } = usePromoCodesActions();
  const { promoCodesListArray, dataLoading, instructorsHashTable } =
    useB2BPromoCodesData();
  const columns: ColumnsType<PromoCodeInDb> = useMemo(
    () => [
      {
        key: "createdAt",
        dataIndex: "createdAt",
        title: "Выпущен",
        render: (value) => (
          <span>{dateFromUtc(value).format(timeFormatDDMMYYYY)}</span>
        ),
        sorter: (a, b) =>
          dateFromUtc(a.createdAt).isAfter(b.createdAt) ? -1 : 1,
      },
      {
        title: "Promo",
        dataIndex: "promocode",
        key: "promoCode",
      },
      {
        title: "Тренер",
        key: "instructor",
        dataIndex: "instructor",
      },
      {
        title: "Скидка",
        key: "type",
        dataIndex: "type",
        render: (value) => {
          switch (value) {
            case "PROMO_30":
              return "Месяц за 549";
            case "PROMO_90":
              return "3 месяца за 990";
            default:
              return "?";
          }
        },
      },
    ],
    []
  );

  const dataSource: PromoCodeWithInstructor[] = useMemo(
    () =>
      promoCodesListArray.map((code) => {
        const instructor = instructorsHashTable[code.affiliateId || ""];
        return {
          ...code,
          key: `${code.promocode} ${code.createdAt}`,
          instructor: `${instructor?.lastName || ""} ${
            instructor?.firstName || ""
          }`,
        };
      }),
    [promoCodesListArray, instructorsHashTable]
  );

  useEffect(() => {
    syncPromoCodesData();
  }, []);

  return {
    columns,
    dataLoading,
    dataSource,
  };
}
