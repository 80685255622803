import { B2BOrganizationInDb } from "sport-app-types";
import { Api } from "app/api/api";
import { GetUrlFnNames, getUrlFns } from "app/api/getData/constants";

export async function getB2bOrganizationsList(): Promise<
  B2BOrganizationInDb[]
> {
  return Api.get<B2BOrganizationInDb[]>(
    getUrlFns[GetUrlFnNames.b2bListOfOrganizations]()
  ).then((d) => d.data);
}

export function normalizeOrganizationsListById(
  listOfOrganizations: B2BOrganizationInDb[]
): Record<string, B2BOrganizationInDb> {
  return listOfOrganizations.reduce(
    (acc, organization) => ({ ...acc, [organization.id]: organization }),
    {}
  );
}
