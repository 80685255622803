import React from "react";
import { useHistory, useParams } from "react-router-dom";
import useAsync from "lib/hooks/useAsync";
import { getMasterTrainingInfoById } from "app/api/getData/getMasterTrainingInfoById";
import Loader from "components/loader";
import EditMasterTraining from "components/pages/editMasterTraining";

const EditMasterTrainingPage: React.FC = () => {
  const { masterTrainingId } = useParams<{ masterTrainingId: string }>();
  const { push } = useHistory();

  const { value: masterTraining, loading } = useAsync(() =>
    getMasterTrainingInfoById({ masterTrainingId })
  );

  if (loading) {
    return <Loader />;
  }

  if (!masterTraining) {
    push("/");
    return <></>;
  }

  return (
    <>
      <EditMasterTraining masterTraining={masterTraining} />
    </>
  );
};

export default EditMasterTrainingPage;
