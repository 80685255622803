import { Api } from "app/api/api";
import { Instructor } from "sport-app-types/src/client";
import { InstructorInDb } from "sport-app-types/src/server";
import { GetUrlFnNames, getUrlFns } from "app/api/getData/constants";
import { date } from "lib/dates";
import { SexVariants } from "sport-app-types/src/universal";
import { LatLngLiteral } from "leaflet";

export const moscowPosition: LatLngLiteral = {
  lat: 55.753781489660035,
  lng: 37.621994018554695,
};

export function prepareInstructorInfoFromDbToClient({
  latitude,
  longitude,
  resolvedCoordinates,
  city,
  emoji,
  covers,
  rating,
  country,
  facts,
  birthday,
  sex,
  firstName,
  lastName,
  timezone,
  middleName,
  alias,
  instagram,
  description,
  email,
  ...instructorInDb
}: InstructorInDb): Instructor {
  return {
    ...instructorInDb,
    alias: alias || "",
    description: description || "",
    instagram: instagram || "",
    firstName: firstName || "",
    lastName: lastName || "",
    middleName: middleName || "",
    timezone: timezone || "Europe/Moscow",
    covers: Array.isArray(covers) ? covers : [],
    facts: Array.isArray(facts) ? facts : [],
    country: country || "",
    city: city || "",
    email: email || "",
    rating: rating || 0,
    sex: sex || SexVariants.male,
    birthday: birthday || date().subtract(18, "year").toISOString(),
    geo: {
      latitude: latitude || moscowPosition.lat,
      longitude: longitude || moscowPosition.lng,
      resolvedCoordinates,
    },
  };
}

export function prepareInstructorFromClientToDb<
  NotRequiredParams extends keyof InstructorInDb | null = null
>({
  geo,
  ...instructorInClient
}: NotRequiredParams extends keyof InstructorInDb
  ? Partial<Omit<Instructor, NotRequiredParams>> & {
      [key in NotRequiredParams]?: InstructorInDb[key];
    }
  : Instructor): Partial<InstructorInDb> {
  return {
    ...instructorInClient,
    ...geo,
  };
}

export async function getInstructorById({
  id,
}: {
  id: string;
}): Promise<Instructor | null> {
  const instructorFromDb = await Api.get<InstructorInDb>(
    getUrlFns[GetUrlFnNames.getInstructor](id)
  ).then((d) => d.data);

  return prepareInstructorInfoFromDbToClient(instructorFromDb);
}
