import { updateMasterTraining } from "app/api/updateData/updateMasterTraining";
import { toast } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { MasterTraining } from "sport-app-types";

type UpdateMasterTrainingData = Pick<MasterTraining, "name">;

export function useEditMasterTraining(
  masterTraining: Pick<MasterTraining, "id" | "name" | "instructor">
) {
  const { push } = useHistory();

  const onSubmit = (newMasterTrainingData: UpdateMasterTrainingData) => {
    updateMasterTraining(masterTraining.id, newMasterTrainingData)
      .then(() => {
        toast.success("Успешно");
        push(`/masterTrainings/${masterTraining?.instructor?.id}`);
      })
      .catch(() => {
        toast.error("Что то пошло не так.");
      });
  };

  return {
    onSubmit,
  };
}
