import { useCallback, useEffect, useState } from "react";

export type OnPromiseFinishFns<Result, Error> = {
  onSuccess?: (res: Result | null) => void;
  onError?: (err: Error) => void;
};

const useAsync = <Result, Error = string>(
  getPromise: () => Promise<Result | null>,
  immediate = true,
  onPromiseFinishFns?: OnPromiseFinishFns<Result, Error>
) => {
  const [loading, setLoading] = useState<boolean>(immediate);
  const [value, setValue] = useState<Result | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const execute = useCallback(() => {
    setLoading(true);
    setValue(null);
    setError(null);
    return getPromise()
      .then((response) => {
        if (!response) {
          return;
        }
        setValue(response);
        if (onPromiseFinishFns?.onSuccess) {
          onPromiseFinishFns?.onSuccess(response);
        }
      })
      .catch((e: any) => {
        setError(e);
        if (onPromiseFinishFns?.onError) {
          onPromiseFinishFns?.onError(e);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getPromise]);
  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [immediate]);
  return { execute, loading, value, error };
};

export default useAsync;
