import React, { useCallback } from "react";
import { TrainingInDb } from "sport-app-types";
import { dateFromUtc } from "lib/dates";
import s from "./styles.module.scss";
import { Button } from "antd";

const TrainingCard: React.FC<{
  training: TrainingInDb;
  onPlusClick?: (training: TrainingInDb) => void;
  onMinusClick?: (training: TrainingInDb) => void;
}> = ({ training, onPlusClick, onMinusClick }) => {
  const onPlusClickHandler = useCallback(() => {
    if (onPlusClick) {
      onPlusClick(training);
    }
  }, [training]);
  const onMinusClickHandler = useCallback(() => {
    if (onMinusClick) {
      onMinusClick(training);
    }
  }, [training]);

  return (
    <div className={s.cardWrapper}>
      <img
        style={{
          width: 80,
          marginRight: 10,
        }}
        alt={""}
        src={training.masterTraining?.covers?.slice(0, 1)[0]}
      />

      <div>
        <div>{training.masterTraining?.name}</div>
        <div>{dateFromUtc(training.startDate).format("DD.MM.YYYY HH:mm")}</div>
        <div>{training.masterTraining?.duration} мин</div>

        {onPlusClick && <Button onClick={onPlusClickHandler}>+</Button>}
        {onMinusClick && <Button onClick={onMinusClickHandler}>-</Button>}
      </div>
    </div>
  );
};

export default TrainingCard;
