import React, { useEffect } from "react";
import MarathonDetailsTable from "pages/marathons/details/table";
import { Link, Route, useHistory, useParams } from "react-router-dom";
import { useMarathonById } from "app/xstate/marathons/hooks/useMarathonById";
import { Button, Card, Modal, Row, Typography } from "antd";
import Loader, { LoaderVariants } from "components/loader";
import { useSyncMarathonsData } from "app/xstate/marathons/hooks/useSyncMarathonsData";
import { operatorRouteFns } from "app/routes/constants";
import { useConfirmMarathon } from "app/xstate/marathons/hooks/useConfirmMarathon";
import { useMarathonsLoadingData } from "app/xstate/marathons/hooks/useMarathonsLoadingData";

const MarathonDetailsPage: React.FC = () => {
  const { marathonId } = useParams<{ marathonId: string }>();
  const syncMarathons = useSyncMarathonsData();
  const history = useHistory();
  const marathon = useMarathonById(marathonId);
  const confirmMarathon = useConfirmMarathon();
  const loading = useMarathonsLoadingData();
  useEffect(() => {
    if (!marathon) {
      syncMarathons();
    }
  }, []);

  if (!marathon?.trainings || loading) {
    return (
      <Row justify={"center"}>
        <Loader variant={LoaderVariants.small} />
      </Row>
    );
  }

  const onOk = () => {
    confirmMarathon(marathonId);
    history.replace(operatorRouteFns.marathonDetails(marathonId));
  };
  const onCancel = () => {
    history.replace(operatorRouteFns.marathonDetails(marathonId));
  };

  return (
    <div>
      <Row justify={"center"}>
        <Typography.Title>{marathon.name}</Typography.Title>
      </Row>

      <Row justify={"space-between"}>
        <Link to={operatorRouteFns.marathonsRequests()}>
          <Button>Назад</Button>
        </Link>
        {marathon.status !== "ACTIVE" && (
          <Link to={operatorRouteFns.confirmMarathon(marathonId)}>
            <Button type={"primary"}>Подтвердить</Button>
          </Link>
        )}
      </Row>

      <Route
        path={operatorRouteFns.confirmMarathon(marathonId)}
        render={({ match }) => {
          if (match) {
            return (
              <Modal
                visible
                title={marathon?.instructor[0].firstName}
                cancelText={"Отмена"}
                okText={"Подтвердить"}
                onOk={onOk}
                onCancel={onCancel}
              >
                <Row justify={"center"}>
                  <Typography.Title>ВНИМАНИЕ!</Typography.Title>
                </Row>

                <Row justify={"center"}>
                  <Typography.Text>
                    После подтверждения марафон станет доступен пользователя в
                    пользовательском приложении для покупки и записи.
                  </Typography.Text>
                </Row>
              </Modal>
            );
          }
          return <></>;
        }}
      />

      <Row>
        <Card
          style={{
            width: 240,
            marginBottom: 30,
            marginTop: 20,
          }}
          cover={<img src={marathon.covers[0]} alt={""} />}
        >
          <Card.Meta
            title={marathon.name}
            description={`${marathon.volume} занятий`}
          />
        </Card>
      </Row>

      <MarathonDetailsTable trainings={marathon?.trainings} />
    </div>
  );
};

export default MarathonDetailsPage;
