import { auth } from "index";
import { useRecoilState, useSetRecoilState } from "recoil";
import { $userInfoLoading, EmployeeRoles, $userRole } from "app/recoil/user";
import useEffectAsync from "app/hooks/useEffectAsync";
import jwtDecode from "jwt-decode";
import { onAuthStateChanged } from "firebase/auth";
import { setToken } from "app/api/api";

export function useUpdateUserInfoNew() {
  const [userInfoLoading, setUserInfoLoading] =
    useRecoilState($userInfoLoading);
  const setEmployeeRole = useSetRecoilState($userRole);

  useEffectAsync(
    () =>
      onAuthStateChanged(auth, async (authState) => {
        setUserInfoLoading(true);
        if (!authState?.uid) {
          setUserInfoLoading(false);
          setEmployeeRole(null);
          return;
        }
        const token = await authState?.getIdToken(true);
        if (!token) {
          return;
        }
        setToken(token);
        const parsedToken = jwtDecode<{ role: EmployeeRoles }>(token);
        setEmployeeRole(parsedToken.role);
        setUserInfoLoading(false);
      }),
    []
  );

  return { userInfoLoading };
}
