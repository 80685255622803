import { useMarathonsActor } from "app/xstate/marathons/hooks/useMarathonsActor";
import { useCallback } from "react";

export function useConfirmMarathon() {
  const [_, send] = useMarathonsActor();

  return useCallback((id: string) => {
    send({
      type: "CONFIRM_MARATHON",
      payload: {
        id,
      },
    });
  }, []);
}
