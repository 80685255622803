import React, { useState } from "react";
import { toast } from "react-hot-toast";
import s from "pages/auth/styles.module.scss";
import logo from "pages/auth/img/logo.svg";
import { firebase } from "index";
import eightImg from "pages/auth/img/eight.svg";
import { Button, Form, Input } from "antd";

export interface AuthFields {
  email: string;
  password: string;
}

const AuthPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const submitSignInFormHandler = (data: AuthFields) => {
    setLoading(true);

    firebase
      .auth()
      .signInWithEmailAndPassword(data.email, data.password)
      .then(() => {
        toast.success("Вы успешно авторизовались!");
      })
      .catch(() => {
        toast.error("Неверные данные, попробуйте снова.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const signInVariant = (
    <Form
      name={"auth"}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      autoComplete={"off"}
      onFinish={submitSignInFormHandler}
      onFinishFailed={(d) => {
        console.log(d);
      }}
    >
      <div className={s.title}>Войдите в систему!</div>
      <Form.Item
        name={"email"}
        label="Email"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={"Пароль"}
        name={"password"}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 3, offset: 10 }}>
        <Button disabled={loading} htmlType={"submit"}>
          Войти
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <div className={s.authPage}>
      <div className={s.imagePart}>
        <img src={eightImg} alt="" className={s.imagePartImg} />
        <span className={s.imagePartText}>Восьмёрка спорт</span>
      </div>
      <div className={s.formPart}>
        <div className={s.formWrapper}>
          <img src={logo} alt="vsmrk" className={s.logo} />
          {signInVariant}
        </div>
      </div>
    </div>
  );
};

export default AuthPage;
