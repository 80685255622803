import { useTrainingRecordsActor } from "app/xstate/trainingRecords/hooks/useTrainingRecordsActor";
import { useMemo } from "react";

export function useTrainingRecordsData() {
  const [{ context }] = useTrainingRecordsActor();

  return {
    ...context,
    filteredRecordsList: useMemo(
      () =>
        context.trainingRecords?.filter((record) =>
          `${record.instructor?.lastName || ""} ${
            record.instructor?.firstName || ""
          }`
            .toLowerCase()
            .includes(context.filterRecordsParams?.instructor?.toLowerCase())
        ),
      [context.filterRecordsParams, context.trainingRecords]
    ),
  };
}
