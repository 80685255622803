import React from "react";
import { GuestRoutes, OperatorRoutes } from "app/routes/routes";
import { useRecoilValue } from "recoil";
import { EmployeeRoles, $userRole } from "app/recoil/user";

export const RoutesListDesktop: React.FC = () => {
  const employeeRole = useRecoilValue($userRole);
  let userRoutes: any;

  switch (employeeRole) {
    case EmployeeRoles.operator:
      userRoutes = <OperatorRoutes />;
      break;
    default:
      userRoutes = <GuestRoutes />;
  }

  return <>{userRoutes}</>;
};
