import React, { useCallback } from "react";
import { CreateOrEditMarathonFormComponent } from "pages/createOrEditMarathon/types";
import { MarathonStatus } from "sport-app-types/src/universal";
import { copyText } from "pages/users/instructors/createInstructorModal";
import { toast } from "react-hot-toast";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { operatorRouteFns } from "app/routes/constants";

const CreateOrEditMarathonFinishScreen: CreateOrEditMarathonFormComponent = ({
  marathon,
}) => {
  const history = useHistory();
  const buyLink = `https://tinkoff-payment-widget.website.yandexcloud.net/?marathonId=${marathon.id}&referralId=123`;
  const publishMarathon = useCallback(async () => {
    if (!marathon.id) {
      return;
    }
    // await updateMarathonStatusInServer(marathon.id, MarathonStatus.active);

    history.push(operatorRouteFns.marathonsList());

    // send({
    //   type: "UPDATE_MARATHON",
    //   payload: {
    //     status: MarathonStatus.active,
    //   },
    // });

    toast.success("Марафон сохранен");
  }, [marathon.id]);

  const copyBuyLink = useCallback(() => {
    copyText(buyLink);
  }, []);

  return (
    <div>
      <div
        onClick={copyBuyLink}
        style={{
          marginTop: 50,
          maxWidth: 400,
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {buyLink}
      </div>

      {marathon.status !== MarathonStatus.active && (
        <Button
          onClick={publishMarathon}
          style={{
            marginTop: 30,
          }}
        >
          Сохранить
        </Button>
      )}
    </div>
  );
};

export default CreateOrEditMarathonFinishScreen;
