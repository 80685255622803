import React from "react";
import { Button, Form, Input } from "antd";
import { updateInstructorById } from "app/api/updateData/updateTrainerById";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";
import TextArea from "antd/lib/input/TextArea";
import { Instructor } from "sport-app-types/src/client";

type UpdateTrainerFormData = Pick<
  Instructor,
  "firstName" | "lastName" | "description" | "instagram" | "alias"
> & {
  fact1: string;
  fact2: string;
  fact3: string;
  fact4: string;
  fact5: string;
  fact6: string;
  fact7: string;
  fact8: string;
};

const EditInstructorForm: React.FC<{ trainer: Instructor }> = ({ trainer }) => {
  const { push } = useHistory();

  const onSubmit = ({
    firstName,
    lastName,
    instagram,
    alias,
    ...facts
  }: UpdateTrainerFormData) => {
    updateInstructorById(trainer.id, {
      firstName,
      lastName,
      instagram,
      alias,
      facts: [
        facts.fact1,
        facts.fact2,
        facts.fact3,
        facts.fact4,
        facts.fact5,
        facts.fact6,
        facts.fact7,
        facts.fact8,
      ],
    })
      .then(() => {
        push("/users/instructors");
        toast.success("Успешно");
      })
      .catch(() => {
        toast.error("Не удалось сохранить");
      });
  };

  return (
    <Form
      initialValues={trainer}
      onFinish={onSubmit}
      labelCol={{ span: 4 }}
      style={{
        marginTop: 40,
      }}
    >
      <Form.Item rules={[{ required: true }]} label={"Имя"} name={"firstName"}>
        <Input />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
          },
        ]}
        label={"Фамилия"}
        name={"lastName"}
      >
        <Input />
      </Form.Item>
      <Form.Item label={"Псевдоним"} name={"alias"}>
        <Input />
      </Form.Item>
      <Form.Item label={"Instagram"} name={"instagram"}>
        <Input />
      </Form.Item>

      <Form.Item label={"Описание"} name={"description"}>
        <TextArea rows={5} />
      </Form.Item>

      <Form.Item
        label={"Факт 1"}
        name={"fact1"}
        initialValue={trainer.facts[0]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={"Факт 2"}
        name={"fact2"}
        initialValue={trainer.facts[1]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={"Факт 3"}
        name={"fact3"}
        initialValue={trainer.facts[2]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={"Факт 4"}
        name={"fact4"}
        initialValue={trainer.facts[3]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={"Факт 5"}
        name={"fact5"}
        initialValue={trainer.facts[4]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={"Факт 6"}
        name={"fact6"}
        initialValue={trainer.facts[5]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={"Факт 7"}
        name={"fact7"}
        initialValue={trainer.facts[6]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={"Факт 8"}
        name={"fact8"}
        initialValue={trainer.facts[7]}
      >
        <Input />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 11 }}>
        <Button htmlType={"submit"}>Сохранить</Button>
      </Form.Item>
    </Form>
  );
};

export default EditInstructorForm;
