import React, { useEffect } from "react";
import MarathonRequestsTable from "pages/marathons/requests/table";
import { useSyncMarathonsData } from "app/xstate/marathons/hooks/useSyncMarathonsData";
import { Row, Typography } from "antd";
import { useNeedSubmitMarathons } from "app/xstate/marathons/hooks/useNeedSubmitMarathons";
import { useMarathonsLoadingData } from "app/xstate/marathons/hooks/useMarathonsLoadingData";
import Loader, { LoaderVariants } from "components/loader";

const MarathonRequestsPage: React.FC = () => {
  const syncMarathons = useSyncMarathonsData();
  const needSubmitMarathons = useNeedSubmitMarathons();
  const loading = useMarathonsLoadingData();

  useEffect(() => {
    syncMarathons();
  }, []);

  if (loading) {
    return (
      <Row justify={"center"}>
        <Loader variant={LoaderVariants.small} />
      </Row>
    );
  }

  return (
    <div>
      <Row justify={"center"}>
        <Typography.Title>Запросы на публикацию</Typography.Title>
      </Row>
      <MarathonRequestsTable marathons={needSubmitMarathons} />
    </div>
  );
};

export default MarathonRequestsPage;
