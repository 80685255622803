import React from "react";
import { CreateOrEditMarathonFormComponent } from "pages/createOrEditMarathon/types";
import { Button, Form, Input, Typography } from "antd";
import s from "./styles.module.scss";
import { useCreateOrEditMarathonActor } from "app/xstate/createOrEditMarathon/hooks/useCreateOrEditMarathonActor";
import { Marathon } from "sport-app-types";
import { useCreateOrEditMarathonData } from "app/xstate/createOrEditMarathon/hooks/useCreateOrEditMarathonData";

const CreateOrEditMarathonSecStepForm: CreateOrEditMarathonFormComponent =
  () => {
    const { marathon } = useCreateOrEditMarathonData();
    const [, send] = useCreateOrEditMarathonActor();
    const onSubmit = (marathonData: Partial<Marathon>) => {
      send({
        type: "UPDATE_MARATHON",
        payload: {
          ...marathonData,
        },
      });

      send({
        type: "SET_CURRENT_STEP",
        payload: "Third",
      });
    };

    return (
      <div className={s.secStepForm}>
        <div>img</div>
        <div>
          <Form onFinish={onSubmit}>
            <Typography.Title>Цель марафона</Typography.Title>
            <Typography.Text>
              Это очень вкусная тренировка, которая точно поможет тебе стать
              стройнее и красивее.
            </Typography.Text>
            <Form.Item
              name={"goal"}
              label={"Цель"}
              initialValue={marathon.goal}
              style={{
                marginTop: 20,
              }}
              required
              rules={[
                {
                  required: true,
                  message: "Обязательное поле",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Typography.Title>Описание марафона</Typography.Title>
            <Typography.Text>
              Это очень вкусная тренировка, которая точно поможет тебе стать
              стройнее и красивее.
            </Typography.Text>
            <Form.Item
              required
              initialValue={marathon.description}
              rules={[
                {
                  required: true,
                  message: "Обязательное поле",
                },
              ]}
              name={"description"}
              label={"Описание"}
              style={{
                marginTop: 20,
              }}
            >
              <Input.TextArea rows={8} />
            </Form.Item>

            <Button htmlType={"submit"}>Далее</Button>
          </Form>
        </div>
      </div>
    );
  };

export default CreateOrEditMarathonSecStepForm;
