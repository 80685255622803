import React from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import EditInstructorForm from "components/pages/editTrainer";
import Loader from "components/loader";
import Title from "antd/lib/typography/Title";
import { Button } from "antd";
import { useApi } from "app/api/hooks/useApi";

const EditInstructorPage: React.FC = () => {
  const { trainerId } = useParams<{ trainerId: string }>();
  const { push } = useHistory();

  const { getInstructorInfo } = useApi();

  const { loading, value: instructor } = getInstructorInfo({ id: trainerId });

  if (loading) {
    return <Loader />;
  }

  if (!instructor) {
    push("/");
    return <></>;
  }

  return (
    <>
      <Title
        level={3}
        style={{
          textAlign: "center",
        }}
      >
        Редактировать анкету тренера
      </Title>

      <EditInstructorForm trainer={instructor} />

      <Link to={`/masterTrainings/${trainerId}`}>
        <Button>Перейти к мастер тренировкам</Button>
      </Link>
    </>
  );
};

export default EditInstructorPage;
