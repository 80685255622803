import { useSelector } from "@xstate/react";
import { useGlobalState } from "app/xstate/globalState/globalStateProvider";
import { filterScheduledMarathons } from "app/api/getData/getMarathons";
import React from "react";

const select = (state: any) => state?.context?.marathons;

export function useScheduledMarathonsData() {
  const { marathons } = useGlobalState();

  const marathonsData = useSelector(marathons, select);

  return React.useMemo(
    () => filterScheduledMarathons(Object.values(marathonsData)),
    [marathonsData]
  );
}
