import { assign, createMachine } from "xstate";
import {
  getInstructorsList,
  GetInstructorsListProps,
} from "app/api/getData/getInstructorsList";
import { Instructor } from "sport-app-types/src/client";
import { normalizeArray } from "lib/normalizeArray";
import { CreateInstructorParams } from "app/types/operator/createTrainerRequest";
import { createInstructorInServer } from "app/api/createData/createInstructor/createInstructor";
import { toast } from "react-hot-toast";

export interface InstructorsMachineContext {
  instructorsHashTable: Record<string, Instructor>;
  instructorsLoading: boolean;
  searchParams: GetInstructorsListProps;
  createNewInstructorModalIsOpen: boolean;
  createNewInstructorParams: CreateInstructorParams | null;
  createNewInstructorLoading: boolean;
}

type InstructorsEvents =
  | {
      type: "SYNC_INSTRUCTORS_LIST";
    }
  | {
      type: "SET_SEARCH_PARAMS";
      searchParams: GetInstructorsListProps;
    }
  | {
      type: "OPEN_CREATE_NEW_INSTRUCTOR_MODAL";
    }
  | {
      type: "CLOSE_CREATE_NEW_INSTRUCTOR_MODAL";
    }
  | {
      type: "CREATE_NEW_INSTRUCTOR";
      payload: CreateInstructorParams;
    };

export const instructorsMachine = createMachine<
  InstructorsMachineContext,
  InstructorsEvents
>({
  id: "instructorsMachine",
  initial: "idle",
  context: {
    instructorsHashTable: {},
    instructorsLoading: false,
    searchParams: {
      field: "email",
      query: "",
    },
    createNewInstructorModalIsOpen: false,
    createNewInstructorParams: null,
    createNewInstructorLoading: false,
  },
  states: {
    idle: {
      on: {
        SYNC_INSTRUCTORS_LIST: {
          target: "searching",
          actions: assign({
            instructorsLoading: (_) => true,
          }),
        },
        SET_SEARCH_PARAMS: {
          actions: assign({
            searchParams: (_, event) => event.searchParams,
          }),
        },
        CREATE_NEW_INSTRUCTOR: {
          actions: assign({
            createNewInstructorParams: (_, { payload }) => payload,
            createNewInstructorLoading: (_) => true,
          }),
          target: "creatingNewInstructor",
        },
        OPEN_CREATE_NEW_INSTRUCTOR_MODAL: {
          actions: assign({
            createNewInstructorModalIsOpen: (_) => true,
          }),
        },
        CLOSE_CREATE_NEW_INSTRUCTOR_MODAL: {
          actions: assign({
            createNewInstructorModalIsOpen: (_) => false,
          }),
        },
      },
    },
    searching: {
      invoke: {
        id: "loadingInvoke",
        src: ({ searchParams }) => getInstructorsList(searchParams),
        onDone: {
          actions: assign({
            instructorsHashTable: (_, event) =>
              normalizeArray<Instructor>(event.data, "id"),
            instructorsLoading: (_) => false,
          }),
          target: "idle",
        },
        onError: {
          actions: assign({
            instructorsLoading: (_) => false,
          }),
          target: "idle",
        },
      },
    },
    creatingNewInstructor: {
      invoke: {
        src: ({ createNewInstructorParams }) => {
          if (!createNewInstructorParams) {
            console.warn(
              "No parameters to create instructor, ",
              createNewInstructorParams
            );
            throw "Нет параметров.";
          }
          return createInstructorInServer(createNewInstructorParams);
        },
        onDone: {
          target: "showNewInstructorInfo",
          actions: assign({
            createNewInstructorLoading: (_) => false,
            createNewInstructorParams: (_, { data }) => {
              console.log(data);
              return data;
            },
            instructorsHashTable: ({ instructorsHashTable }, { data }) => ({
              ...instructorsHashTable,
              [data.id]: data,
            }),
          }),
        },
        onError: {
          target: "idle",
          actions: [
            assign({
              createNewInstructorLoading: (_) => false,
            }),
            () => {
              toast.error("Не удалось создать тренера.");
            },
          ],
        },
      },
    },
    showNewInstructorInfo: {
      on: {
        CLOSE_CREATE_NEW_INSTRUCTOR_MODAL: {
          target: "idle",
          actions: assign({
            createNewInstructorParams: (_) => null,
            createNewInstructorModalIsOpen: (_) => false,
          }),
        },
        CREATE_NEW_INSTRUCTOR: {
          target: "idle",
          actions: assign({
            createNewInstructorParams: (_) => null,
            createNewInstructorModalIsOpen: (_) => false,
          }),
        },
      },
    },
  },
});
