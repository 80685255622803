import { Marathon } from "sport-app-types";

const LOCALE_MARATHON_KEY: string = "LOCALE_MARATHON_CREATE_OR_EDIT";

export function getLocaleMarathon(): Partial<Marathon> {
  const marathonString = localStorage.getItem(LOCALE_MARATHON_KEY);

  if (marathonString) {
    return JSON.parse(marathonString);
  }

  return {};
}

export function saveMarathonLocale(
  marathon: Partial<Marathon> | null
): Partial<Marathon> {
  const marathonLocale = getLocaleMarathon();

  const newMarathon: Partial<Marathon> = marathon
    ? {
        ...marathonLocale,
        ...marathon,
      }
    : {};

  localStorage.setItem(LOCALE_MARATHON_KEY, JSON.stringify(newMarathon));

  return newMarathon;
}
