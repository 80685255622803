import { useCallback } from "react";
import { useTrainingRecordsActor } from "app/xstate/trainingRecords/hooks/useTrainingRecordsActor";
import { TrainingRecord } from "pages/trainingsRecords";
import { GetTrainingRecordsParams } from "app/api/getData/getTrainingRecords";
import { FilterTrainingRecordsParams } from "app/xstate/trainingRecords/machine";

export function useTrainingRecordsActions() {
  const [_, send] = useTrainingRecordsActor();

  const syncTrainingRecords = useCallback(() => {
    send({
      type: "SYNC_DATA",
    });
  }, []);

  const selectTrainingRecord = useCallback((record: TrainingRecord) => {
    send({
      type: "SELECT_RECORD",
      payload: record,
    });
  }, []);

  const clearSelectedRecord = useCallback(() => {
    send({
      type: "CLEAR_SELECTED_RECORD",
    });
  }, []);

  const updateSearchRecordParams = useCallback(
    (payload: Partial<GetTrainingRecordsParams>) => {
      send({
        type: "UPDATE_SEARCH_PARAMS",
        payload,
      });
    },
    []
  );

  const updateFilterRecordParams = useCallback(
    (payload: Partial<FilterTrainingRecordsParams>) => {
      send({
        type: "UPDATE_FILTER_PARAMS",
        payload,
      });
    },
    []
  );

  return {
    syncTrainingRecords,
    selectTrainingRecord,
    clearSelectedRecord,
    updateFilterRecordParams,
    updateSearchRecordParams,
  };
}
