import { Api } from "app/api/api";
import { getUrlFns } from "app/api/getData/constants";
import { Marathon, MarathonStatus } from "sport-app-types";

export async function confirmMarathonInServer(
  marathonId: string | null
): Promise<Marathon | null> {
  if (!marathonId) {
    return null;
  }
  return Api.post<
    Marathon,
    {
      marathonId: string;
      status: MarathonStatus.active;
    }
  >(getUrlFns.editMarathon(), {
    marathonId,
    status: MarathonStatus.active,
  }).then((d) => d.data);
}
