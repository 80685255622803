import React from "react";
import { useParams } from "react-router-dom";
import { Typography } from "antd";
import ButtonStepper from "components/buttonSteps";
import CreateOrEditMarathonFirstStepForm from "pages/createOrEditMarathon/firstStepForm";
import { CreateOrEditMarathonFormComponent } from "pages/createOrEditMarathon/types";
import CreateOrEditMarathonSecStepForm from "pages/createOrEditMarathon/secStepForm";
import { useCreateOrEditMarathon } from "pages/createOrEditMarathon/useCreateOrEditMarathon";
import Loader from "components/loader";
import CreateOrEditMarathonThirdStepForm from "pages/createOrEditMarathon/thirdStepForm";
import CreateOrEditMarathonFourthStepForm from "pages/createOrEditMarathon/fourthStepForm";
import CreateOrEditMarathonFifthStepForm from "pages/createOrEditMarathon/fifthStepForm";
import CreateOrEditMarathonFinishScreen from "pages/createOrEditMarathon/finishScreen";

export type CreateMarathonStepNames =
  | "First"
  | "Second"
  | "Third"
  | "Fourth"
  | "Fifth"
  | "Finish";

export const createMarathonStepNumbers: {
  [key in CreateMarathonStepNames]: number;
} = {
  First: 0,
  Second: 1,
  Third: 2,
  Fourth: 3,
  Fifth: 4,
  Finish: 5,
};

export const createMarathonStepNames: {
  [key in typeof createMarathonStepNumbers[keyof typeof createMarathonStepNumbers]]: CreateMarathonStepNames;
} = {
  0: "First",
  1: "Second",
  2: "Third",
  3: "Fourth",
  4: "Fifth",
  5: "Finish",
};

const CreateMarathonSteps: {
  [key in CreateMarathonStepNames]: CreateOrEditMarathonFormComponent;
} = {
  First: CreateOrEditMarathonFirstStepForm,
  Second: CreateOrEditMarathonSecStepForm,
  Third: CreateOrEditMarathonThirdStepForm,
  Fourth: CreateOrEditMarathonFourthStepForm,
  Fifth: CreateOrEditMarathonFifthStepForm,
  Finish: CreateOrEditMarathonFinishScreen,
};

const CreateOrEditMarathonPage: React.FC = () => {
  const params = useParams<{ marathonId?: string }>();

  const {
    marathon,
    marathonDataLoading,
    currentStepNumber,
    finishedStepsCount,
    setCurrentStep,
    step,
  } = useCreateOrEditMarathon(params.marathonId);

  if (marathonDataLoading) {
    return <Loader />;
  }

  const CurrentStep = CreateMarathonSteps[step];

  // @ts-ignore
  if (
    params.marathonId !== "new" &&
    params.marathonId &&
    (!marathon?.id || params.marathonId !== marathon.id)
  ) {
    return <Loader />;
  }

  return (
    <div>
      <div>
        <Typography.Title>Марфоны</Typography.Title>
        <Typography.Text>Создание и редактирование</Typography.Text>
      </div>
      <ButtonStepper
        steps={["Название", "Описание", "Фото", "Инструкторы", "Расписание"]}
        finishedStepsNumber={finishedStepsCount}
        selectedStep={currentStepNumber}
        onClickOnStep={(index) => {
          setCurrentStep(createMarathonStepNames[index]);
          console.log(index);
        }}
      />

      <CurrentStep marathon={marathon} />
    </div>
  );
};

export default CreateOrEditMarathonPage;
