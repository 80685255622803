import { Api } from "app/api/api";
import { GetUrlFnNames, getUrlFns } from "app/api/getData/constants";
import { PromoCodeInDb } from "sport-app-types/index";

export function getB2BPromoCodesList(): Promise<PromoCodeInDb[]> {
  try {
    return Api.get<PromoCodeInDb[]>(
      getUrlFns[GetUrlFnNames.b2bGetPromoCodes]()
    ).then((d) => d.data);
  } catch (e) {
    console.log("Cant fetch promo codes list");

    throw "Не удалось получить список промокодов.";
  }
}
