import React, { useCallback, useContext } from "react";
import { useInterpret, useSelector } from "@xstate/react";
import {
  b2bOrganizationsMachine,
  B2BOrganizationsMachineContext,
} from "app/xstate/b2bCompanies/machine";
import { InterpreterFrom } from "xstate";
import {
  instructorsMachine,
  InstructorsMachineContext,
} from "app/xstate/instructors/machine";
import {
  b2bPromoCodesMachine,
  B2BPromoCodesMachineContext,
} from "app/xstate/b2bPromoCodes/machine";
import {
  trainingRecordsMachine,
  TrainingRecordsMachineContext,
} from "app/xstate/trainingRecords/machine";
import {
  trainingStatisticsMachine,
  TrainingStatisticsMachineContext,
} from "app/xstate/trainingStatistics/machine";
import {
  marathonsMachine,
  MarathonsMachineContext,
} from "app/xstate/marathons/machine";
import {
  createOrEditMarathonMachine,
  CreateOrEditMarathonMachineContext,
} from "app/xstate/createOrEditMarathon/machine";

interface GlobalStateContextType {
  b2bCompanies: InterpreterFrom<typeof b2bOrganizationsMachine>;
  instructors: InterpreterFrom<typeof instructorsMachine>;
  b2bPromoCodes: InterpreterFrom<typeof b2bPromoCodesMachine>;
  trainingRecords: InterpreterFrom<typeof trainingRecordsMachine>;
  trainingStatistics: InterpreterFrom<typeof trainingStatisticsMachine>;
  marathons: InterpreterFrom<typeof marathonsMachine>;
  createOrEditMarathon: InterpreterFrom<typeof createOrEditMarathonMachine>;
}

interface GlobalState {
  b2bCompanies: B2BOrganizationsMachineContext;
  instructors: InstructorsMachineContext;
  b2bPromoCodes: B2BPromoCodesMachineContext;
  trainingRecords: TrainingRecordsMachineContext;
  trainingStatistics: TrainingStatisticsMachineContext;
  marathons: MarathonsMachineContext;
  createOrEditMarathon: CreateOrEditMarathonMachineContext;
}

const GlobalStateContext = React.createContext<GlobalStateContextType>(
  {} as GlobalStateContextType
);

export function useGlobalState() {
  return useContext(GlobalStateContext);
}

export type GlobalStateSelectorFn<
  T extends keyof GlobalState,
  K = GlobalState[T],
  S extends GlobalState[T] = GlobalState[T]
> = (state: S) => K;

export function useGlobalStateSelector<
  T extends keyof GlobalState,
  S extends GlobalState[T],
  K
>(contextName: T, selectorFunction: GlobalStateSelectorFn<T, K, S>): K {
  const globalServices = useGlobalState();
  const context = globalServices[contextName];

  const selector = useCallback(
    (state) => selectorFunction(state.context),
    [selectorFunction]
  );

  return useSelector(context, selector);
}

const GlobalStateProvider: React.FC = ({ children }) => {
  const b2bCompanies: any = useInterpret(b2bOrganizationsMachine);
  const instructors: any = useInterpret(instructorsMachine);
  const b2bPromoCodes: any = useInterpret(b2bPromoCodesMachine);
  const trainingRecords: any = useInterpret(trainingRecordsMachine);
  const trainingStatistics: any = useInterpret(trainingStatisticsMachine);
  const marathons: any = useInterpret(marathonsMachine);
  const createOrEditMarathon: any = useInterpret(createOrEditMarathonMachine);

  return (
    <GlobalStateContext.Provider
      value={{
        b2bCompanies,
        instructors,
        b2bPromoCodes,
        trainingRecords,
        trainingStatistics,
        marathons,
        createOrEditMarathon,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

export default GlobalStateProvider;
