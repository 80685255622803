import { TrainingRecord } from "pages/trainingsRecords";
import { Api } from "app/api/api";
import { GetUrlFnNames, getUrlFns } from "app/api/getData/constants";

export interface GetTrainingRecordsParams {
  gte: string;
  lte: string;
}

export async function getTrainingRecords(
  searchParams: GetTrainingRecordsParams
): Promise<TrainingRecord[]> {
  try {
    return await Api.post<TrainingRecord[], GetTrainingRecordsParams>(
      getUrlFns[GetUrlFnNames.getTrainingRecords](),
      searchParams
    ).then((d) => d.data);
  } catch (e) {
    return [];
  }
}
