import { useInstructorsActor } from "app/xstate/instructors/hooks/useInstructorsActor";
import { useMemo } from "react";

export function useInstructorsData() {
  const [{ context, value }] = useInstructorsActor();

  return {
    value,
    ...context,
    instructorsListArray: useMemo(
      () => Object.values(context.instructorsHashTable),
      [context.instructorsHashTable]
    ),
  };
}
