import { useB2BPromoCodesActor } from "app/xstate/b2bPromoCodes/hooks/useB2BPromoCodesActor";
import { useCallback } from "react";
import { PromoCodeInDb } from "sport-app-types/index";

export function usePromoCodesActions() {
  const [_, send] = useB2BPromoCodesActor();

  const syncPromoCodesData = useCallback(() => {
    send({
      type: "SYNC_DATA",
    });
  }, []);

  const openCreatePromoCodeModal = useCallback(() => {
    send({
      type: "OPEN_CREATE_PROMO_CODE_MODAL",
    });
  }, []);

  const closeCreatePromoCodeModal = useCallback(() => {
    send({
      type: "CLOSE_CREATE_PROMO_CODE_MODAL",
    });
  }, []);

  const createPromoCode = useCallback((params: PromoCodeInDb) => {
    send({
      type: "CREATE_PROMOCODE",
      payload: params,
    });
  }, []);

  return {
    syncPromoCodesData,
    openCreatePromoCodeModal,
    closeCreatePromoCodeModal,
    createPromoCode,
  };
}
