import React from "react";
import s from "pages/users/instructors/listOfInstructors/styles.module.scss";
import Loader from "components/loader";
import { Button, Row, Table } from "antd";
import { useListOfInstructors } from "pages/users/instructors/listOfInstructors/useListOfInstructors";
import CreateInstructorModal from "pages/users/instructors/createInstructorModal";
import { useInstructorsActions } from "app/xstate/instructors/hooks/useInstructorsActions";

const ListOfInstructors: React.FC = () => {
  const { columns, instructorsListData, instructorsListLoading } =
    useListOfInstructors();

  const { openCreateInstructorModal } = useInstructorsActions();

  if (!instructorsListData || instructorsListLoading) {
    return <Loader />;
  }

  return (
    <div className={s.listOfCustomersPageContainer}>
      <div className={s.listOfCustomersPageTitle}>Тренеры</div>

      <Row justify={"end"}>
        <Button type={"primary"} onClick={openCreateInstructorModal}>
          Создать
        </Button>
      </Row>

      <Table
        columns={columns}
        dataSource={instructorsListData}
        bordered
        style={{
          marginTop: 10,
        }}
        pagination={{
          defaultPageSize: 100,
        }}
      />

      <CreateInstructorModal />
    </div>
  );
};

export default ListOfInstructors;
