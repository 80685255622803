import { auth } from "index";
import { toast } from "react-hot-toast";

export function logout() {
  auth.signOut().then(() => {
    toast.success("Вы вышли из аккаунта");
  });
}

export function getCurrentToken(needForceRefresh?: boolean) {
  return auth.currentUser?.getIdToken(needForceRefresh);
}
