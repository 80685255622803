import useAsync, { OnPromiseFinishFns } from "lib/hooks/useAsync";
import { useCallback } from "react";
import { getMasterTrainingInfoById } from "app/api/getData/getMasterTrainingInfoById";
import { getMasterTrainingsByInstructorId } from "app/api/getData/getMasterTrainingsByInstructorId";
import { MasterTraining, Instructor } from "sport-app-types/src/client";
import { getInstructorById } from "app/api/getData/getTrainerById";

type UseApiResult<Result, Params> = (
  params?: Params,
  onPromiseFinishFns?: OnPromiseFinishFns<Result, string>
) => {
  execute: () => void;
  loading: boolean;
  value: Result | null;
  error: any;
};

export const useApiPrepare = <
  Result = never,
  Params extends Record<string, any> = {}
>(
  getPromiseFn: (params: Params) => Promise<Result | null>
) => {
  const useApiResult: UseApiResult<Result, Params> = useCallback(
    (params = {} as Params, onPromiseFinishFns) =>
      useAsync(() => getPromiseFn(params), true, onPromiseFinishFns),
    []
  );

  return useApiResult;
};

export function useApi() {
  return {
    getMasterTrainingsList: useApiPrepare<MasterTraining[], { id: string }>(
      getMasterTrainingsByInstructorId
    ),
    getMasterTrainingInfo: useApiPrepare<
      MasterTraining,
      { masterTrainingId: string }
    >(getMasterTrainingInfoById),
    getInstructorInfo: useApiPrepare<Instructor, { id: string }>(
      getInstructorById
    ),
    // getTrainingRecords: useApiPrepare()
  };
}
