import React from "react";
import { Image, Modal } from "antd";

const ShowImgModal: React.FC<{ src: string | null; closeModalFn: () => void }> =
  ({ src, closeModalFn }) => (
    <Modal
      visible={!!src}
      centered
      cancelText={"Закрыть"}
      onCancel={closeModalFn}
      onOk={closeModalFn}
      title={"Просмотр фотографии"}
      maskClosable={false}
    >
      <Image src={src || ""} />
    </Modal>
  );

export default ShowImgModal;
