import React from "react";
import ReactDOM from "react-dom";

const modalWrapperNode = document.getElementById("modal-root");

const Portal: React.FC = ({ children }) => {
  if (!modalWrapperNode) {
    return null;
  }
  return ReactDOM.createPortal(
    <div style={{ zIndex: 1000 }}>{children}</div>,
    modalWrapperNode
  );
};

export default Portal;
