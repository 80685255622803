import { ReactComponent as form } from "./assets/Anketa.svg";
import { ReactComponent as clock } from "./assets/Clock.svg";
import { ReactComponent as company } from "./assets/Company.svg";
import { ReactComponent as help } from "./assets/Help.svg";
import { ReactComponent as home } from "./assets/Home.svg";
import { ReactComponent as zipper } from "./assets/Marketing.svg";
import { ReactComponent as video } from "./assets/Video.svg";
import { ReactComponent as calendar } from "./assets/Calendar.svg";
import { ReactComponent as triangle } from "./assets/Triangle.svg";
import { ReactComponent as quit } from "./assets/Quit.svg";
import { ReactComponent as withdrawMoney } from "./assets/WithdrawMoney.svg";
import { ReactComponent as star } from "./assets/Star.svg";
import { ReactComponent as person } from "./assets/Person.svg";
import { ReactComponent as bookmark } from "./assets/Bookmark.svg";
import { ReactComponent as warning } from "./assets/Warning.svg";
import { ReactComponent as alarm } from "./assets/Alarm.svg";
import { ReactComponent as filter } from "./assets/Filter.svg";
import { ReactComponent as loupe } from "./assets/Loupe.svg";
import { ReactComponent as arrowLineRight } from "./assets/ArrowLineDown.svg";
import { ReactComponent as arrowRight } from "./assets/Arrow-right.svg";
import { ReactComponent as arrowLeft } from "./assets/Arrow-left.svg";
import { ReactComponent as arrowBottom } from "./assets/Arrow-bottom.svg";
import { ReactComponent as upload } from "./assets/Upload.svg";
import { ReactComponent as ticket } from "./assets/Ticket.svg";
import { ReactComponent as cross } from "./assets/Cross.svg";
import { ReactComponent as reload } from "./assets/Reload.svg";
import { ReactComponent as trash } from "./assets/Trash.svg";
import { ReactComponent as threeDots } from "./assets/ThreeDots.svg";
import { ReactComponent as pencil } from "./assets/Pencil.svg";
import { ReactComponent as i } from "./assets/I.svg";
import { ReactComponent as pieChart } from "./assets/PieChart.svg";
import { ReactComponent as finances } from "./assets/Finances.svg";
import { ReactComponent as biceps } from "./assets/Biceps.svg";
import { ReactComponent as x } from "./assets/X.svg";
import { ReactComponent as check } from "./assets/Check.svg";
import { ReactComponent as calendarLine } from "./assets/Calendar-line.svg";
import { ReactComponent as plusOutlined } from "./assets/Plus-outlined.svg";
import { ReactComponent as message } from "./assets/Message.svg";
import { ReactComponent as ticketHd } from "./assets/Ticket-hd.svg";
import { ReactComponent as starHd } from "./assets/Star-hd.svg";
import { ReactComponent as ok } from "./assets/Ok.svg";
import { ReactComponent as diagram } from "./assets/Diagram.svg";
import { ReactComponent as dumbbell } from "./assets/Dumbbell.svg";
import { ReactComponent as money } from "./assets/Money.svg";
import { ReactComponent as rating } from "./assets/Rating.svg";
import { ReactComponent as personInsideCircle } from "./assets/PersonInsideCircle.svg";
import { ReactComponent as hamburger } from "./assets/Hamburger.svg";
import { ReactComponent as earth } from "./assets/Earth.svg";
import { ReactComponent as photo } from "./assets/Photo.svg";
import { ReactComponent as document } from "./assets/Document.svg";
import { ReactComponent as security } from "./assets/Security.svg";
import { ReactComponent as card } from "./assets/Card.svg";
import { ReactComponent as arrowWithLineLeft } from "./assets/Arrow-with-line-left.svg";
import { ReactComponent as documentCreate } from "./assets/DocumentCreate.svg";
import { ReactComponent as question } from "./assets/Question.svg";
import { ReactComponent as bicepsHd } from "./assets/BicepsHd.svg";
import { ReactComponent as companyLogo } from "./assets/CompanyLogo.svg";
import { ReactComponent as camera } from "./assets/Camera.svg";

export const Icons = {
  form,
  clock,
  company,
  help,
  home,
  zipper,
  video,
  calendar,
  triangle,
  quit,
  "arrow-right": arrowRight,
  "arrow-bottom": arrowBottom,
  "arrow-left": arrowLeft,
  "withdraw-money": withdrawMoney,
  star,
  person,
  bookmark,
  warning,
  "arrow-with-line-down": arrowLineRight,
  alarm,
  filter,
  loupe,
  upload,
  ticket,
  cross,
  reload,
  trash,
  threeDots,
  pencil,
  i,
  pieChart,
  finances,
  biceps,
  check,
  x,
  calendarLine,
  plusOutlined,
  message,
  ticketHd,
  starHd,
  ok,
  diagram,
  dumbbell,
  money,
  rating,
  personInsideCircle,
  hamburger,
  earth,
  photo,
  document,
  security,
  card,
  arrowWithLineLeft,
  documentCreate,
  question,
  bicepsHd,
  companyLogo,
  camera,
};
