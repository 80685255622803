import React from "react";
import { Button } from "antd";
import { Instructor, InstructorSpecialization } from "sport-app-types";

type InstructorSpecializationFilters = {
  [key in InstructorSpecialization]: Filter;
};

export type InstructorsSpecializationFilterValue = {
  [key in keyof InstructorSpecializationFilters]?: boolean;
};

export type OnInstructorsSpecializationFilterChange = (
  filter: keyof InstructorSpecializationFilters
) => void;

interface InstructorsSpecializationFilterProps {
  value: InstructorsSpecializationFilterValue;
  onChange: OnInstructorsSpecializationFilterChange;
}

interface Filter {
  label: string;
  filterFn: (instructor: Instructor) => boolean;
}

export const instructorSpecializationFilters: InstructorSpecializationFilters =
  {
    [InstructorSpecialization.fitness]: {
      label: "Фитнес - тренер",
      filterFn: (instructor) =>
        Boolean(instructor.specialization === InstructorSpecialization.fitness),
    },
    [InstructorSpecialization.nutritionist]: {
      label: "Нутрициолог",
      filterFn: (instructor) =>
        Boolean(
          instructor.specialization === InstructorSpecialization.nutritionist
        ),
    },
    [InstructorSpecialization.psychologist]: {
      label: "Психолог",
      filterFn: (instructor) =>
        Boolean(
          instructor.specialization === InstructorSpecialization.psychologist
        ),
    },
  };

const InstructorsSpecializationFilter: React.FC<InstructorsSpecializationFilterProps> =
  ({ value, onChange }) => (
    <Button.Group>
      {(
        Object.keys(instructorSpecializationFilters) as Array<
          keyof InstructorSpecializationFilters
        >
      ).map((filterKey) => (
        <Button
          type={value[filterKey] ? "primary" : "default"}
          onClick={() => onChange(filterKey)}
        >
          {instructorSpecializationFilters[filterKey].label}
        </Button>
      ))}
    </Button.Group>
  );

export default InstructorsSpecializationFilter;
