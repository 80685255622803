import { B2BOrganizationInDb } from "sport-app-types/index";
import { useB2BCompaniesActor } from "app/xstate/b2bCompanies/hooks/useB2BCompaniesActor";
import { PartialBy } from "lib/partialBy";
import { CreateLicenseKeysProps } from "app/api/createData/createLicenseKeys";

interface OpenCreateLicenceKeysModalProps {
  orgId: string;
}

export function useOrganizationsActions() {
  const [_, send] = useB2BCompaniesActor();

  const openCreateOrEditOrganizationModal = (
    organization?: PartialBy<B2BOrganizationInDb, "id">
  ) => {
    send({
      type: "OPEN_CREATE_OR_EDIT_ORGANIZATION_MODAL",
      payload: organization,
    });
  };

  const closeCreateOrEditOrganizationModal = () => {
    send({
      type: "CLOSE_CREATE_OR_EDIT_ORGANIZATION_MODAL",
    });
  };

  const createOrganization = (
    organization: PartialBy<B2BOrganizationInDb, "id">
  ) => {
    send({
      type: "CREATE_ORGANIZATION",
      payload: organization,
    });
  };

  const updateOrganization = (organization: B2BOrganizationInDb) => {
    send({
      type: "UPDATE_ORGANIZATION",
      payload: organization,
    });
  };

  const clearSelectedOrganization = () => {
    send({
      type: "CLEAR_SELECTED_ORGANIZATION",
    });
  };

  const syncOrganizationsList = () => {
    send({
      type: "SYNC_ORGANIZATIONS_LIST",
    });
  };

  const syncLicenceKeys = () => {
    send({
      type: "SYNC_LICENSE_KEYS_LIST",
    });
  };

  const openCreateLicenceKeysModal = (
    params?: OpenCreateLicenceKeysModalProps
  ) => {
    send({
      type: "OPEN_CREATE_LICENCE_KEY_MODAL",
      payload: params,
    });
  };

  const closeCreateLicenceKeysModal = () => {
    send({
      type: "CLOSE_CREATE_LICENCE_KEY_MODAL",
    });
  };

  const createLicenseKeys = (params: CreateLicenseKeysProps) => {
    send({
      type: "CREATE_LICENCE_KEY",
      payload: params,
    });
  };

  return {
    updateOrganization,
    createOrganization,
    syncOrganizationsList,
    clearSelectedOrganization,
    openCreateLicenceKeysModal,
    closeCreateLicenceKeysModal,
    syncLicenceKeys,
    createLicenseKeys,
    openCreateOrEditOrganizationModal,
    closeCreateOrEditOrganizationModal,
  };
}
