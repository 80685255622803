import React, { Suspense } from "react";
import "antd/dist/antd.min.css";
import { Toaster } from "react-hot-toast";
import { RoutesListDesktop } from "components/routesList/routesListDesktop";
import { useUpdateUserInfoNew } from "app/hooks/useUpdateUserInfoNew";
import Loader from "components/loader";

function App() {
  const { userInfoLoading } = useUpdateUserInfoNew();
  if (userInfoLoading) {
    return <Loader />;
  }

  return (
    <div className="App">
      <Suspense fallback={<Loader />}>
        <RoutesListDesktop />
      </Suspense>
      <Toaster
        toastOptions={{
          success: {
            duration: 3000,
          },
        }}
      />
    </div>
  );
}

export default App;
