import React, { useEffect } from "react";
import ViewingTrainingRecord from "components/viewingTrainingRecordModal";
import ru_RU from "antd/es/date-picker/locale/ru_RU";

import TrainingsRecordsList from "components/pages/trainingsRecordsList";
import { useTrainingRecordsActions } from "app/xstate/trainingRecords/hooks/useTrainingRecordsActions";
import { Button, Col, DatePicker, Row, Typography } from "antd";
import moment from "moment";
import { useTrainingRecordsData } from "app/xstate/trainingRecords/hooks/useTrainingRecordsData";
import { timeFormatDDMMYYYY } from "app/api/getData/getB2BLicenceKeysList";

export interface TrainingRecord {
  recordURL?: string;
  recordDate?: string;
  instructor?: {
    firstName?: string;
    lastName?: string;
  };
  training: {
    masterTraining?: {
      name?: string;
    };
  };
}

const TrainingRecordsPage: React.FC = () => {
  const { syncTrainingRecords, updateSearchRecordParams } =
    useTrainingRecordsActions();
  const { searchRecordsParams } = useTrainingRecordsData();

  useEffect(() => {
    syncTrainingRecords();
  }, []);

  return (
    <>
      <Row justify={"center"}>
        <Typography.Title>Записи тренировок</Typography.Title>
      </Row>

      <Row
        align={"middle"}
        style={{
          marginTop: 20,
        }}
      >
        <Col>
          <DatePicker.RangePicker
            locale={ru_RU}
            value={[
              moment(searchRecordsParams.gte),
              moment(searchRecordsParams.lte),
            ]}
            format={timeFormatDDMMYYYY}
            onChange={(event) => {
              const gte =
                event && event[0] && event[0].startOf("d").toISOString();
              const lte =
                event && event[1] && event[1].endOf("d").toISOString();

              if (gte) {
                updateSearchRecordParams({
                  gte,
                });
              }
              if (lte) {
                updateSearchRecordParams({
                  lte,
                });
              }
            }}
          />
        </Col>
        <Button
          onClick={() => syncTrainingRecords()}
          type={"primary"}
          style={{
            marginLeft: 32,
          }}
        >
          Обновить
        </Button>
      </Row>

      <TrainingsRecordsList />
      <ViewingTrainingRecord />
    </>
  );
};

export default TrainingRecordsPage;
