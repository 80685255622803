import { currentEnvironment, Environment } from "app/helpers/isProd";

export enum GetUrlFnNames {
  getInstructors = "getInstructors",
  getCustomers = "getCustomers",
  getInstructor = "getInstructor",
  updateInstructorById = "updateInstructorById",
  getInstructorMasterTrainingsById = "getInstructorMasterTrainingsById",
  getMasterTrainingInfoById = "getMasterTrainingInfoById",
  updateMasterTrainingInfoById = "updateMasterTrainingInfoById",
  createInstructor = "createInstructor",
  b2bListOfOrganizations = "b2bListOfOrganization",
  b2bCreateOrganization = "b2bCreateOrganization",
  b2bUpdateOrganization = "b2bUpdateOrganization",
  b2bCreateLicenseKeys = "b2bCreateLicenseKeys",
  b2bGetPromoCodes = "b2bGetPromoCodes",
  b2bCreatePromoCode = "b2bCreatePromoCode",
  getTrainingRecords = "getTrainingRecords",
  getTrainingStatistics = "getTrainingStatistics",
  marathons = "marathons",
  editMarathon = "editMarathon",
  editMarathonStatus = "editMarathonStatus",
  getMarathonById = "getMarathonById",
  uploadFileToStorage = "uploadFileToStorage",
  createMarathon = "createMarathon",
}

const baseUrl = getBaseUrl(currentEnvironment);

export const getUrlFns: {
  [key in GetUrlFnNames]: (...params: any[]) => string;
} = {
  [GetUrlFnNames.getInstructors]: () => `${baseUrl}/operator/instructor`,
  [GetUrlFnNames.getCustomers]: () => `${baseUrl}/operator/users`,
  [GetUrlFnNames.getInstructor]: (id: string) =>
    `${baseUrl}/operator/instructor/${id}`,
  [GetUrlFnNames.updateInstructorById]: (id: string) =>
    `${baseUrl}/operator/instructor/${id}`,
  [GetUrlFnNames.createInstructor]: () =>
    `${baseUrl}/operator/createInstructor`,
  [GetUrlFnNames.getInstructorMasterTrainingsById]: () =>
    `${baseUrl}/operator/masterTraining`,
  [GetUrlFnNames.getMasterTrainingInfoById]: (id: string) =>
    `${baseUrl}/operator/masterTraining/${id}`,
  [GetUrlFnNames.updateMasterTrainingInfoById]: (id: string) =>
    `${baseUrl}/operator/masterTraining/${id}`,
  [GetUrlFnNames.b2bListOfOrganizations]: () =>
    `${baseUrl}/operator/licenseKey/organization`,
  [GetUrlFnNames.b2bCreateOrganization]: () =>
    `${baseUrl}/operator/licenseKey/organization`,
  [GetUrlFnNames.b2bUpdateOrganization]: (id: string) =>
    `${baseUrl}/operator/licenseKey/organization/${id}`,
  [GetUrlFnNames.b2bCreateLicenseKeys]: () =>
    `${baseUrl}/operator/licenseKey/generate`,
  [GetUrlFnNames.b2bGetPromoCodes]: () => `${baseUrl}/operator/promocode`,
  [GetUrlFnNames.b2bCreatePromoCode]: () => `${baseUrl}/operator/promocode`,
  [GetUrlFnNames.getTrainingRecords]: () =>
    `https://api.vsmrk.com/v1/operator/getTrainingRecords`,
  [GetUrlFnNames.getTrainingStatistics]: () =>
    `${baseUrl}/operator/getTrainingStatistics`,
  marathons: () => `${baseUrl}/operator/marathon`,
  editMarathon: () => `${baseUrl}/operator/marathon`,
  editMarathonStatus: () => `${baseUrl}/operator/marathon/status`,
  [GetUrlFnNames.getMarathonById]: (marathonId: string) =>
    `${baseUrl}/operator/instructorDashboard/marathon/${marathonId}`,
  [GetUrlFnNames.uploadFileToStorage]: () =>
    `${baseUrl}/instructorDashboard/uploadimage`,
  createMarathon: () => `${baseUrl}/operator/marathon/create`,
};

export function getBaseUrl(stage: Environment): string {
  switch (stage) {
    case Environment.production:
      return "https://api.vsmrk.com/v1";
    case Environment.test:
      return "https://test392.vsmrk.com/v1";
    case Environment.development:
      return "https://dev297.vsmrk.com/v1";
    default:
      return "https://dev297.vsmrk.com/v1";
  }
}

// https://dev297.vsmrk.com/v1/
// https://test392.vsmrk.com/v1/
// https://api.vsmrk.com/v1/
