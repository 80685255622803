import React from "react";
import ReactDOM from "react-dom";
import "index.scss";
import App from "./App";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { getAuth } from "firebase/auth";
import "firebase/compat/firestore";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { RecoilRoot } from "recoil";
import config from "app/api/configs/config.json";
import { BrowserRouter } from "react-router-dom";
import GlobalStateProvider from "app/xstate/globalState/globalStateProvider";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.css";
import "swiper/css";
import "swiper/css/effect-cards";

const firebaseApp = firebase.initializeApp(config);

export const auth = getAuth(firebaseApp);
const firestoreDb = getFirestore(firebaseApp);
export function getCollection(collectionUrl: string) {
  return getDocs(collection(firestoreDb, collectionUrl));
}
export { firebase };

export const rootDiv = document.getElementById("root");

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <RecoilRoot>
        <GlobalStateProvider>
          <App />
        </GlobalStateProvider>
      </RecoilRoot>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
