export enum Environment {
  development = "development",
  production = "production",
  test = "test",
}

export const isProd =
  process.env.REACT_APP_NODE_ENV !== Environment.development;
// export const isProd = true;

export const currentEnvironment: Environment = (process.env
  .REACT_APP_NODE_ENV || Environment.development) as Environment;
console.log(currentEnvironment);
