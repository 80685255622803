import React, { useMemo } from "react";
import { Form, Input, Modal, Select } from "antd";
import { useB2BPromoCodesData } from "app/xstate/b2bPromoCodes/hooks/useB2BPromoCodesData";
import { usePromoCodesActions } from "app/xstate/b2bPromoCodes/hooks/usePromoCodesActions";
import { date } from "lib/dates";

// function getRandomArbitrary(min: number, max: number) {
//   return Math.floor(Math.random() * (max - min) + min);
// }

const CreatePromoCodeModal: React.FC = () => {
  const [form] = Form.useForm();
  const {
    createPromoCodeModalIsOpen,
    instructorsHashTable,
    createPromoCodeLoading,
  } = useB2BPromoCodesData();
  const { createPromoCode, closeCreatePromoCodeModal } = usePromoCodesActions();

  const instructorsArray = useMemo(
    () => Object.values(instructorsHashTable),
    [instructorsHashTable]
  );

  const onSubmit = async () => {
    try {
      const formData = await form.validateFields();
      createPromoCode({
        ...formData,
        validUntilTo: date().add(1, "y").toISOString(),
      });
      form.resetFields();
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <Modal
      title={"Выпуск промокодов"}
      okText={"Выпустить"}
      cancelText={"Отменить"}
      visible={createPromoCodeModalIsOpen}
      onCancel={closeCreatePromoCodeModal}
      onOk={onSubmit}
      confirmLoading={createPromoCodeLoading}
      maskClosable={false}
    >
      <Form
        labelCol={{
          span: 5,
        }}
        labelAlign={"left"}
        form={form}
        initialValues={{
          affiliateId: instructorsArray[0]?.id,
          type: "PROMO_30",
        }}
      >
        <Form.Item
          name={"affiliateId"}
          label={"Тренер"}
          required
          rules={[
            {
              required: true,
              message: "Обязательное поле",
            },
          ]}
        >
          <Select>
            {instructorsArray.map((instructor) => (
              <Select.Option value={instructor.id} key={instructor.id}>
                {instructor.firstName} {instructor.lastName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={"type"}
          label={"Скидка"}
          required
          rules={[
            {
              required: true,
              message: "Обязательное поле",
            },
          ]}
        >
          <Select>
            <Select.Option value={"PROMO_30"}>Месяц за 549</Select.Option>
            <Select.Option value={"PROMO_90"}>3 Месяца за 990</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          required
          rules={[
            {
              required: true,
              message: "Обязательное поле",
            },
          ]}
          name={"promocode"}
          label={"Промокод"}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreatePromoCodeModal;
