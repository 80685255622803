import { useMemo } from "react";
import { useB2BCompaniesActor } from "app/xstate/b2bCompanies/hooks/useB2BCompaniesActor";

export function useOrganizationsData() {
  const [{ context }] = useB2BCompaniesActor();

  const { organizationsHashTable } = context;

  return {
    ...context,
    listOfOrganizationsArray: useMemo(
      () => Object.values(organizationsHashTable),
      [organizationsHashTable]
    ),
  };
}
