import { Marathon } from "sport-app-types";
import { Api } from "app/api/api";
import { getUrlFns } from "app/api/getData/constants";

export async function getMarathonById(
  marathonId: string
): Promise<Marathon | null> {
  try {
    const res = await Api.get<Marathon>(getUrlFns.getMarathonById(marathonId));

    return res.data;
  } catch (e) {
    console.warn("Cant get marathon.", `MarathonId: ${marathonId}.`, e);
    return null;
  }
}
