import React from "react";
import s from "pages/users/customers/styles.module.scss";
import { Table } from "antd";
import { useListOfCustomers } from "pages/users/customers/listOfCustomers/useListOfCustomers";
import Loader from "components/loader";

const ListOfCustomers: React.FC = () => {
  const { customersListData, customersListLoading, columns } =
    useListOfCustomers();

  if (!customersListData || customersListLoading) {
    return <Loader />;
  }

  return (
    <div className={s.listOfCustomersPageContainer}>
      <div className={s.listOfCustomersPageTitle}>Клиенты</div>

      <Table columns={columns} dataSource={customersListData} bordered />
    </div>
  );
};

export default ListOfCustomers;
