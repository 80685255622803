import React from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import InstructorMasterTrainingsList from "components/pages/instructorMasterTrainings";
import Loader from "components/loader";
import Title from "antd/lib/typography/Title";
import { Button } from "antd";
import { useApi } from "app/api/hooks/useApi";

const InstructorMasterTrainingsPage: React.FC = () => {
  const { push } = useHistory();
  const { trainerId } = useParams<{ trainerId: string }>();

  const { getMasterTrainingsList } = useApi();

  const { value: masterTrainingsList, loading } = getMasterTrainingsList({
    id: trainerId,
  });

  if (loading) {
    return <Loader />;
  }

  if (!masterTrainingsList) {
    push("/");
    return <></>;
  }

  return (
    <>
      <Title>Список мастер тренировок</Title>
      <Link to={`/trainers/edit/${trainerId}`}>
        <Button>Вернуться к редактированию анкеты тренера</Button>
      </Link>
      <InstructorMasterTrainingsList masterTrainings={masterTrainingsList} />
    </>
  );
};

export default InstructorMasterTrainingsPage;
