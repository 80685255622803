import {
  appendInvisibleElement,
  removeInvisibleElement,
} from "app/helpers/invisibleElementHelpers";
import { toast } from "react-hot-toast";

export interface UploadFileFromUserResult {
  file: File;
  stringData: string;
}

const MAX_FILE_SIZE = 10485760;

export function uploadFilesFromUser<T extends number>(
  fileTypes: string,
  maxFileCount: T
): T extends 1
  ? Promise<UploadFileFromUserResult>
  : Promise<UploadFileFromUserResult[]> {
  const isMulti = maxFileCount > 1;
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute("accept", fileTypes);
  const clickEventHandler = (event: MouseEvent) => {
    const element = event.target as HTMLInputElement;
    element.value = "";
  };
  input.addEventListener("click", clickEventHandler);
  input.style.display = "none";
  appendInvisibleElement(input);
  if (isMulti && maxFileCount > 1) {
    input.multiple = true;
  }
  return new Promise<any>((resolve) => {
    const changeEventHandler = () => {
      const { files } = input;
      if (!files) {
        return;
      }

      const res: Promise<UploadFileFromUserResult | null>[] = Array.from(
        files
      ).map(async (file) => {
        const reader = new FileReader();
        if (file.size > MAX_FILE_SIZE) {
          toast.error(`Размер фото ${file.name} не должен превышать 10мб. `);
          return null;
        }

        const compressedFile = file;

        return new Promise((readerResolve) => {
          reader.readAsDataURL(file);
          reader.onload = () => {
            const { result } = reader;
            if (result) {
              readerResolve({
                file: compressedFile,
                stringData: result as string,
              });
            }
          };
        });
      });

      Promise.all(res)
        .then((d) => {
          const filteredFiles = d.filter((file) => file);
          resolve(
            isMulti ? filteredFiles.slice(0, maxFileCount) : filteredFiles[0]
          );
          removeInvisibleElement(input);
        })
        .catch(console.log);
    };
    input.addEventListener("change", changeEventHandler);
    input.click();
  });
}
