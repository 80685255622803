import { TrainingDirection } from "sport-app-types/src/universal";
import { TrainingDirectionNames } from "app/types/TrainingDirection";

function getTrainingDirectionName(
  direction?: TrainingDirection | null
): typeof TrainingDirectionNames[keyof typeof TrainingDirectionNames] | "" {
  if (!direction) {
    return "";
  }
  return TrainingDirectionNames[direction];
  // switch (direction) {
  //   case TrainingDirection.power:
  //     return TrainingDirectionNames.power;
  //   case TrainingDirection.stretch:
  //     return TrainingDirectionNames.stretch;
  //   case TrainingDirection.burn:
  //     return TrainingDirectionNames.burn;
  //   case TrainingDirection.balance:
  //     return TrainingDirectionNames.balance;
  //   case TrainingDirection.artistic:
  //     return TrainingDirectionNames.artistic;
  //   default:
  //     return "";
  // }
}

export default getTrainingDirectionName;
