export const operatorRouteFns = {
  marathonsList: () => "/marathons",
  editMarathon: (id?: string) => `/marathons/${id || ":marathonId"}`,
  createMarathon: () => `/marathons/new`,
  marathonsSchedule: () => "/marathons/schedule",
  marathonsRequests: () => "/marathons/requests",
  marathonDetails: (id?: string) => `/marathons/${id || ":marathonId"}`,
  confirmMarathon: (id: string) =>
    `${operatorRouteFns.marathonDetails(id)}/confirm`,
};
