import { useB2BPromoCodesActor } from "app/xstate/b2bPromoCodes/hooks/useB2BPromoCodesActor";
import { useMemo } from "react";

export function useB2BPromoCodesData() {
  const [{ context }] = useB2BPromoCodesActor();

  return {
    ...context,
    promoCodesListArray: useMemo(
      () => Object.values(context.promoCodesHashTable),
      [context.promoCodesHashTable]
    ),
  };
}
