import { useCallback, useEffect } from "react";
import { useMachine } from "@xstate/react";
import { customersMachine } from "app/xstate/customersMachine";
import { GetCustomersListProps } from "app/api/getData/getCustomersList";
import { getColumnSearchProps } from "components/table/getColumnSearchProps";

export function useListOfCustomers() {
  const [customersList, send] = useMachine(customersMachine);

  const updateSearch = useCallback((search: GetCustomersListProps) => {
    send({
      type: "SET_SEARCH_PARAMS",
      search,
    });
  }, []);

  const syncListOfCustomers = useCallback(() => {
    send({ type: "SEARCH" });
  }, []);

  useEffect(syncListOfCustomers, []);

  const columns = [
    {
      title: "Фамилия",
      dataIndex: "lastName",
      key: "lastName",
      width: "30%",
      ...getColumnSearchProps({
        dataIndex: "Фамилии",
        onChange: (e) => {
          updateSearch({
            query: e.target.value,
            field: "lastName",
          });
        },
        onSubmit: syncListOfCustomers,
      }),
    },
    {
      title: "Имя",
      dataIndex: "firstName",
      key: "firstName",
      width: "20%",
      ...getColumnSearchProps({
        dataIndex: "имени",
        onChange: (e) => {
          updateSearch({
            query: e.target.value,
            field: "firstName",
          });
        },
        onSubmit: syncListOfCustomers,
      }),
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
      ...getColumnSearchProps({
        dataIndex: "телефону",
        onChange: (e) => {
          updateSearch({
            query: e.target.value,
            field: "phone",
          });
        },
        onSubmit: syncListOfCustomers,
      }),
    },
    {
      title: "Почта",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps({
        dataIndex: "почте",
        onChange: (e) => {
          updateSearch({
            query: e.target.value,
            field: "email",
          });
        },
        onSubmit: syncListOfCustomers,
      }),
    },
  ];

  return {
    columns,
    customersListData: customersList.context.customers,
    customersListLoading: customersList.context.loading,
  };
}
