import { useGlobalState } from "app/xstate/globalState/globalStateProvider";
import { useSelector } from "@xstate/react";
import { filterMarathonsNeedSubmitRequests } from "app/api/getData/getMarathons";
import React from "react";

const select = (state: any) => state?.context?.marathons;

export function useNeedSubmitMarathons() {
  const { marathons } = useGlobalState();

  const marathonsData = useSelector(marathons, select);

  return React.useMemo(
    () => filterMarathonsNeedSubmitRequests(Object.values(marathonsData)),
    [marathonsData]
  );
}
