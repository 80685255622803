import { Api } from "app/api/api";
import { GetUrlFnNames, getUrlFns } from "app/api/getData/constants";
import { Customer, CollectionResult } from "sport-app-types";

interface GetCustomersListPropsSimple {
  field: "email" | "firstName" | "lastName" | "phone";
  query: string;
}

interface GetCustomersListPropsMulti {
  field: "createdAt";
  query: {
    createdAtAfter?: string;
    createdAtBefore?: string;
  };
}

export type GetCustomersListProps =
  | GetCustomersListPropsMulti
  | GetCustomersListPropsSimple;

export function getCustomersList({
  field,
  query,
}: GetCustomersListProps): Promise<Customer[]> {
  let params: Record<string, any> = {};
  if (typeof query !== "string") {
    params = {
      ...query,
    };
  } else {
    params[field] = query;
  }

  return Api.post<CollectionResult<Customer[]>>(
    getUrlFns[GetUrlFnNames.getCustomers](),
    params
  ).then((d) => d.data.result);
}
