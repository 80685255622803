import React from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { dateFromUtc } from "lib/dates";
import { timeFormatDDMMYYYY } from "app/api/getData/getB2BLicenceKeysList";
import { Marathon } from "sport-app-types";
import { InstructorInDb } from "sport-app-types/src/server";

const ScheduledMarathonTable: React.FC<{
  marathons: Marathon[];
}> = ({ marathons }) => {
  const columns: ColumnsType<Marathon> = [
    {
      key: "date",
      dataIndex: "createdAt",
      title: "Дата",
      render: (date: Marathon["createdAt"]) =>
        dateFromUtc(date).format(timeFormatDDMMYYYY),
      sorter: (a, b) =>
        dateFromUtc(a.startDate).isBefore(b.startDate) ? -1 : 1,
      defaultSortOrder: "ascend",
    },
    {
      key: "name",
      dataIndex: "name",
      title: "Название марафона",
    },
    {
      key: "instructor",
      dataIndex: "instructor",
      title: "Тренер",
      render: (instructor: InstructorInDb) =>
        `${instructor.lastName || ""} ${instructor.firstName || ""} ${
          instructor.middleName || ""
        }`,
    },
    {
      key: "volume",
      dataIndex: "volume",
      title: "Продолжительность",
      render: (volume: Marathon["volume"]) => `${volume} занятий`,
    },
    {
      key: "startData",
      dataIndex: "startDate",
      title: "Старт",
      render: (date: Marathon["createdAt"]) =>
        dateFromUtc(date).format(timeFormatDDMMYYYY),
    },
    {
      key: "finishDate",
      dataIndex: "finishDate",
      title: "Конец",
      render: (date: Marathon["createdAt"]) =>
        dateFromUtc(date).format(timeFormatDDMMYYYY),
    },
  ];

  return (
    <div>
      <Table columns={columns} dataSource={marathons} />
    </div>
  );
};

export default ScheduledMarathonTable;
