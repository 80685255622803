import React from "react";
import Title from "antd/lib/typography/Title";
import { useTrainingStatistics } from "pages/trainingStatistics/hooks/useTrainingStatistics";
import { Button, DatePicker, Row, Table } from "antd";
import Loader, { LoaderVariants } from "components/loader";
import "moment/locale/ru";
import ru_RU from "antd/es/date-picker/locale/ru_RU";
import moment from "moment";
import { timeFormatDDMMYYYY } from "app/api/getData/getB2BLicenceKeysList";
import { useTrainingStatisticsData } from "app/xstate/trainingStatistics/hooks/useTrainingStatisticsData";
import { useTrainingStatisticsActions } from "app/xstate/trainingStatistics/hooks/useTrainingStatisticsActions";

const TrainingStatisticsPage: React.FC = () => {
  const { columns, onRangeChange, downloadTrainingsStatistics } =
    useTrainingStatistics();
  const { loading, search, trainingsList } = useTrainingStatisticsData();
  const { syncTrainingStatistics } = useTrainingStatisticsActions();

  return (
    <>
      <Row justify={"center"}>
        <Title>Статистика проведенных тренировок</Title>
      </Row>
      <Row
        style={{
          marginTop: 22,
        }}
      >
        <DatePicker.RangePicker
          locale={ru_RU}
          value={[moment(search.gte), moment(search.lte)]}
          onChange={onRangeChange}
          format={timeFormatDDMMYYYY}
        />
        <Button
          onClick={syncTrainingStatistics}
          type={"primary"}
          style={{
            marginLeft: 32,
          }}
        >
          Применить
        </Button>
        <Button
          onClick={downloadTrainingsStatistics}
          type={"primary"}
          style={{
            marginLeft: 32,
          }}
        >
          Скачать отчёт
        </Button>
      </Row>

      {loading ? (
        <Loader variant={LoaderVariants.small} />
      ) : (
        trainingsList && (
          <Table columns={columns} dataSource={trainingsList} bordered />
        )
      )}
    </>
  );
};

export default TrainingStatisticsPage;
