import { FieldInfo, parseAsync } from "json2csv";

export async function convertJsonToCsv<T>(
  fields: (FieldInfo<T> | string)[],
  obj: T[]
) {
  const link = document.createElement("a");
  const csv = await parseAsync(obj, { fields });

  const blob = new Blob(["\ufeff", csv]);
  const url = URL.createObjectURL(blob);
  link.download = "data.csv";
  link.href = url;

  link.click();
}
