import { assign, createMachine } from "xstate";
import { TrainingRecord } from "pages/trainingsRecords";
import {
  getTrainingRecords,
  GetTrainingRecordsParams,
} from "app/api/getData/getTrainingRecords";
import { date } from "lib/dates";

export interface FilterTrainingRecordsParams {
  instructor: string;
}

type TrainingRecordsMachineActions =
  | {
      type: "SYNC_DATA";
    }
  | {
      type: "SELECT_RECORD";
      payload: TrainingRecord;
    }
  | {
      type: "CLEAR_SELECTED_RECORD";
    }
  | {
      type: "UPDATE_SEARCH_PARAMS";
      payload: Partial<GetTrainingRecordsParams>;
    }
  | {
      type: "UPDATE_FILTER_PARAMS";
      payload: Partial<FilterTrainingRecordsParams>;
    };

export interface TrainingRecordsMachineContext {
  trainingRecords: TrainingRecord[] | null;
  selectedRecord: TrainingRecord | null;
  trainingRecordsLoading: boolean;
  searchRecordsParams: GetTrainingRecordsParams;
  filterRecordsParams: FilterTrainingRecordsParams;
}

export const trainingRecordsMachine = createMachine<
  TrainingRecordsMachineContext,
  TrainingRecordsMachineActions
>({
  key: "trainingRecordsMachine",
  id: "trainingRecordsMachineId",
  context: {
    selectedRecord: null,
    trainingRecords: null,
    trainingRecordsLoading: false,
    searchRecordsParams: {
      lte: date().endOf("d").toISOString(),
      gte: date().subtract(1, "w").startOf("d").toISOString(),
    },
    filterRecordsParams: {
      instructor: "",
    },
  },
  initial: "idle",
  states: {
    idle: {
      on: {
        SYNC_DATA: {
          target: "dataLoading",
          actions: assign({
            trainingRecordsLoading: (_) => true,
          }),
        },
        SELECT_RECORD: {
          actions: assign({
            selectedRecord: (_, { payload }) => payload,
          }),
        },
        CLEAR_SELECTED_RECORD: {
          actions: assign({
            selectedRecord: (_) => null,
          }),
        },
        UPDATE_SEARCH_PARAMS: {
          actions: assign({
            searchRecordsParams: ({ searchRecordsParams }, { payload }) => ({
              ...searchRecordsParams,
              ...payload,
            }),
          }),
        },
        UPDATE_FILTER_PARAMS: {
          actions: assign({
            filterRecordsParams: ({ filterRecordsParams }, { payload }) => ({
              ...filterRecordsParams,
              ...payload,
            }),
          }),
        },
      },
    },
    dataLoading: {
      invoke: {
        src: ({ searchRecordsParams }) =>
          getTrainingRecords(searchRecordsParams),
        onDone: {
          target: "idle",
          actions: assign({
            trainingRecordsLoading: (_) => false,
            trainingRecords: (_, { data }) => data,
          }),
        },
        onError: {
          target: "idle",
          actions: assign({
            trainingRecordsLoading: (_) => false,
          }),
        },
      },
    },
  },
});
