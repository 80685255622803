import { useGlobalState } from "app/xstate/globalState/globalStateProvider";
import { useSelector } from "@xstate/react";

const selector = (state: any) => state.context.marathons.loading;

export function useMarathonsLoadingData() {
  const { marathons } = useGlobalState();

  return useSelector(marathons, selector);
}
