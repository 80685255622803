import React from "react";
import s from "components/pages/trainingsRecordsList/styles.module.scss";
import { Input, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import "antd/dist/antd.min.css";
import { dateFromUtc } from "lib/dates";
import { TrainingRecord } from "pages/trainingsRecords";
import { useTrainingRecordsActions } from "app/xstate/trainingRecords/hooks/useTrainingRecordsActions";
import { useTrainingRecordsData } from "app/xstate/trainingRecords/hooks/useTrainingRecordsData";
import Loader from "components/loader";
import { SearchOutlined } from "@ant-design/icons";

const TrainingsRecordsList: React.FC = () => {
  const { selectTrainingRecord, updateFilterRecordParams } =
    useTrainingRecordsActions();
  const { trainingRecordsLoading, filterRecordsParams, filteredRecordsList } =
    useTrainingRecordsData();
  const columns: ColumnsType<TrainingRecord> = [
    {
      title: "Тренер",
      dataIndex: "instructor",
      key: "firstName",
      sorter: {
        compare: (a, b) =>
          `${a.instructor?.firstName || ""}` < `${b.instructor?.lastName || ""}`
            ? 1
            : -1,
      },
      filterDropdown: () => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Начните вводить`}
            value={filterRecordsParams.instructor}
            onChange={(e) =>
              updateFilterRecordParams({ instructor: e.target.value || "" })
            }
            style={{ marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      render: (instructor) => (
        <span>
          {instructor?.lastName} {instructor?.firstName}
        </span>
      ),
    },
    {
      title: "Дата",
      dataIndex: "recordDate",
      key: "recordDate",
      render: (value) => (
        <span>{dateFromUtc(value).format("DD.MM.YYYY HH:mm")}</span>
      ),
      sorter: {
        compare: (a, b) =>
          dateFromUtc(a.recordDate).isBefore(b.recordDate) ? 1 : -1,
      },
    },
    {
      title: "Мастер-тренировка",
      key: "nameTraining",
      dataIndex: "training",
      sorter: {
        compare: (a, b) =>
          `${a.training.masterTraining?.name || ""}` >
          `${b.training.masterTraining?.name || ""}`
            ? -1
            : 1,
      },
      render: (training) => <span>{training?.masterTraining?.name}</span>,
    },

    {
      title: "",
      dataIndex: "see",
      key: "see",
      render: (_, record) => (
        <span
          className={s.seeTranslationLink}
          onClick={() => {
            selectTrainingRecord(record);
          }}
        >
          Смотреть
        </span>
      ),
    },
  ];

  if (trainingRecordsLoading) {
    return <Loader />;
  }

  if (!filteredRecordsList) {
    return null;
  }

  return <Table columns={columns} dataSource={filteredRecordsList} bordered />;
};

export default TrainingsRecordsList;
