import React, { useCallback, useState } from "react";
import { CreateOrEditMarathonFormComponent } from "pages/createOrEditMarathon/types";
import { Button, Form, Typography } from "antd";
import s from "./styles.module.scss";
import { uploadFilesFromUser } from "app/helpers/uploadFileFromUser";
import { uploadMarathonPhotosToStorage } from "app/api/createData/uploadMarathonPhotoToServer";
import { useCreateOrEditMarathonActor } from "app/xstate/createOrEditMarathon/hooks/useCreateOrEditMarathonActor";
import { toast } from "react-hot-toast";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";

export const IMAGE_FORMATS = ".png,.jpg,.jpeg,.HEIC";

const CreateOrEditMarathonThirdStepForm: CreateOrEditMarathonFormComponent = ({
  marathon,
}) => {
  const [, send] = useCreateOrEditMarathonActor();
  const [photos, setPhotos] = useState<string[]>(() => marathon.covers || []);
  const [loading, setLoading] = useState<number | null>(null);

  const uploadPhoto = async (newPhotoIndex: number) => {
    try {
      const files = await uploadFilesFromUser(IMAGE_FORMATS, 1);
      setLoading(newPhotoIndex);

      const filesToUpload = Array.isArray(files)
        ? files.map((file) => file.stringData)
        : [(files as any).stringData];

      const photoUrl = await uploadMarathonPhotosToStorage(filesToUpload);

      setPhotos((oldPhotos) => {
        const newPhotos = [...oldPhotos];

        [newPhotos[newPhotoIndex]] = photoUrl;

        return newPhotos;
      });
      setLoading(null);
    } catch (e: any) {
      setLoading(null);
    }
  };

  const deletePhoto = useCallback((index: number) => {
    setPhotos((oldPhotos) =>
      oldPhotos.map((url, photoIndex) => (index !== photoIndex ? url : ""))
    );
  }, []);

  const onDoneClick = useCallback(() => {
    if (!photos.length) {
      toast.error("Недостаточно фотогорафий");
      return;
    }
    send({
      type: "UPDATE_MARATHON",
      payload: {
        covers: photos,
      },
    });
    send({
      type: "SET_CURRENT_STEP",
      payload: "Fourth",
    });
  }, [photos]);

  return (
    <Form className={s.formContainer}>
      <Typography.Title>Полное фото марафона</Typography.Title>

      <div className={s.photos}>
        <div>
          <Button
            icon={<UploadOutlined />}
            loading={loading === 0}
            disabled={loading !== 0 && loading !== null}
            onClick={() => {
              uploadPhoto(0);
            }}
          >
            Загрузить фото
          </Button>
          <div
            className={`${s.fullPhoto} ${s.long}`}
            style={{
              backgroundImage: `url(${photos[0]})`,
            }}
          >
            <DeleteOutlined
              className={s.trash}
              onClick={() => {
                deletePhoto(0);
              }}
            />
            <div>{marathon.name}</div>
            <div>{marathon.volume} Тренировки</div>
          </div>
        </div>
        <div>
          <Button
            icon={<UploadOutlined />}
            loading={loading === 1}
            disabled={loading !== 1 && loading !== null}
            onClick={() => {
              uploadPhoto(1);
            }}
          >
            Загрузить фото
          </Button>
          <div
            className={`${s.fullPhoto} ${s.long}`}
            style={{
              backgroundImage: `url(${photos[1]})`,
            }}
          >
            <DeleteOutlined
              className={s.trash}
              onClick={() => {
                deletePhoto(1);
              }}
            />
            <div>{marathon.name}</div>
            <div>{marathon.volume} Тренировки</div>
          </div>
        </div>
        <div>
          <Button
            icon={<UploadOutlined />}
            loading={loading === 2}
            disabled={loading !== 2 && loading !== null}
            onClick={() => {
              uploadPhoto(2);
            }}
          >
            Загрузить фото
          </Button>
          <div
            className={`${s.fullPhoto} ${s.long}`}
            style={{
              backgroundImage: `url(${photos[2]})`,
            }}
          >
            <DeleteOutlined
              className={s.trash}
              onClick={() => {
                deletePhoto(2);
              }}
            />
            <div>{marathon.name}</div>
            <div>{marathon.volume} Тренировки</div>
          </div>
        </div>

        <div>
          <Button
            icon={<UploadOutlined />}
            loading={loading === 3}
            disabled={loading !== 3 && loading !== null}
            onClick={() => {
              uploadPhoto(3);
            }}
          >
            Загрузить фото
          </Button>
          <div
            className={`${s.fullPhoto} ${s.middle}`}
            style={{
              backgroundImage: `url(${photos[3]})`,
            }}
          >
            <DeleteOutlined
              className={s.trash}
              onClick={() => {
                deletePhoto(3);
              }}
            />
            <div>{marathon.name}</div>
            <div>{marathon.volume} Тренировки</div>
          </div>
        </div>

        <div>
          <Button
            icon={<UploadOutlined />}
            loading={loading === 4}
            disabled={loading !== 4 && loading !== null}
            onClick={() => {
              uploadPhoto(4);
            }}
          >
            Загрузить фото
          </Button>
          <div
            className={`${s.fullPhoto} ${s.sq}`}
            style={{
              backgroundImage: `url(${photos[4]})`,
            }}
          >
            <DeleteOutlined
              className={s.trash}
              onClick={() => {
                deletePhoto(4);
              }}
            />
            <div>{marathon.name}</div>
            <div>{marathon.volume} Тренировки</div>
          </div>
        </div>
      </div>

      {/* {photos.map((bg, bgIndex) => ( */}
      {/*  <SwiperSlide key={`create_or_edit_marathon_${bg}`}> */}
      {/*    <div> */}
      {/*      <div */}
      {/*        className={s.fullPhoto} */}
      {/*        style={{ */}
      {/*          backgroundImage: `url(${bg})`, */}
      {/*        }} */}
      {/*      > */}
      {/*        <DeleteOutlined */}
      {/*          className={s.trash} */}
      {/*          onClick={() => { */}
      {/*            deletePhoto(bgIndex); */}
      {/*          }} */}
      {/*        /> */}
      {/*        <div>{marathon.name}</div> */}
      {/*        <div>{marathon.volume} Тренировки</div> */}
      {/*      </div> */}
      {/*    </div> */}
      {/*  </SwiperSlide> */}
      {/* ))} */}

      <Button onClick={onDoneClick} disabled={loading !== null}>
        Далее
      </Button>
    </Form>
  );
};

export default CreateOrEditMarathonThirdStepForm;
