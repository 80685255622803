import React, { MouseEventHandler, useMemo } from "react";
import * as CSS from "csstype";

import { Icons } from "./constants";

export type IconNames = keyof typeof Icons;

export interface IconProps {
  name: IconNames;
  size?: number;
  fill?: string;
  className?: string;
  onClick?: MouseEventHandler<SVGSVGElement>;
}

export const Icon: React.FC<IconProps> = ({
  fill = "#D1D3D5",
  size = 24,
  name,
  onClick,
  className,
}) => {
  const Tag = Icons[name];

  const tagStyle: CSS.Properties = useMemo(
    () => ({
      width: `${size}px`,
      height: `${size}px`,
      fill,
      cursor: onClick ? "pointer" : "inherit",
      strokeWidth: "10px",
    }),
    [size, onClick, fill]
  );

  return (
    <Tag name={name} style={tagStyle} onClick={onClick} className={className} />
  );
};
