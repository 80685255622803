import { useMarathonsActor } from "app/xstate/marathons/hooks/useMarathonsActor";
import { useCallback } from "react";

export function useSyncMarathonsData() {
  const [_, send] = useMarathonsActor();

  return useCallback(() => {
    send({ type: "SYNC_DATA" });
  }, []);
}
