import { useCreateOrEditMarathonActor } from "app/xstate/createOrEditMarathon/hooks/useCreateOrEditMarathonActor";
import { useCallback, useEffect, useState } from "react";
import { useCreateOrEditMarathonData } from "app/xstate/createOrEditMarathon/hooks/useCreateOrEditMarathonData";
import {
  CreateMarathonStepNames,
  createMarathonStepNumbers,
} from "pages/createOrEditMarathon/index";
import { useMarathonsDataById } from "app/xstate/marathons/hooks/useMarathonDataById";

export function useCreateOrEditMarathon(marathonId?: string) {
  const [, send] = useCreateOrEditMarathonActor();
  const { marathon, finishedStepsCount, step } = useCreateOrEditMarathonData();

  const [clearingMarathon, setClearingMarathon] = useState(false);

  const refreshForm = useCallback(async () => {
    setClearingMarathon(true);

    setTimeout(() => {
      setClearingMarathon(false);
    });
  }, []);

  const { marathon: marathonDefault, marathonDataLoading } =
    useMarathonsDataById(marathonId);

  useEffect(() => {
    send({
      type: "SET_CURRENT_STEP",
      payload: "First",
    });
  }, []);

  useEffect(() => {
    if ((marathonId === "new" || !marathonId) && marathon?.id) {
      send({
        type: "CLEAR_MARATHON",
      });
      refreshForm();
      return;
    }
    if (marathonId && marathonDefault && !marathonDataLoading) {
      send({
        type: "UPDATE_MARATHON",
        payload: marathonDefault,
      });
    }
  }, [marathonDefault, marathonDataLoading]);

  useEffect(() => {
    if (marathonId && marathonDefault && !marathonDataLoading) {
      send({
        type: "SET_CURRENT_STEP",
        payload: "Finish",
      });

      send({
        type: "SET_CURRENT_STEP",
        payload: "First",
      });

      // send({
      //   type: "SYNC_MARATHON",
      //   payload: {
      //     marathonId,
      //   },
      // });
    }

    send({
      type: "SYNC_INSTRUCTORS",
    });
  }, [marathonDataLoading, marathonDefault]);

  const setCurrentStep = useCallback((newStep: CreateMarathonStepNames) => {
    send({
      type: "SET_CURRENT_STEP",
      payload: newStep,
    });
  }, []);

  return {
    marathon,
    marathonDataLoading:
      (marathonDataLoading && marathonId && marathonId !== "new") ||
      clearingMarathon,
    finishedStepsCount,
    currentStepNumber: createMarathonStepNumbers[step],
    step,
    setCurrentStep,
  };
}
