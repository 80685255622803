import { useGlobalState } from "app/xstate/globalState/globalStateProvider";
import { useSelector } from "@xstate/react";
import { Marathon } from "sport-app-types";

const select = (state: any) => state.context.marathons;

export function useMarathonById(id: string): Marathon | null {
  const { marathons } = useGlobalState();

  const marathonsData: Record<string, Marathon> = useSelector(
    marathons,
    select
  );

  return marathonsData[id] || null;
}
