import React from "react";
import { Button } from "antd";
import { Marathon, MarathonStatus } from "sport-app-types";
import { date } from "lib/dates";

type MarathonsFilters = {
  [key in MarathonStatus]: Filter;
} & {
  planned: Filter;
};

export type MarathonsListFilterValue = {
  [key in keyof MarathonsFilters]?: boolean;
};

export type OnMarathonsListFilterChange = (
  filter: keyof MarathonsFilters
) => void;

interface MarathonsListFilterProps {
  value: MarathonsListFilterValue;
  onChange: OnMarathonsListFilterChange;
}

interface Filter {
  label: string;
  filterFn: (marathon: Marathon) => boolean;
}

export const marathonsFilters: MarathonsFilters = {
  [MarathonStatus.active]: {
    label: "Уже идут",
    filterFn: (marathon) =>
      Boolean(
        marathon.status === MarathonStatus.active &&
          marathon.startDate &&
          marathon.finishDate &&
          date(marathon.startDate).isBefore(date()) &&
          date(marathon.finishDate).isAfter(date())
      ),
  },
  planned: {
    label: "Запланированные",
    filterFn: (marathon) =>
      Boolean(
        marathon.status === MarathonStatus.active &&
          marathon.startDate &&
          date(marathon.startDate).isAfter(date())
      ),
  },
  [MarathonStatus.draft]: {
    label: "В черновиках",
    filterFn: (marathon) => Boolean(marathon.status === MarathonStatus.draft),
  },
  [MarathonStatus.finished]: {
    label: "Завершенные",
    filterFn: (marathon) =>
      Boolean(
        (marathon.finishDate && date(marathon.finishDate).isBefore(date())) ||
          marathon.status === MarathonStatus.finished
      ),
  },
};

const MarathonsListFilter: React.FC<MarathonsListFilterProps> = ({
  value,
  onChange,
}) => (
  <Button.Group>
    {(Object.keys(marathonsFilters) as Array<keyof MarathonsFilters>).map(
      (filterKey) => (
        <Button
          type={value[filterKey] ? "primary" : "default"}
          onClick={() => onChange(filterKey)}
          key={`marathonsFilters_${filterKey}`}
        >
          {marathonsFilters[filterKey].label}
        </Button>
      )
    )}
  </Button.Group>
);

export default MarathonsListFilter;
