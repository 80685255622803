import React from "react";
import { Button, Card, Row, Table, Typography } from "antd";
import {
  PromoCodeWithInstructor,
  useB2BPromoCodes,
} from "pages/b2bPromoCodes/hooks/useB2BPromoCodes";
import Loader, { LoaderVariants } from "components/loader";
import { usePromoCodesActions } from "app/xstate/b2bPromoCodes/hooks/usePromoCodesActions";
import CreatePromoCodeModal from "pages/b2bPromoCodes/createPromoCodeModal";
import { convertJsonToCsv } from "lib/jsonToCsv";
import { dateFromUtc } from "lib/dates";
import { timeFormatDDMMYYYY } from "app/api/getData/getB2BLicenceKeysList";

const B2BPromoCodesPage: React.FC = () => {
  const { columns, dataLoading, dataSource } = useB2BPromoCodes();
  const { openCreatePromoCodeModal } = usePromoCodesActions();

  return (
    <>
      <Row justify={"center"}>
        <Typography.Title>Многоразовые промокоды</Typography.Title>
      </Row>

      <Row justify={"end"}>
        <Button type={"primary"} onClick={openCreatePromoCodeModal}>
          Выпустить
        </Button>
      </Row>

      {dataLoading ? (
        <Loader variant={LoaderVariants.small} />
      ) : (
        <Table
          style={{
            marginTop: 10,
          }}
          columns={columns}
          dataSource={dataSource}
          bordered
        />
      )}
      <Row justify={"end"}>
        <Card bordered>
          <Typography.Text onClick={() => downloadPromoCodes(dataSource)}>
            Всего {dataSource.length} промокодов{" "}
            <Typography.Link>Скачать</Typography.Link>
          </Typography.Text>
        </Card>
      </Row>
      <CreatePromoCodeModal />
    </>
  );
};

export default B2BPromoCodesPage;

function downloadPromoCodes(promoCodes: PromoCodeWithInstructor[]) {
  convertJsonToCsv<PromoCodeWithInstructor>(
    [
      {
        label: "Выпущен",
        value: ({ createdAt }: any) =>
          dateFromUtc(createdAt || undefined).format(timeFormatDDMMYYYY),
      },
      {
        value: "promocode",
        label: "Ключ",
      },
      {
        value: "instructor",
        label: "Тренер",
      },
    ],
    promoCodes
  );
}
