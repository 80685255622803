import React from "react";
import { Form, Input, Modal, Typography } from "antd";
import { useOrganizationsActions } from "app/xstate/b2bCompanies/hooks/useCompaniesActions";
import { useOrganizationsData } from "app/xstate/b2bCompanies/hooks/useCompaniesData";

const AddOrEditOrganizationModal: React.FC = () => {
  const [form] = Form.useForm();
  const {
    createOrganization,
    updateOrganization,
    closeCreateOrEditOrganizationModal,
  } = useOrganizationsActions();
  const {
    selectedOrganization,
    createOrEditOrganizationLoading,
    createOrEditOrganizationModalIsOpen,
  } = useOrganizationsData();
  const isOpen = Boolean(
    selectedOrganization || createOrEditOrganizationModalIsOpen
  );

  const submit = () => {
    form.validateFields().then((values) => {
      if (selectedOrganization) {
        updateOrganization({
          ...selectedOrganization,
          ...values,
        });
      } else {
        createOrganization(values);
      }
      closeCreateOrEditOrganizationModal();
    });
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={closeCreateOrEditOrganizationModal}
      centered
      okText={selectedOrganization ? "Сохранить" : "Создать"}
      cancelText={"Отменить"}
      onOk={submit}
      confirmLoading={createOrEditOrganizationLoading}
      width={623}
      maskClosable={false}
    >
      <Typography.Title level={4}>
        Заполните все необходимые поля
      </Typography.Title>
      <Form
        form={form}
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 21 }}
        labelAlign={"left"}
        name={"createOrganizationModalForm"}
        initialValues={{
          ...selectedOrganization,
        }}
        style={{
          marginTop: 30,
        }}
      >
        <Form.Item
          label={"Название организации"}
          required
          name={"name"}
          rules={[{ required: true, message: "Обязательное поле" }]}
        >
          <Input placeholder={"Введите название компании"} />
        </Form.Item>
        <Form.Item
          label={"Контактное лицо"}
          required
          name={"contactPerson"}
          rules={[
            { required: true, message: "Обязательное поле" },
            {
              pattern: new RegExp("^[а-яА-ЯёЁ\\s]+$"),
              message: "Могут содержаться только русские буквы",
            },
          ]}
        >
          <Input placeholder={"Введите имя контактного лица"} />
        </Form.Item>
        <Form.Item
          label={"Телефон"}
          required
          name={"phone"}
          rules={[
            {
              required: true,
              pattern: new RegExp(
                "^(\\+7|7|8)?[\\s\\-]?\\(?[489][0-9]{2}\\)?[\\s\\-]?[0-9]{3}[\\s\\-]?[0-9]{2}[\\s\\-]?[0-9]{2}$"
              ),
              message: "Введите корректный номер терефона",
            },
          ]}
        >
          <Input placeholder={"Введите телефон компании"} />
        </Form.Item>
        <Form.Item
          label={"e-mail"}
          required
          name={"email"}
          rules={[
            {
              required: true,
              type: "email",
              message: "Введите корректную почту",
            },
          ]}
        >
          <Input placeholder={"Введите электронную компании"} />
        </Form.Item>
        <Form.Item
          label={"Логотип (ссылка)"}
          required
          name={"logo"}
          rules={[{ required: true, message: "Обязательное поле" }]}
        >
          <Input placeholder={"Введите электронную компании"} />
        </Form.Item>
        <Form.Item
          label={"Фон (ссылка)"}
          required
          name={"background"}
          rules={[{ required: true, message: "Обязательное поле" }]}
        >
          <Input placeholder={"Введите электронную компании"} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddOrEditOrganizationModal;
