import { Training } from "sport-app-types";
import { Api } from "app/api/api";
import { GetUrlFnNames, getUrlFns } from "app/api/getData/constants";

export interface GetTrainingStatisticsProps {
  gte: string;
  lte: string;
}

export interface TrainingStatistics {
  id: string;
  trainingId: string;
  amountOfUsers: number;
  isLate: boolean;
  streamingVolume: number;
  averageWatchingVolume: number;
  trainingStatus: "SUCCEEDED";
  startTrainingTime: string;
  usersWVmore50: number;
  training: Training;
}

export async function getTrainingStatistics(
  params: GetTrainingStatisticsProps
): Promise<TrainingStatistics[]> {
  try {
    const trainingStatistics = await Api.post<
      TrainingStatistics[],
      GetTrainingStatisticsProps
    >(getUrlFns[GetUrlFnNames.getTrainingStatistics](), params);

    return trainingStatistics.data;
  } catch (e) {
    console.warn("Cant gat training statistics, ", e);
    throw "Не удалось загрузить статистику проведенных тренировок.";
  }
}
