import React from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import { operatorRouteFns } from "app/routes/constants";
import { dateFromUtc } from "lib/dates";
import { timeFormatDDMMYYYY } from "app/api/getData/getB2BLicenceKeysList";
import { Marathon } from "sport-app-types";
import { InstructorInDb } from "sport-app-types/src/server";

const MarathonRequestsTable: React.FC<{
  marathons: Marathon[];
}> = ({ marathons }) => {
  const columns: ColumnsType<Marathon> = [
    {
      key: "date",
      dataIndex: "createdAt",
      title: "Дата",
      render: (date: Marathon["createdAt"]) =>
        dateFromUtc(date).format(timeFormatDDMMYYYY),
      sorter: (a, b) =>
        dateFromUtc(a.startDate).isBefore(b.startDate) ? -1 : 1,
      defaultSortOrder: "descend",
    },
    {
      key: "name",
      dataIndex: "name",
      title: "Название марафона",
    },
    {
      key: "instructor",
      dataIndex: "instructor",
      title: "Тренер",
      render: (instructor: InstructorInDb) =>
        `${instructor.lastName || ""} ${instructor.firstName || ""} ${
          instructor.middleName || ""
        }`,
    },
    {
      key: "volume",
      dataIndex: "volume",
      title: "Продолжительность",
      render: (volume: Marathon["volume"]) => `${volume} занятий`,
    },
    {
      key: "submit",
      dataIndex: "id",
      title: "Подтвердить",
      render: (id: Marathon["id"]) => (
        <Link to={operatorRouteFns.marathonDetails(id)}>Подтвердить</Link>
      ),
    },
  ];

  return (
    <div>
      <Table columns={columns} dataSource={marathons} />
    </div>
  );
};

export default MarathonRequestsTable;
