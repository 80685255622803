import React, { useCallback, useMemo, useState } from "react";
import { CreateOrEditMarathonFormComponent } from "pages/createOrEditMarathon/types";
import { Button, Col, Input, Row, Typography } from "antd";
import {
  GlobalStateSelectorFn,
  useGlobalStateSelector,
} from "app/xstate/globalState/globalStateProvider";
import { Instructor } from "sport-app-types/src/client";
import CreateOrEditMarathonInstructorCard from "pages/createOrEditMarathon/fourthStepForm/instructorCard";
import s from "./styles.module.scss";
import { CreateOrEditMarathonMachineContext } from "app/xstate/createOrEditMarathon/machine";
import { generateEmptyArray } from "lib/generateEmptyArray";
import { toast } from "react-hot-toast";
import { InstructorInDb } from "sport-app-types/src/server";
import { useCreateOrEditMarathonActor } from "app/xstate/createOrEditMarathon/hooks/useCreateOrEditMarathonActor";
import InstructorsSpecializationFilter, {
  InstructorsSpecializationFilterValue,
  OnInstructorsSpecializationFilterChange,
} from "pages/createOrEditMarathon/fourthStepForm/filter";
import { InstructorSpecialization } from "sport-app-types";

const instructorsListSelector: GlobalStateSelectorFn<
  "createOrEditMarathon",
  Instructor[]
> = ({ instructors }) => Object.values(instructors);

const instructorsSelector: GlobalStateSelectorFn<
  "createOrEditMarathon",
  CreateOrEditMarathonMachineContext["instructors"]
> = ({ instructors }) => instructors;

const CreateOrEditMarathonFourthStepForm: CreateOrEditMarathonFormComponent = ({
  marathon,
}) => {
  const [instructorSpecializationFilter, setInstructorSpecializationFilter] =
    useState<InstructorsSpecializationFilterValue>({
      [InstructorSpecialization.fitness]: true,
      [InstructorSpecialization.nutritionist]: true,
      [InstructorSpecialization.psychologist]: true,
    });

  const onInstructorSpecializationFilterChange: OnInstructorsSpecializationFilterChange =
    useCallback((filter) => {
      setInstructorSpecializationFilter((oldFilters) => ({
        ...oldFilters,
        [filter]: !oldFilters[filter],
      }));
    }, []);

  const [, send] = useCreateOrEditMarathonActor();
  const instructorsList = useGlobalStateSelector(
    "createOrEditMarathon",
    instructorsListSelector
  );

  const instructorsHashTable = useGlobalStateSelector(
    "createOrEditMarathon",
    instructorsSelector
  );

  const [search, setSearch] = useState<string>("");

  const [instructorsInMarathon, setInstructorsInMarathon] = useState<string[]>(
    () =>
      (marathon.instructor?.map((instructor) => instructor.id) || []).concat(
        generateEmptyArray(3 - (marathon.instructor?.length || 0))
      )
  );

  const searchFilteredInstructors = useMemo(
    () =>
      instructorsList
        .filter((instructor) =>
          `${instructor.firstName} ${instructor.lastName}`
            .toLowerCase()
            .includes(search.toLowerCase())
        )
        .filter(
          (instructor) =>
            !instructorsInMarathon.filter(Boolean).includes(instructor.id)
        )
        .filter(
          (instructor) =>
            !instructor.specialization ||
            (instructor.specialization &&
              instructorSpecializationFilter[instructor.specialization])
        ),
    [
      search,
      instructorsList,
      instructorsInMarathon,
      instructorSpecializationFilter,
    ]
  );

  const onSearchUpdateHandler: React.ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    setSearch(target.value);
  };

  const onPlusClick = useCallback(
    (instructor: Instructor | InstructorInDb) => {
      const filteredInstructorsList = instructorsInMarathon.filter(Boolean);

      if (filteredInstructorsList.length > 2) {
        toast.error("В марафон можно добавить не более 3 тренеров");
        return;
      }

      setInstructorsInMarathon((oldInstructorsInMarathon) => {
        const filteredOldInstructorsList =
          oldInstructorsInMarathon.filter(Boolean);

        return [...filteredOldInstructorsList, instructor.id].concat(
          generateEmptyArray(3 - filteredOldInstructorsList.length - 1)
        );
      });
    },
    [instructorsInMarathon]
  );

  const onMinusClick = useCallback(
    (instructorToDelete: Instructor | InstructorInDb) => {
      setInstructorsInMarathon((oldInstructorsInMarathon) => {
        const newInstructorsInMarathon = oldInstructorsInMarathon.filter(
          (instructorId) =>
            instructorId && instructorId !== instructorToDelete.id
        );

        return newInstructorsInMarathon.concat(
          generateEmptyArray(
            newInstructorsInMarathon.length
              ? 3 - newInstructorsInMarathon.length
              : 3
          )
        );
      });
    },
    []
  );

  const onDoneClick = useCallback(() => {
    send({
      type: "SET_CURRENT_STEP",
      payload: "Fifth",
    });

    send({
      type: "UPDATE_MARATHON",
      payload: {
        instructor: instructorsInMarathon
          .filter(Boolean)
          .map((id) => instructorsHashTable[id]) as any,
      },
    });
  }, [instructorsInMarathon, instructorsHashTable]);

  return (
    <div>
      <Row justify={"space-between"} align={"middle"}>
        <Typography.Title>Выберите ведущих</Typography.Title>
      </Row>

      <Row>
        <InstructorsSpecializationFilter
          value={instructorSpecializationFilter}
          onChange={onInstructorSpecializationFilterChange}
        />

        <Col
          style={{
            marginLeft: "auto",
          }}
        >
          <Input placeholder={"Поиск"} onChange={onSearchUpdateHandler} />
        </Col>
      </Row>

      <div className={s.selectedInstructors}>
        {instructorsInMarathon.map((instructorId, index) =>
          instructorId ? (
            <CreateOrEditMarathonInstructorCard
              instructor={instructorsHashTable[instructorId]}
              key={instructorId}
              onMinusClick={onMinusClick}
            />
          ) : (
            <div className={s.instructorNumberCard} key={`noInsId_${index}`}>
              <Typography.Title>Тренер {index + 1}</Typography.Title>
            </div>
          )
        )}
      </div>
      <div className={s.instructorsList}>
        {searchFilteredInstructors.map((instructor) => (
          <CreateOrEditMarathonInstructorCard
            instructor={instructor}
            onPlusClick={onPlusClick}
            key={instructor.id}
          />
        ))}
      </div>

      <Row
        style={{
          position: "fixed",
          bottom: 100,
          right: 100,
        }}
      >
        <Button onClick={onDoneClick}>Далее</Button>
      </Row>
    </div>
  );
};

export default CreateOrEditMarathonFourthStepForm;
