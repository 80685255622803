import { assign, createMachine } from "xstate";
import { Marathon, Instructor } from "sport-app-types";
import { getMarathonById } from "app/api/getData/getMarathonById";
import {
  CreateMarathonStepNames,
  createMarathonStepNumbers,
} from "pages/createOrEditMarathon";
import { getInstructorsList } from "app/api/getData/getInstructorsList";
import { normalizeArray } from "lib/normalizeArray";
import { saveMarathonInServer } from "app/api/createData/createMarathon";
import {
  getLocaleMarathon,
  saveMarathonLocale,
} from "pages/createOrEditMarathon/localeMarathonActions";

export interface CreateOrEditMarathonMachineContext {
  marathon: Partial<
    Marathon & {
      instructorIdsToCreateMarathon: [];
    }
  >;
  marathonDataLoading: boolean;
  step: CreateMarathonStepNames;
  finishedStepsCount: number;
  instructors: {
    [key: string]: Instructor;
  };
  instructorsLoading: boolean;
  marathonIsSaving: boolean;
}

type CreateOrEditMarathonEvents =
  | {
      type: "SYNC_MARATHON";
      payload: {
        marathonId: string;
      };
    }
  | {
      type: "SYNC_INSTRUCTORS";
    }
  | {
      type: "SET_CURRENT_STEP";
      payload: CreateMarathonStepNames;
    }
  | {
      type: "UPDATE_MARATHON";
      payload: Partial<
        Marathon & {
          instructorIdsToCreateMarathon: [];
        }
      >;
    }
  | {
      type: "CLEAR_MARATHON";
    }
  | {
      type: "SAVE_MARATHON";
    }
  | {
      type: "STOP_MARATHON_DATA_LOADING";
    };

export const createOrEditMarathonMachine = createMachine<
  CreateOrEditMarathonMachineContext,
  CreateOrEditMarathonEvents
>({
  id: "createOrEditMarathon",
  context: {
    marathonDataLoading: false,
    step: "First",
    finishedStepsCount: 0,
    instructors: {},
    instructorsLoading: false,
    marathon: getLocaleMarathon(),
    marathonIsSaving: false,
  },
  initial: "idle",
  on: {
    SET_CURRENT_STEP: {
      actions: assign({
        step: (_, { payload }) => payload,
        finishedStepsCount: ({ finishedStepsCount }, { payload }) => {
          const candidate = createMarathonStepNumbers[payload] - 1;
          if (finishedStepsCount < candidate) {
            return candidate;
          }
          return finishedStepsCount;
        },
      }),
    },
    UPDATE_MARATHON: {
      actions: assign({
        marathon: ({ marathon }, action) => {
          const newMarathon: Partial<Marathon> = {
            ...marathon,
            ...action.payload,
          };
          saveMarathonLocale(newMarathon);
          return newMarathon;
        },
      }),
    },
    CLEAR_MARATHON: {
      actions: assign({
        marathon: (_) => {
          saveMarathonLocale(null);
          return {};
        },
      }),
    },
    STOP_MARATHON_DATA_LOADING: {
      actions: assign({
        marathonDataLoading: (_) => false,
      }),
    },
  },
  states: {
    idle: {
      on: {
        SYNC_MARATHON: {
          target: "fetchingMarathonData",
          actions: assign({
            marathonDataLoading: (_) => true,
          }),
        },
        SYNC_INSTRUCTORS: {
          target: "fetchingInstructors",
          actions: assign({
            instructorsLoading: (_) => true,
          }),
        },
        SAVE_MARATHON: {
          target: "savingMarathon",
          actions: assign({
            marathonIsSaving: (_) => true,
          }),
        },
      },
    },
    fetchingMarathonData: {
      invoke: {
        src: async (context, action) => {
          if (action.type === "SYNC_MARATHON") {
            return getMarathonById(action?.payload?.marathonId);
          }
          throw "Wrong action type";
        },
        onDone: {
          target: "idle",
          actions: assign({
            marathonDataLoading: (_) => false,
            marathon: (_, { data }) => data,
          }),
        },
      },
    },
    fetchingInstructors: {
      invoke: {
        src: async () => getInstructorsList(),
        onDone: {
          target: "idle",
          actions: assign({
            instructorsLoading: (_) => false,
            instructors: (_, { data }) =>
              normalizeArray(data as Instructor[], "id"),
          }),
        },
      },
    },
    savingMarathon: {
      invoke: {
        src: async ({ marathon }) => saveMarathonInServer(marathon),
        onDone: {
          target: "idle",
          actions: assign({
            marathonIsSaving: (_) => false,
            marathon: ({ marathon }, { data }) => {
              saveMarathonLocale(null);
              return {
                ...marathon,
                id: data.id,
              };
            },
            step: (_) => "Finish",
          }),
        },
      },
    },
  },
});
