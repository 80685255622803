import { Marathon, MarathonStatus } from "sport-app-types";
import { Api } from "app/api/api";
import { getUrlFns } from "app/api/getData/constants";
import { CreateOrUpdateMarathonDto } from "app/api/createData/createMarathon";

export type UpdatingMarathonInfo = CreateOrUpdateMarathonDto;

export type UpdateMarathonParams = UpdatingMarathonInfo & {
  marathonId: string;
};

export async function updateMarathonInServer(
  marathonId: string,
  updateMarathonData: CreateOrUpdateMarathonDto
): Promise<Marathon> {
  if (!marathonId) {
    throw "marathonId is required";
  }
  return Api.post<Marathon, UpdateMarathonParams>(getUrlFns.editMarathon(), {
    marathonId,
    ...updateMarathonData,
  }).then((d) => d.data);
}

export async function updateMarathonStatusInServer(
  marathonId: string,
  status: MarathonStatus
): Promise<Marathon> {
  if (!marathonId) {
    throw "marathonId is required";
  }

  return Api.post<
    Marathon,
    Pick<Marathon, "status"> & {
      marathonId: string;
    }
  >(getUrlFns.editMarathonStatus(), {
    marathonId,
    status,
  }).then((d) => d.data);
}
