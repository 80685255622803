import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import TrainersListPage from "pages/users/instructors";
import CustomersListPage from "pages/users/customers";

const UsersListPage: React.FC = () => (
  <>
    <Switch>
      <Route path="/users/customers" component={CustomersListPage} exact />
      <Route path="/users/instructors" component={TrainersListPage} exact />

      <Redirect to="/users/instructors" />
    </Switch>
  </>
);

export default UsersListPage;
