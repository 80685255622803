import { Api } from "app/api/api";
import { GetUrlFnNames, getUrlFns } from "app/api/getData/constants";
import { MasterTrainingInDb } from "sport-app-types/src/server";

type UpdateMasterTrainingDataType = Partial<
  Omit<MasterTrainingInDb, "id" | "updatedAt" | "createdAt" | "equipment">
>;

export async function updateMasterTraining(
  id: string,
  data: UpdateMasterTrainingDataType
): Promise<MasterTrainingInDb> {
  return Api.put<MasterTrainingInDb>(
    getUrlFns[GetUrlFnNames.updateMasterTrainingInfoById](id),
    data
  ).then((d) => d.data);
}
