import { Api } from "app/api/api";
import { GetUrlFnNames, getUrlFns } from "app/api/getData/constants";
import { date } from "lib/dates";
import { LicenseKeyInDb } from "sport-app-types/index";

export interface CreateLicenseKeysProps {
  orgId: string;
  expiredAt?: string;
  amountOfKeys?: number;
  duration?: number;
}

export function createLicenseKeysInServer({
  amountOfKeys,
  expiredAt,
  orgId,
  duration,
}: CreateLicenseKeysProps): Promise<LicenseKeyInDb[]> {
  return Api.post<LicenseKeyInDb[], CreateLicenseKeysProps>(
    getUrlFns[GetUrlFnNames.b2bCreateLicenseKeys](),
    {
      orgId,
      amountOfKeys: amountOfKeys || 1,
      expiredAt: expiredAt || date().toISOString(),
      duration: duration || 360,
    }
  ).then((d) => d.data);
}
