import { atom } from "recoil";
import { RecoilKeys } from "app/recoil/keys";

export enum EmployeeRoles {
  operator = "operator",
}

export const $userRole = atom<EmployeeRoles | null>({
  key: RecoilKeys.userRole,
  default: null,
});

export const $userInfoLoading = atom<boolean>({
  key: RecoilKeys.userInfoLoading,
  default: true,
});
