import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AuthPage from "pages/auth";
import operatorRoutes from "app/routes/operatorRoutes";
import Layout from "components/Layout";

export const OperatorRoutes: React.FC = () => (
  <Layout>
    <Switch>
      {operatorRoutes.map((routeProps) => (
        <Route {...routeProps} key={routeProps.path as string} />
      ))}
      <Redirect to="/users/instructors" />
    </Switch>
  </Layout>
);

export const GuestRoutes: React.FC = () => (
  <Switch>
    <Route path="/auth" exact component={AuthPage} />
    <Redirect to="/auth" />
  </Switch>
);
