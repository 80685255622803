import { CreateInstructorParams } from "app/types/operator/createTrainerRequest";
import { AxiosError } from "axios";
import { ErrorCodes } from "app/types/errorCodes";
import { toast } from "react-hot-toast";
import { Api } from "app/api/api";
import { getUrlFns } from "app/api/getData/constants";

export async function createInstructorInServer(
  instructorData: CreateInstructorParams
): Promise<CreateInstructorParams | null> {
  try {
    const newInstructor = await Api.post<
      CreateInstructorParams,
      CreateInstructorParams
    >(getUrlFns.createInstructor(), instructorData).then((d) => d.data);

    console.log(newInstructor);

    return newInstructor;
  } catch (e: any) {
    const error: AxiosError = e;
    if (error.response) {
      switch (error.response.data) {
        case ErrorCodes.emailAlreadyExists:
          toast.error("Тренер с такой почтой уже существует.");
          throw "Тренер с такой почтой уже существует.";
        case ErrorCodes.phoneAlreadyExists:
          toast.error("Тренер с таким номером телефона уже существует.");
          throw "Тренер с таким номером телефона уже существует.";
        default:
          throw "Что то пошло не так на сервере! (";
      }
    }
    return null;
  }
}
