import { Api } from "app/api/api";
import { GetUrlFnNames, getUrlFns } from "app/api/getData/constants";
import { MasterTraining } from "sport-app-types/src/client";
import { MasterTrainingInDb } from "sport-app-types/src/server";
import { prepareInstructorInfoFromDbToClient } from "app/api/getData/getTrainerById";
import { CollectionResult } from "sport-app-types/src/universal";

export function prepareMasterTrainingFromDbToClient({
  instructor,
  equipment,
  child,
  explicit,
  covers,
  ...masterTrainingInDb
}: MasterTrainingInDb): MasterTraining {
  return {
    ...masterTrainingInDb,
    child: child || false,
    explicit: explicit || false,
    covers: Array.isArray(covers) ? covers : [],
    instructor: instructor && prepareInstructorInfoFromDbToClient(instructor),
    equipment: equipment ? Object.values(equipment) : [],
  };
}

export async function getMasterTrainingsByInstructorId({
  id,
}: {
  id: string;
}): Promise<MasterTraining[]> {
  if (!id) {
    throw new Error("Invalid id");
  }

  const masterTrainings = await Api.post<
    CollectionResult<MasterTrainingInDb[]>
  >(getUrlFns[GetUrlFnNames.getInstructorMasterTrainingsById](), {
    instructorId: id,
  }).then((d) => d.data.result);

  return masterTrainings.map(prepareMasterTrainingFromDbToClient);
}
