import { B2BOrganizationInDb } from "sport-app-types/index";
import { Api } from "app/api/api";
import { GetUrlFnNames, getUrlFns } from "app/api/getData/constants";

export function updateB2BOrganizationInServer({
  id,
  licenseKeys,
  ...organization
}: B2BOrganizationInDb): Promise<B2BOrganizationInDb> {
  return Api.put<B2BOrganizationInDb, Omit<B2BOrganizationInDb, "id">>(
    getUrlFns[GetUrlFnNames.b2bUpdateOrganization](id),
    organization
  ).then((d) => d.data);
}
