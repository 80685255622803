import React from "react";
import { Instructor } from "sport-app-types/src/client";
import { Button, Row } from "antd";
import { date } from "lib/dates";
import s from "./styles.module.scss";
import { InstructorInDb } from "sport-app-types/src/server";

const CreateOrEditMarathonInstructorCard: React.FC<{
  instructor: Instructor | InstructorInDb;
  onPlusClick?: (instructor: Instructor | InstructorInDb) => void;
  onMinusClick?: (instructor: Instructor | InstructorInDb) => void;
}> = ({ instructor, onPlusClick, onMinusClick }) => {
  const instructorAge = date().diff(instructor.birthday, "y");
  return (
    <div className={s.cardWrapper}>
      <img src={instructor.avatar} alt="" className={s.avatar} />
      <div>
        <Row>{instructor.specialization}</Row>
        <div>
          {instructor.firstName} {instructor.lastName}
        </div>
        {Boolean(instructorAge) && <Row>{instructorAge} лет</Row>}
      </div>
      <div>
        {onPlusClick && (
          <Button
            onClick={() => {
              onPlusClick(instructor);
            }}
          >
            +
          </Button>
        )}
        {onMinusClick && (
          <Button
            onClick={() => {
              onMinusClick(instructor);
            }}
          >
            -
          </Button>
        )}
      </div>
    </div>
  );
};

export default CreateOrEditMarathonInstructorCard;
