import React, { useEffect } from "react";
import { useScheduledMarathonsData } from "app/xstate/marathons/hooks/useScheduledMarathonsData";
import ScheduledMarathonTable from "pages/marathons/confirmed/table";
import { Row, Typography } from "antd";
import { useSyncMarathonsData } from "app/xstate/marathons/hooks/useSyncMarathonsData";
import Loader, { LoaderVariants } from "components/loader";
import { useMarathonsLoadingData } from "app/xstate/marathons/hooks/useMarathonsLoadingData";

const ScheduledMarathonsPage: React.FC = () => {
  const marathons = useScheduledMarathonsData();
  const syncMarathons = useSyncMarathonsData();
  const loading = useMarathonsLoadingData();

  useEffect(() => {
    syncMarathons();
  }, []);

  if (loading) {
    return (
      <Row justify={"center"}>
        <Loader variant={LoaderVariants.small} />
      </Row>
    );
  }

  return (
    <div>
      <Row justify={"center"}>
        <Typography.Title>Запланированные</Typography.Title>
      </Row>
      <ScheduledMarathonTable marathons={marathons} />
    </div>
  );
};

export default ScheduledMarathonsPage;
