import React from "react";
import { ColumnsType } from "antd/lib/table";
import { Training } from "sport-app-types";
import { Table } from "antd";
import { dateFromUtc } from "lib/dates";
import { timeFormatDDMMYYYY } from "app/api/getData/getB2BLicenceKeysList";
import getTrainingDirectionName from "app/helpers/getTrainingDirectionName";

const MarathonDetailsTable: React.FC<{
  trainings: Training[];
}> = ({ trainings }) => {
  const columns: ColumnsType<Training> = [
    {
      key: "startDate",
      dataIndex: "startDate",
      title: "Начало",
      render: (value: Training["startDate"]) =>
        dateFromUtc(value).format(timeFormatDDMMYYYY),
    },
    {
      key: "masterTrainingName",
      dataIndex: "masterTraining",
      title: "Тренировка",
      render: (masterTraining: Training["masterTraining"]) =>
        masterTraining?.name,
    },
    {
      key: "instructor",
      dataIndex: "instructor",
      title: "Тренер",
      render: (instructor: Training["instructor"]) =>
        `${instructor?.lastName || ""} ${instructor?.firstName || ""} ${
          instructor?.middleName || ""
        }`,
    },
    {
      key: "duration",
      dataIndex: "masterTraining",
      title: "Продолжительность",
      render: (masterTraining: Training["masterTraining"]) =>
        `${masterTraining?.duration} минут`,
    },
    {
      key: "direction",
      dataIndex: "masterTraining",
      title: "Направление",
      render: (masterTraining: Training["masterTraining"]) =>
        `${getTrainingDirectionName(masterTraining?.direction)}`,
    },
  ];

  return (
    <div>
      <Table columns={columns} dataSource={trainings} />
    </div>
  );
};

export default MarathonDetailsTable;
