import React from "react";
import Title from "antd/es/typography/Title";
import { Button, Col, Form, Image, Input, Row } from "antd";
import { useEditMasterTraining } from "components/pages/editMasterTraining/useEditMasterTraining";
import TextArea from "antd/lib/input/TextArea";
import { Link } from "react-router-dom";
import { MasterTraining } from "sport-app-types";
import s from "./styles.module.scss";

const EditMasterTraining: React.FC<{
  masterTraining: MasterTraining;
}> = ({ masterTraining }) => {
  const { onSubmit } = useEditMasterTraining(masterTraining);

  return (
    <div>
      <Title>Редактирование мастер-тренировки</Title>

      <Form onFinish={onSubmit}>
        <Form.Item
          initialValue={masterTraining.name || ""}
          name={"name"}
          label={"Название"}
        >
          <Input />
        </Form.Item>

        <Form.Item
          initialValue={masterTraining.description}
          name={"description"}
          label={"Описание"}
        >
          <TextArea rows={10} />
        </Form.Item>

        <Form.Item>
          <Button type={"primary"} htmlType={"submit"}>
            Сохранить
          </Button>
        </Form.Item>
        <Form.Item>
          <Link to={`/masterTrainings/${masterTraining?.instructor?.id}`}>
            <Button color={"red"} danger>
              Вернуться к списку мастер-тренировок
            </Button>
          </Link>
        </Form.Item>

        <Row className={s.coversList} gutter={14}>
          {masterTraining.covers?.map((cover) => (
            <Col span={8}>
              <Image src={cover} className={s.cover} />
            </Col>
          ))}
        </Row>
      </Form>
    </div>
  );
};

export default EditMasterTraining;
