import { useCallback } from "react";
import { useTrainingStatisticsActor } from "app/xstate/trainingStatistics/hooks/useTrainingStatisticsActor";
import { GetTrainingStatisticsProps } from "app/api/getData/getTrainingStatistics";

export function useTrainingStatisticsActions() {
  const [_, send] = useTrainingStatisticsActor();
  const setSearchParams = useCallback((search: GetTrainingStatisticsProps) => {
    send({
      type: "SET_SEARCH_PARAMS",
      search,
    });
  }, []);

  const syncTrainingStatistics = useCallback(() => {
    send({
      type: "SYNC_TRAINING_STATISTICS",
    });
  }, []);

  return {
    setSearchParams,
    syncTrainingStatistics,
  };
}
