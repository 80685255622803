import React from "react";
import { Marathon, Training, TrainingInDb } from "sport-app-types";
import s from "pages/createOrEditMarathon/fifthStepForm/styles.module.scss";
import { dateFromUtc } from "lib/dates";
import { Typography } from "antd";
import { AddingTrainingCords } from "pages/createOrEditMarathon/fifthStepForm/index";
import TrainingCard from "pages/createOrEditMarathon/fifthStepForm/trainingCard";
import { timeFormatDDMMYYYY } from "app/api/getData/getB2BLicenceKeysList";

const CreateOrEditMarathonAddTrainingRow: React.FC<{
  marathon: Partial<Marathon>;
  index: number;
  addingTrainingCords: AddingTrainingCords;
  selectTraining: (cords: AddingTrainingCords) => void;
  addTrainingToMarathon: (training: Training) => void;
  deleteTrainingFromMarathon: (training: Training) => void;
}> = ({
  marathon,
  index,
  addingTrainingCords,
  selectTraining,
  addTrainingToMarathon,
  deleteTrainingFromMarathon,
}) => {
  const { instructor: instructors } = marathon;
  const isSelectedRow = index === addingTrainingCords.rowIndex;
  const iterateDate = dateFromUtc(marathon.startDate).add(index, "d");

  return (
    <div>
      <div className={`${s.addTrainingRow}`}>
        <div className={s.dateCard}>{iterateDate.format("DD.MM")}</div>

        {instructors?.map((instructor) => {
          const trainingInThisPosition = marathon.trainings?.find(
            (training) =>
              iterateDate.format(timeFormatDDMMYYYY) ===
                dateFromUtc(training.startDate).format(timeFormatDDMMYYYY) &&
              training.instructorId === instructor.id
          );
          return trainingInThisPosition ? (
            <TrainingCard
              key={trainingInThisPosition.id}
              training={trainingInThisPosition as TrainingInDb}
              onMinusClick={() => {
                deleteTrainingFromMarathon(trainingInThisPosition);
              }}
            />
          ) : (
            <div
              key={`createOrEditMarathon_training_card_${index}`}
              onClick={() => {
                selectTraining({
                  rowIndex: index,
                  instructor,
                });
              }}
              className={`${s.instructorCard} ${
                isSelectedRow &&
                instructor.id === addingTrainingCords.instructor?.id
                  ? s.selected
                  : ""
              }`}
            >
              <Typography.Title level={3}>
                Тренировка <br /> {instructor.firstName} {instructor.lastName}
              </Typography.Title>
            </div>
          );
        })}
      </div>
      {isSelectedRow && (
        <div className={s.trainingsRow}>
          {addingTrainingCords.instructor?.trainings
            ?.filter(
              (training) =>
                !marathon.trainings?.find((t) => t.id === training.id) &&
                dateFromUtc(training.startDate).format(timeFormatDDMMYYYY) ===
                  iterateDate.format(timeFormatDDMMYYYY)
            )
            ?.map((training) => (
              <TrainingCard
                key={training.id}
                training={training}
                onPlusClick={() => {
                  addTrainingToMarathon(training as Training);
                }}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default CreateOrEditMarathonAddTrainingRow;
