import React, { useMemo } from "react";

const Progress: React.FC<{ successPercent: number }> = ({ successPercent }) => {
  const preparedSuccessPercent = useMemo<number>(() => {
    if (successPercent >= 0 && successPercent < 1) {
      return successPercent * 100;
    }
    if (successPercent >= 0 && successPercent < 100) {
      return successPercent;
    }
    return successPercent;
  }, [successPercent]);

  return (
    <div
      style={{
        height: 10,
        position: "relative",
        borderRadius: 5,
        overflow: "hidden",
        backgroundColor: "#E1DFE7",
      }}
    >
      <div
        style={{
          width: `${preparedSuccessPercent}%`,
          backgroundColor: "#FFD84E",
          height: "100%",
          transition: "ease all .2s",
        }}
      />
    </div>
  );
};

export default Progress;
