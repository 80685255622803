import React from "react";
import { DatePicker, Form, InputNumber, Modal, Select } from "antd";
import { useOrganizationsData } from "app/xstate/b2bCompanies/hooks/useCompaniesData";
import ru_RU from "antd/es/date-picker/locale/ru_RU";
import { useOrganizationsActions } from "app/xstate/b2bCompanies/hooks/useCompaniesActions";

const IssueOfLicenceKeysModal: React.FC = () => {
  const [form] = Form.useForm();
  const {
    listOfOrganizationsArray,
    createLicenceKeyModalIsOpen,
    createLicenceKeyParams,
    createLicenceKeyLoading,
  } = useOrganizationsData();

  const { closeCreateLicenceKeysModal, createLicenseKeys } =
    useOrganizationsActions();

  const submitFormHandler = async () => {
    try {
      const formData = await form.validateFields();

      await createLicenseKeys({
        orgId: formData.orgId,
        expiredAt: formData.expiredAt?.toISOString(),
        duration: formData.duration,
        amountOfKeys: formData.amountOfKeys,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const isOpen = Boolean(createLicenceKeyParams || createLicenceKeyModalIsOpen);

  return (
    <Modal
      visible={isOpen}
      onCancel={closeCreateLicenceKeysModal}
      centered
      okText={"Выпустить"}
      cancelText={"Отменить"}
      onOk={submitFormHandler}
      title={"Эмиссия ключей"}
      confirmLoading={createLicenceKeyLoading}
      maskClosable={false}
    >
      <Form
        form={form}
        labelCol={{
          span: 8,
        }}
        labelAlign={"left"}
        name={"createLicenseKeys"}
        initialValues={{
          amountOfKeys: 1,
          orgId: createLicenceKeyParams?.orgId,
          duration: 180,
        }}
      >
        <Form.Item
          name={"orgId"}
          label={"Организация"}
          required
          rules={[{ required: true }]}
        >
          <Select
            onChange={(v) => {
              form.setFieldsValue({ companyId: v });
            }}
          >
            {listOfOrganizationsArray.map((company) => (
              <Select.Option
                value={company.id}
                title={company.name || ""}
                key={company.id}
              >
                {company.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={"Активировать до"}
          required
          name={"expiredAt"}
          rules={[{ required: true }]}
        >
          <DatePicker locale={ru_RU} />
        </Form.Item>
        <Form.Item
          label={"Действует (Дней)"}
          required
          name={"duration"}
          rules={[
            { required: true, message: "Обязательное поле!" },
            { type: "number", message: "Только цифры!" },
            { type: "number", min: 1, message: "Минимум 1!" },
            { type: "number", max: 366, message: "Не более года!" },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label={"Количество"}
          required
          name={"amountOfKeys"}
          rules={[
            { required: true, message: "Обязательное поле!" },
            { type: "number", min: 1, message: "Минимум 1" },
            { type: "number", message: "Только цифры!" },
          ]}
        >
          <InputNumber />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default IssueOfLicenceKeysModal;
